import React from "react";
import { useEffect, useState } from "react";
import "./MyCertificate.scss";
//import { makeStyles } from '@material-ui/core';
import ReactPaginate from "react-paginate";
import { ImDownload3 } from "react-icons/im";

import certificateImg from "../../../assets/bgs/certificate.png";
import { certificate } from "../../../store/actions/getPosPersonalDetails";
import { jsPDF } from "jspdf";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import Constant from "../../../utils/constant";

export default function MyCertificate() {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([...Array(33).keys()]);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [val, setVal] = useState({});

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  useEffect(() => {
    certificate()
      .then((res) => {
        if (res.status) {
          setVal(res.data);
          console.log("res.data=====", res.data);
        } else {
          alert(res.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, []);

  // useEffect(() => {
  //   certificate()
  //     .then((res) => {
  //       if (res.status) {
  //         const fullUrl = `https://policiecue-api.mobileprogramming.net/pos/${res.data.url}`;
  //         setVal({ ...res.data, fullUrl });
  //         console.log("Full URL for certificate:", fullUrl);
  //       } else {
  //         alert(res.message);
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error.message);
  //     });
  // }, []);

  const handleDownload = () => {
    if (val?.fullUrl) {
      window.open(val.fullUrl, "_blank");
    } else {
      alert("Certificate URL not available");
    }
  };

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });
  // const HandleOnPDFDownload=()=>{
  //   // Landscape export, 2×4 inches
  // const doc = new jsPDF({
  //   orientation: "landscape",
  //   unit: "in",
  //   format: [4, 2]
  // });

  // doc.html(val?.url?val.url:"", 1, 1);
  // doc.save("two-by-four.pdf");
  // }
  const MyDoc = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text>Section #1</Text>
        </View>
        <View style={styles.section}>
          <Text>Section #2</Text>
        </View>
      </Page>
    </Document>
  );
  return (
    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
      <main className="mainBodyContainer">
        <div className=" my-2">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
            <div className="bg-transparent p-2  rounded-3">
              <nav
                style={{
                  bsbreadcrumbdivider:
                    'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")',
                }}
                aria-label="breadcrumb"
              >
                <h6>My Certificate</h6>
                <ol className="breadcrumb pt-2 mb-0 bg-transparent">
                  <li className="breadcrumb-item bg-transparent">
                    <a
                      href="/"
                      className="text-decoration-none "
                      style={{ fontFamily: "Calibri" }}
                    >
                      Dashboard
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted"
                    aria-current="page"
                  >
                    Account Information
                  </li>
                  <li className="breadcrumb-item bg-transparent">
                    My Certificate
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <div className="MyCertificate">
            <div className="agreement__btn-agreement">
              <a
                href={val?.url ? val.url : ""}
                download={val?.url ? val.url : ""}
                target="_blank"
                height="80%"
              >
                <button>
                  <span>
                    <ImDownload3 />
                  </span>
                  Download Certificate
                </button>
              </a>
              {/* <button onClick={handleDownload}>Download Certificate</button> */}
            </div>

            <div style={{ marginTop: "20px" }}>
              <div style={{ padding: "20px", border: "solid 1px #000" }}>
                <div style={{ padding: "10px" }}>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src="https://policiecue-api.mobileprogramming.net/images/policicue-logo.png"
                      alt=""
                    />
                  </div>
                  <h1
                    style={{
                      textAlign: "center",
                      marginBottom: "0",
                      paddingBottom: "0",
                      lineHeight: "1",
                      fontSize: "2em",
                    }}
                  >
                    PoliciCue Insurance Broking Services Ltd.
                  </h1>
                  <p
                    style={{
                      lineHeight: "2",
                      fontSize: "1rem",
                      textAlign: "center",
                    }}
                  >
                    A-42/6, Sector-62 Noida Gautam Buddha Nagar UP 201301 IN
                    CERTIFICATION OF ONLINE POINT OF SALE PERSON TRAINING
                  </p>

                  <div
                    style={{
                      marginTop: "50px",
                      lineHeight: "2",
                      fontSize: "1rem",
                    }}
                  >
                    <div
                      style={{
                        // width: "100px",
                        // height: "120px",
                        marginLeft: "30px",
                        border: "solid 1px #000",
                        float: "right",
                      }}
                    >
                      <img
                        style={{ width: "100px" }}
                        src={val?.user_image ? val.user_image : ""}
                        alt=""
                      />
                    </div>
                    This is to certify that <strong>{val?.name}</strong>
                    <br />
                    who had registered for the online training program on{" "}
                    <strong>{val?.Date1}</strong> for{" "}
                    <strong>Point of Sale</strong> person
                    <br />
                    <br />
                    Examination has completed 15 hours Online training in
                    compliance to the guidelines issued by Insurance Regulatory
                    and Development Authority issued under Reference number:{" "}
                    <strong>IRDAI/Int/ GDL/ORD/183/10/2015</strong> dated
                    26/10/2015 ( As amended from time to time )<br />
                    <br />
                    Place : NOIDA
                    <br />
                    Date : {val?.date2}
                    <br />
                    POSP CODE : {val?.pospCode}
                    <p style={{ textAlign: "right", marginBottom: "40px" }}>
                      <img
                        width="200px;"
                        src="images/policicue-signature.png"
                        alt=""
                      />
                      <br />
                      PRINCIPAL OFFICER
                    </p>
                    <p style={{ textAlign: "center" }}>
                      <small>
                        This is a computer generated document and No signature
                        is required
                      </small>
                    </p>
                    <div style={{ clear: "both" }}></div>
                  </div>
                </div>
              </div>
              {/* <div className="mainBody">
        <div className="main border-1">
            <div className="main-inner border-1">
                <div className="inner-bl border-1">
                    <div className="policicueLogo"><img src="https://policiecue-api.mobileprogramming.net/images/policicue-logo.png" alt="" /></div>
                    <h1 className="text-center">PoliciCue Insurance Broking Services Ltd.</h1>
                    <p className="companyAddress text-center">A-42/6,Sector-62 Noida Gautam Buddha Nagar UP 201301 IN CERTIFICATION OF ONLINE POINT OF SALE PERSON TRAINING</p>

                    <div className="mid-blk">
                        <div className="pp-img border-1 pull-right">
                            <img src={val?.user_image?val.user_image:""} alt=""/>
                        </div>
                        This is to certify that<br/>
                        <strong>{val?.name}</strong> who had registered for the online training program on <strong>{val?.Date1}</strong> for <strong>Point of Sale</strong> person<br/><br/>
                        Examination has completed 15 hours Online<br/><br/>
                        Training in compliance to the guidelines issued by Insurance Regulatory and Development<br/><br/>
                        Authority issued under Reference number: <strong>IRDAI/Int/ GDL/ORD/183/10/2015</strong> dated <strong>26/10/2015</strong> ( As amended from time to time )<br/><br/>
                        Place : {val?.place}<br/>
                        Date : {val?.date2}<br/>
                        POSP CODE : {val?.pospCode}
<p className="text-right m-b-20">
                            <img width="150px;" src="https://policiecue-api.mobileprogramming.net/images/policicue-logo.png" alt=""/><br/>
                            PRINCIPAL OFFICER
                        </p>
                        
                        <p className="text-center"><small>This is a computer generated document and No signature is required</small></p>                        
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
   </div> */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
