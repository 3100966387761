import React, { useEffect, useState } from "react";
import "./MyQuotes.css";
//import { makeStyles } from '@material-ui/core';
import ReactPaginate from "react-paginate";
// import { MdModeEdit } from 'react-icons/md';
// import DeleteIcon from '@mui/icons-material/Delete'
export default function MyQuotes() {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([...Array(33).keys()]);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {


    
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const handleChange = (e) => {
    console.log(e.selected);
  }

  return (
    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
      <main className="mainBodyContainer" style={{ height: "100vh" }}>
        <div className=" my-2">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
            <div className="bg-transparent p-2  rounded-3">
              <nav
                style={{
                  bsbreadcrumbdivider:
                    'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")',
                }}
                aria-label="breadcrumb"
              >
                <h6>My Quotes</h6>
                <ol className="breadcrumb pt-2 mb-0 bg-transparent">
                  <li className="breadcrumb-item bg-transparent">
                    <a
                      href="/"
                      className="text-decoration-none "
                      style={{ fontFamily: "Calibri" }}
                    >
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item text-muted" aria-current="page">
                    My Quotes
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-wrapper">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
              <div className="shadow rounded-3 p-4 pb-0 bg-white">
                <form id="icueLeadInfoForm">
                  <div className="align-row">
                    <div className="full-inputs">
                      <label for="name"style={{color:"black"}}>From</label>
                      <input type="date" name="date"></input>
                    </div>
                    <div className="full-inputs">
                      <label for="name" style={{color:"black"}}>To</label>
                      <input type="date" name="date"></input>
                    </div>
                    <div className="full-inputs">
                      <label for="dropdown" style={{color:"black"}}>Product Name</label>
                      <select
                        id="cars"
                        name="cars"
                        style={{
                          backgroundColor: "#EBEFF2",
                          width: "100%",
                          height: "19px",
                          border: "none",
                          lineHeight: "2.5rem",
                        }}
                      >
                        <option value="select">Select</option>
                       
                      </select>
                    </div>
                    <div className="full-inputs">
                      <label for="number" style={{color:"black"}}>Quotes Number</label>
                      <input type="text" name="number"></input>
                    </div>
                   
                  </div>
                  <div className="py-4 align-row col-8 ">
                    <div className="py-4 align-row col-4 "></div>
                    <div className="align-row">
                      <div className="col-lg-3 col-md-3 col-xxl-3 col-sm-12">
                        {/* <div className='searchbtn text-white' style={{ marginLeft: "165px", lineHeight: "20px", height: "60px" }}><button className=''  type='search' >SEARCH</button></div> */}
                        <div
                          className="badge badge-primary "
                          style={{
                            lineHeight: "20px",
                            height: "30px",
                            color: "white",
                          }}
                        >
                          SEARCH
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-3 col-xxl-3 col-sm-12">
                        {/* <div className='clearbtn ' style={{ marginLeft: "-155px", lineHeight: "20px", height: "60px" }}><button className=''  type='search' >CLEAR FILTER</button></div> */}
                        <div
                          className="badge badge-secondary badge-outlined"
                          style={{ lineHeight: "20px", height: "30px" }}
                        >
                          CLEAR FILTER
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 bg-white nav-data-handling  ">
          <div  id='search' >
												<input
													className='car2-form-controll w-60'
													id='Search'
													type='text'
													name='Search'

													onChange={handleChange}
													style={{ position: "absolute", top:0 , left:0 }}
												/>
												<label className='ms-3 car2-form-control-placeholderr mt-n2 fs-7 fw-bold' for='firstName'>
													Search
												</label>
											</div>
            <div className="col-sm-3 col-md-3">
              <ul className="nav nav-pills">
                <li className="active">
                  <span>Show</span>
                </li>
                <li className="">
                  <span id="pendingcount">
                    {" "}
                    <select
                      onChange={(e) => setItemsPerPage(e.target.value)}
                      name="dtBasicExample_length"
                      aria-controls="dtBasicExample"
                      className="form-select ce "
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </span>
                </li>
                <li className="">
                  <span id="pendingcount">entries</span>
                </li>
              </ul>
            </div>
            <table id="dtBasicExample" className="table results " width="auto">
              <thead>
                <tr
                  className="text-uppercase"
                  style={{
                    lineHeight: "15px",
                    fontFamily: "Calibri",
                    fontStyle: "normal",
                    textAlign: "left",
                    fontSize: "16px",
                  }}
                >
                  <th style={{ width: "12%",fontFamily:'Eras Demi ITS' }}>POLICY NUMBER</th>
                  <th width="22%" style={{ fontFamily:'Eras Demi ITS' }}>INSURANCE COMPANY NAME</th>
                  <th Width="18%" style={{fontFamily:'Eras Demi ITS' }} >CUSTOMER NAME</th>
                  <th width="18%" style={{fontFamily:'Eras Demi ITS' }}>PREMIUM</th>
                  <th width="15%" style={{fontFamily:'Eras Demi ITS' }}>POLICY ISSUED</th>
                  <th width="20%" style={{fontFamily:'Eras Demi ITS' }}>PRODUCT NAME</th>

                  <th className="text-center" width="28%"  style={{fontFamily:'Eras Demi ITS' }}>
                    ACTION
                  </th>
                </tr>
              </thead>
              <tbody
                style={{
                  lineHeight: "15px",
                  fontFamily: "Calibri",
                  fontSize: "16px",
                }}
              >
                {currentItems &&
                  currentItems.map((k, v) => (
                    <tr>
                      <td>78678</td>
                      <td> USGI</td>
                      <td className="wrap">Abhishek Kumar</td>
                      <td>
                        Rs. 17,890{" "}
                        <div style={{ color: "gray", paddingLeft: "1.8rem" }}>
                          Yearly
                        </div>
                      </td>
                      <td className="text-center">25-Jan-2022</td>
                      <td>Car Insurance</td>
                      {/* <td><svg data-testid="DeleteIcon"></svg></td> */}
                      <td className="text-center btn-download">
                      <span className="pencil-align" ><i className="bi bi-pencil-fill"></i></span>
                        <a
                          href="/PayInstallment"
                          type="button"
                          className="btn icueBgInfo text-white mb-0 mt-0 "
                    >
                          Download
                        </a>{" "}
                        <br></br>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tr>
              <ReactPaginate
          containerClassName="containerClassName"
          // className="pagein"
          nextClassName="nextClassName"
          pageClassName="pageClassName"
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
              </tr>
            </table>
          </div>
        </div>
        
      </main>
    </div>
  );
}
