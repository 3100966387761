import React, {useState,useEffect} from 'react';
//import axios from 'axios'
import './BankDetails.css';
import { getBankDetails } from '../../../store/actions/getPosPersonalDetails'
import { alertShow } from '../../../store/actions/alert'

const BankDetails = (props) => {
    const { pathname } = window.location;
    console.log(window.location
        ,'=======')
    const [active, setactive] = useState('');
    const [val, setVal] = useState();
    useEffect(() => {
        getBankDetails().then(res => {
            if(res.status){
                setVal(res.data[0])
            }
           })
           .catch( (error)=> {
            alertShow('err',error.message);  
           });
    }, [])

    return(
        
            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
                <main className="mainBodyContainer">
                    <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3 pb-0">
                            <div className="bg-transparent p-2 pb-0 rounded-3">
                            <nav style={{bsbreadcrumbdivider: 'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")'}} aria-label="breadcrumb">
                                <h6>My Profile</h6>
                                <ol className="breadcrumb pt-2 pb-0 mb-0 bg-transparent">
                                    <li className="breadcrumb-item bg-transparent"><a href="/" className="text-decoration-none " style={{fontFamily:'Calibri'}}>Dashboard</a></li>
                                    <li className="breadcrumb-item text-muted" aria-current="page">Account Information</li>
                                    <li className="breadcrumb-item text-muted" aria-current="page">My Profile</li>
                                </ol>
                              </nav>
                            </div>
                        </div>
                    </div>

                   
                  
                        <div className="row my-2">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3" >
                            <div className="p-2 rounded-3">
                                <div className="py-3" >                  
                                      <div className="container px-0">
                                      <div className="row">
                                             
                                      <div className="addroles pr-0 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                     <ul className="px-0 m-0 shadow-sm">
                                     <li className={pathname=="/MyProfile"?"accountNavBarItem active":"accountNavBarItem"} style={{backgroundColor:"#F9F9F9"}} >
                        <a href="/MyProfile" className="accountNavBarLink" id="" >Personal Details </a>
                    </li> 
                    <li className={pathname=="/Aadhar"?"accountNavBarItem active":"accountNavBarItem"} style={{backgroundColor:"#F9F9F9"}} >
                        <a href="/Aadhar" className="accountNavBarLink" id="" >Aadhaar Details </a>
                    </li>   
                    <li className={pathname=="/PANDetails"?"accountNavBarItem active":"accountNavBarItem"} style={{backgroundColor:"#F9F9F9"}}>
                        <a href="/PANDetails" className="accountNavBarLink" id="" >PAN Details </a>
                    </li>  
                    <li className={pathname=="/ContactDetails"?"accountNavBarItem active":"accountNavBarItem"} style={{backgroundColor:"#F9F9F9"}}>
                        <a href="/ContactDetails" className="accountNavBarLink" id="" >Contact Details </a>
                    </li>   
                    <li className={pathname=="/EducationDetails"?"accountNavBarItem active":"accountNavBarItem"} style={{backgroundColor:"#F9F9F9"}} >
                        <a href="/EducationDetails" className="accountNavBarLink" id="">Education Details </a>
                    </li>  
                    <li className={pathname=="/BankDetails"?"accountNavBarItem active":"accountNavBarItem"}style={{backgroundColor:"white"}} >
                        <a href="/BankDetails" className="accountNavBarLink" id="" style={{ color: "#2196F3"}}>Bank Details </a>
                    </li>          
                                     </ul>
                                      
                                            
                                          
                                         
                                            </div>
           
                                               <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12 bg-white shadow-sm">
                                                    <div className="tab-content p-4" id="myTabContent">
                                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                          <div className="row">              
                                                               <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">              
                                                               <form >
                                                               <div className="bg-white">
                                <h4 className="icueTextPrimary mb-4">Bank Details</h4>
                              </div>
                                <form id="icueLeadInfoForm" >
                                    <div className="row" style={{marginLeft:"0.5px"}}>                          
                                        
                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-0 full-input" style={{height:"3rem"}}>
                                        <label for='address'style={{color:"black"}}>Bank Name</label>
                                         <input type='text' placeholder='-'   value={val?.bankName}  name='address' ></input>
                                           
                                        </div>
                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-0 full-input" style={{height:"3rem"}}>
                                        <label for='address'style={{color:"black"}}>Account Holder</label>
                                         <input type='text' placeholder='-' value={val?.accountHolderName}  name='address' ></input>
                                           
                                        </div>
                                        
                                    </div>
                                    
                                    <div className="row" style={{marginTop:"2rem", marginLeft:"0.5px"}}>
                                        <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-12 col-12 mb-0 full-input" style={{height:"3rem"}} >
                                        <label for='accnumber'style={{color:"black"}}>Account Number</label>
                                         <input type='accnumber' placeholder='-' value={val?.accountNumber}  name='accnumber' ></input>
                                        </div>
                                        <div className="mt-10 col-xxl-3 col-xl-2 col-lg-3 col-md-3 col-sm-12 col-12 mb-0 full-input" style={{height:"3rem"}}>
                                        <label for='text'style={{color:"black"}}>IFSC Code</label>
                                         <input type='text' placeholder='-' value={val?.ifscCode}  name='text' ></input>
                                           
                                        </div>
                                        
                                        <div className="col-md-12 col-lg-12 mt-3 py-3 px-0">
                          {/* <div className="radio">Cancelled Cheque / Passbook / Bank Statement</div> */}
                          <span>
                            {" "}
                            <input
                              type="radio"
                              id="Cancelled Cheque"
                              name="radio-group"
                              className="radio-custom"
                              value={'Cancelled Cheque'}
                              checked={val?.bankCopyName==='Cancelled Cheque'}
                            />{" "}
                            <label
                              className="radio-custom-label"
                              for="Cancelled Cheque"
                            >
                              Cancelled Cheque
                            </label>{" "}
                          </span>{" "}
                          &nbsp; &nbsp;
                          <span>
                            {" "}
                            <input
                              type="radio"
                              id="Passbook"
                              className="radio-custom"
                              name="radio-group"
                              value="Passbook"
                              checked={val?.bankCopyName==='Passbook'}
                            />{" "}
                            <label className="radio-custom-label" for="Passbook">
                              Passbook
                            </label>
                          </span>{" "}
                          &nbsp; &nbsp;
                          <span>
                            {" "}
                            <input
                              type="radio"
                              id="Bank Statement"
                              className="radio-custom"
                              name="radio-group"
                              value="Bank Statement"
                              checked={val?.bankCopyName==='Bank Statement'}
                            />{" "}
                            <label
                              className="radio-custom-label"
                              for="Bank Statement"
                            >
                              Bank Statement
                            </label>{" "}
                          </span>
                        </div>
                                        </div>
                                   
                                    <h6 style={{marginTop:"2rem"}}>Cancelled Cheque/ Passbook/ Bank Statement Image</h6>
                                    <div style={{paddingTop:"1rem"}} >
                                    <div  className="previewbox" >
                            <img src={val?.bankCopyImageUrl?val.bankCopyImageUrl:"not-found.png"}  style={{width:"100%", height:"100%"}}/>
                       
                    </div>
                    </div>
                                    {/* <br></br>  <br></br>   
                                     <div className="py-4 ">
                                    <div className="row">
                                        <div className="col-6">
                                        <div className="badge badge-primary " style={{ marginLeft: "25px", lineHeight: "20px", height: "30px", color:"white" }}>UPDATE</div>
                                       
                                        </div>

                                        <div className="col-6 ">
                                        <div className="badge badge-secondary badge-outlined" style={{ marginLeft: "-145px", lineHeight: "20px", height: "30px" }}> <button onClick={() => setVal(() => "")} style={{backgroundColor:"white"}}>RESET</button></div>
                                        </div>
                                                                             
                                    </div>
                                </div> */}
                                  
                                </form>
                                            </form>
                                        </div>
                                    </div>   
                                      </div>
                                      
                                </div>
                            </div>
                           
                        </div>
                    </div>
</div>
</div>
</div>
</div>
                </main>
            </div>
     )
}
export default BankDetails







