import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_STATE_FAILURE, FETCH_STATE_REQUEST, FETCH_STATE_SUCCESS } from "./types"

export const fetchStateRequest = () => {
    return {
        type: FETCH_STATE_REQUEST
    }
}
export const fetchStateSuccess = getState => {
    return {
        type: FETCH_STATE_SUCCESS,
        payload: getState
    }
}
export const fetchStateFailure = error => {
    return {
        type: FETCH_STATE_FAILURE,
        payload: error
    }
}



export const getState = () => async (dispatch) => {
    dispatch(fetchStateRequest)
    await axios.get(Constant.BASE_URL + 'pos/listStates')
        .then(response => {
            const result = response.data
            dispatch(fetchStateSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchStateFailure(errorMsg))
        })
}

