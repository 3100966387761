import React, { useState, useEffect } from "react";
import Footer from "../ReusableComponents/Footer";
import NavBar from "../ReusableComponents/NavBar";
import { Modal } from "react-bootstrap";
import { getQualification } from "../../store/actions/qualification";
import { useDispatch, useSelector } from "react-redux";
import { uploadProfile, updateAdhaarImagesBack,updateAdhaarImagesFront,updatePan,updateEducationCert,updateBankCopy } from '../../store/actions/Image'
import { updateTextDetails } from '../../store/actions/personalDetails'
import { alertShow } from '../../store/actions/alert'

// Styling

import "./ProfileDetail.css";

// React Bootstrap Breadcrumb
import { Breadcrumb, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Form1 from "./Form1";
import Form2 from "./Form2";
import { Link, useHistory } from "react-router-dom";
import { DeleteRounded } from "@material-ui/icons";
const User = JSON.parse(localStorage.getItem("user"));
const ProfileDetail = () => {
  const History = useHistory();
  const [showModal, setModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const handleCloseModal = () => {
    setModalShow(false)
    History.push('/');
  };
  const handleShowModal = () => setModalShow(true);
  const fname_regex = /^[a-zA-Z ]+$/;
  const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const mobile_regex = /^[0-9\b]+$/;
  const aadhar_regex = /^d{12}$/;
  const pan_regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  const [errors, setErrors] = useState({
    firstName: "",
    email: "",
    mobile: "",
    aadhar: "",
    pan: "",
  });
  const [personalDetail, setPersonalDetail] = useState({
    firstName: User?.firstName ? User?.firstName : "",
    email: User?.emailId ? User?.emailId : "",
    lastName: User?.lastName ? User?.lastName : "",
    mobile: User?.mobileNo ? User?.mobileNo : "",
    middleName: "",
    Adhaar_no: "",
    pan: "",
    addressLine1:"",
    addressLine2:"",
    landmark: "",
    othersIfAny:"",
    bankName:"",
    accountHolderName: User?.firstName ? User?.firstName : "",
    accountNumber: "",
    ifscCode:"",
    bankcopyName:"",
    isChecked: false,
  });
  const [accountFile, setAccountFile] = useState([]);
  const [ aadharFile, setAaadharFile] = useState([]);
  const [ aadharFileBack, setAaadharFileBack] = useState([]);
  const [photoFile, setPhotoFile] = useState([]);
  const [chequeFile, setChequeFile] = useState([]);
  const [offersFile, setOffersFile] = useState([]);
  const [cnicFile, setCnicFile] = useState([]);
  const [checked, setChecked] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const initialValues = { isChecked: false };
  const [selectState, setSelectState] = useState('')
  const [selectCity, setSelectCity] = useState('')
  const [selectPinCode, setSelectPinCode] = useState('')
  const [photoLoad, setphotoLoad] = useState(false)
  const [AadhaarLoad, setAadhaarLoad] = useState(false)
  const [panLoad, setpanLoad] = useState(false)
  const [eduload, seteduload] = useState(false)
  const [bankLoad, setbankLoad] = useState(false)
  const [validtion, setvalidtion] = useState(false)
  const [AadhaarLoadBack, setAadhaarLoadBack] = useState(false)
  
  const [formValues, setFormValues] = useState(initialValues);
  const qualificationState = useSelector(
    (state) => state.posQualification.data.data
  );
  const [selectQualification, setSelectQualification] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getQualification());

  }, []);
  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {
        // setShow(true)
        return true
      } 
    }
}, []);
const handleblue = () => {
  validtion && setFormErrors(validate(personalDetail));
  };
  const validate = (values) => {
    const errors = {};
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const fname_regex = /^[a-zA-Z ]+$/;
    const lname_regex = /([a-zA-Z]{1,30})$/;
    const mobile_regex = /^[0-9\b]+$/;
    const aadhar_regex = /^d{12}$/;
    const pan_regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    if (!values.firstName) {
      errors.firstName = "First Name is Required!";
    } else if (!fname_regex.test(values.firstName)) {
      errors.firstName = "Enter Valid First Name!";
    }
    if (values.email == "") {
      errors.email = "Email is Required!";
    } else if (!email_regex.test(values.email)) {
      errors.email = "Enter Valid Email!";
    }
    if (!values.mobile) {
      errors.mobile = "Mobile No. is Required";
    } else if (values.mobile.length < 10) {
      errors.mobile = "Mobile No. must be 10 Digits";
    } else if (!mobile_regex.test(values.mobile)) {
      errors.mobile = "Enter valid Mobile No.";
    }
    if (values.Adhaar_no == "") {
      errors.Adhaar_no = "Aadhaar No. is Required";
    } else if (!mobile_regex.test(values.Adhaar_no) || values.Adhaar_no.length != 12 || values.Adhaar_no.startsWith('0') || values.Adhaar_no.startsWith('1')) {
      errors.Adhaar_no = "Enter valid Aadhaar No.";
    }
    if (!values.mobile) {
      errors.mobile = "Mobile is required!";
    }
    if (!values.addressLine1) {
      errors.addressLine1 = "Address is required!";
    }
    if (!photoFile.length>0) {
      errors.photo = "Photo is required!";
    }
    if (!values.pan.length>0) {
      errors.pan = "Pan is required!";
    }
   
    if (!offersFile.length > 0) {
      errors.offer = "Education Proof is required!";
    }
    if (!aadharFile.length > 0) {
      errors.aadharFile = "Aadhar Front Image is required!";
      
    }
    if (!aadharFileBack.length > 0) {
      errors.aadharFileBack = "Aadhar Back Image is required!";
    }
    if (!cnicFile.length > 0) {
      errors.cnicFile = "Pan Image is required!";
    }
    if (selectState == "") {
      errors.state = "State is required!";
    } 
    if (selectCity == "") {
      errors.city = "City is required!";
    } 
    if (selectPinCode == "") {
      errors.pincode = "pincode is required!";
    } 
    if (selectQualification == "") {
      errors.selectQualification = "Education Qualification is required!";
    } 
    if (values.pan == "") {
      errors.pan = "Pan No. is required!";
    } else if (!pan_regex.test(values.pan)) {
      errors.pan = "Enter valid Pan No.";
    }
    if (!values.isChecked) {
      errors.isChecked = `Kindly accepts the terms and conditions to submit`;
    }
    return errors;
  };
  const handleSubmit = (e) => {

    e.preventDefault();
    setvalidtion(true)
    console.log(formValues, "formValuesformValues");
    setFormErrors(validate(personalDetail));
    if (Object.keys(validate(personalDetail)).length === 0) {
      
     const data= {
        "firstName":personalDetail.firstName,
        "middleName":personalDetail.middleName,
        "lastName":personalDetail.lastName,
        "mobileNo": personalDetail.mobile,
        "emailId":personalDetail.email,
        "pancardNo": personalDetail.pan,
        "addressLine1":personalDetail.addressLine1,
        "addressLine2":personalDetail.addressLine2,
        "landmark": personalDetail.landmark,
        "state":selectState,
        "city":selectCity,
        "pincode":selectPinCode,
        "educationalQualification": selectQualification,
        "othersIfAny":personalDetail.othersIfAny,
        "bankName":personalDetail.bankName,
        "accountHolderName":`${personalDetail.firstName} ${personalDetail.middleName} ${personalDetail.lastName}`,
        "accountNumber":parseInt(personalDetail.accountNumber),
        "ifscCode":personalDetail.ifscCode,
        "bankcopyName":personalDetail.bankcopyName,
        "aadhaarNo": parseInt(personalDetail.Adhaar_no),
        "photoUrl": photoFile[0],
         "aadhaarImageFront": aadharFile[0],
        "aadhaarImageBack": aadharFileBack[0],
        "pancardImageUrl": cnicFile[0],
        "eduProofImageUrl1": offersFile[0],
        "eduProofImageUrl2":offersFile[1],
      "bankCopyImageUrl": chequeFile[0]
        
    }
    console.log("data to be sent",data)
    updateTextDetails(data).then(res => {
     if(res=='Request failed with status code 400'){
      alertShow('err',"Required Valid Feilds!!")
     }else if (res.status==0 ||res?.errors){
       if(res?.errors.length){
        alertShow('err',JSON.stringify( res?.errors?.map((v)=>{
           if(v.msg=='Invalid value'){
                return `${v.param} : ${v.msg}`
           }else{return v.msg} 
         })))
       }
       if(res.status===0 && res.message){
        alertShow('err',JSON.stringify(res.message));
       }
     }
     else{
       if(res.status===1){
         handleShowModal();
       }
      
     }
    })
    .catch( (error)=> {
      alertShow('err',error.message);  
    });
    }
  };

  const personalchk = (sectionname, e) => {
    validtion && setFormErrors(validate(personalDetail));
    if (sectionname == "firstName" || sectionname == "lastName") {
      if (e.target.value != "" && !fname_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Only alphabets are allowed!" });
      } else {
        setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }
    if (sectionname == "email") {
      if (e.target.value != "" && !email_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Only alphabets are allowed!" });
        setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
      } else {
        setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }
    if (sectionname == "mobile") {
      if (e.target.value != "" && !mobile_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Only Numbers are allowed!" });
      } else {
        e.target.value !== "e"&& setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }
    if (sectionname == "Adhaar_no" && e.target.value.length<=12) {
      
        setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
        setErrors({ ...errors, [sectionname]: "" });
  
    }
    if (sectionname == "pan") {
      (e.target.value.length <=11) && setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname == "addressLine1" || sectionname == "addressLine2"||sectionname == "landmark" ||sectionname == "othersIfAny") {
    console.log(personalDetail,"personalDetail")
    setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
    }
    if (sectionname == "middleName" ||  sectionname == "bankName" || sectionname == "accountHolderName"||sectionname == "accountNumber" ||sectionname == "ifscCode" ||sectionname == "bankcopyName") {
    console.log(personalDetail,"personalDetail")
    setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
    }
  };
  const uploadSingleFile = (e, field) => {
    validtion && setFormErrors(validate(personalDetail));
    if (field == "Account") {
      setAccountFile([...accountFile, URL.createObjectURL(e.target.files[0])]);
      console.log("file", accountFile);
    }
    if (field == "Aadhaar") {
      if(e.target.files.length){
       if(!aadharFile.length){
        setAadhaarLoad(true)
        const formData = new FormData();
      formData.append("aadhaarImageFront",e.target.files[0]);
    
      updateAdhaarImagesFront(formData).then(res => {
        if(res.status==0){
          setErrors({ ...errors, Aadhaar: res?.message });
          setAadhaarLoad(false)
        }else{
        setAadhaarLoad(false)
        setAaadharFile([...aadharFile,res.aadhaarImageFront]);
        setErrors({ ...errors, Aadhaar: "" });}
      })
      .catch(error => {
        setAadhaarLoad(false)
        console.log(error.message,"Aadhaar eeee")
         setErrors({ ...errors, Aadhaar: error.message });
      })
       }
       
    }
    }
    if (field == "AadhaarBack") {
      if(e.target.files.length){
       
        setAadhaarLoadBack(true)
        const formData = new FormData();
      formData.append("aadhaarImageBack",e.target.files[0]);
    
      updateAdhaarImagesBack(formData).then(res => {
        if(res.status==0){
          setErrors({ ...errors, Aadhaar: res?.message });
          setAaadharFileBack(false)
        }else{
        setAadhaarLoadBack(false)
        setAaadharFileBack([...aadharFileBack,res.aadhaarImageBack]);
        setErrors({ ...errors, Aadhaar: "" });}
      })
      .catch(error => {
        setAadhaarLoadBack(false)
        console.log(error.message,"Aadhaar eeee")
         setErrors({ ...errors, Aadhaar: error.message });
      })
       
       
    }
    }
    if (field == "Account") {
      setAccountFile([...accountFile, URL.createObjectURL(e.target.files[0])]);
      console.log("file", accountFile);
    }
    if (field == "Photo") {
     
      setphotoLoad(true)
      const formData = new FormData();
      formData.append("photo",e.target.files[0]);
      uploadProfile(formData).then(res => {
        if(res.status==0){
          setErrors({ ...errors, photo: res?.message });
          setphotoLoad(false)
        }else{
          setphotoLoad(false)
            setPhotoFile([...photoFile,res.photoUrl]);

        }
      })
    .catch(error => {
      alertShow('err',error.message);
      setphotoLoad(false)
      setErrors({ ...errors, photo: error.message });
    })
    }
    if (field == "Offers") {
      seteduload(true)
      const formData = new FormData();
      formData.append("EduCert",e.target.files[0]);
      updateEducationCert(formData).then(res => {
        if(res.status==0){
          setErrors({ ...errors, eduProofImag: res?.message });
          seteduload(false)
        }else{
        seteduload(false)
        setOffersFile([...offersFile,res.eduProofImageUrl1]);}
      })
    .catch(error => {
      seteduload(false)
      setErrors({ ...errors, eduProofImag: error.message });
    })
    }
    if (field == "CNIC") {
      setpanLoad(true)
      const formData = new FormData();
      formData.append("pancard",e.target.files[0]);
      updatePan(formData).then(res => {
        if(res.status==0){
          setErrors({ ...errors, cnic: res?.message });
          setpanLoad(false)
        }else{
        setpanLoad(false)
        setCnicFile([...cnicFile,res.pancardImageUrl]);}
      })
    .catch(error => {
      setpanLoad(false)
      setErrors({ ...errors, cnic: error.message });
    })
    }
    if (field == "Cheque") {
      setbankLoad(true)
      const formData = new FormData();
      formData.append("bankCopy",e.target.files[0]);
      updateBankCopy(formData).then(res => {
        if(res.status==0){
          setErrors({ ...errors, updateBank: res?.message });
          setbankLoad(false)
        }else{
        setbankLoad(false)
        setChequeFile([...chequeFile,res.bankCopyImageUrl]);}
      })
    .catch(error => {
      setbankLoad(false)
      setErrors({ ...errors, updateBank: error.message });
    })

    }
  };

  const upload = (e) => {
    e.preventDefault();
    console.log(accountFile);
  };

  const deleteFile = (e, field) => {
    if (field == "Account") {
      const s = accountFile.filter((item, index) => index !== e);
      setAccountFile(s);
    }
    if (field == "Aadhaar") {
      const s = aadharFile.filter((item, index) => index !== e);
      setAaadharFile(s);
    }
    if (field == "AadhaarBack") {
      const s = aadharFileBack.filter((item, index) => index !== e);
      setAaadharFileBack(s);
    }
    if (field == "Photo") {
      const s = photoFile.filter((item, index) => index !== e);
      setPhotoFile(s);
    }
    if (field == "Offers") {
      const s = offersFile.filter((item, index) => index !== e);
      setOffersFile(s);
    }
    if (field == "CNIC") {
      const s = cnicFile.filter((item, index) => index !== e);
      setCnicFile(s);
    }
    if (field == "Cheque") {
      const s = chequeFile.filter((item, index) => index !== e);
      setCnicFile(s);
    }
  };
  console.log("personalDetail",personalDetail)
  return (
    <div className="main-div">
      <NavBar />

      {/* Breadcrumb Start */}
      <section className="Breadcrumb__Section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Become POS</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      {/* Breadcrumb End */}
      {/* Register Area Start */}
      <section className="Registration__Section">
        <div className="container px-5">
          <div className="row px-5">
            <div className="col-12 profileData-container">
              <h2 className="Register__Pos__Sub__Heading my-3 ml-3">
                Please Complete Your Details
              </h2>
              <Accordion defaultActiveKey="0" className="mt-1" >
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="accordion-header-1">
                    <p className="faqText text-primary">Personal Details</p>
                  </Accordion.Header>
                  <Accordion.Body className="accordion-header-1">
                    <Form1
                      personalchk={personalchk}
                      uploadSingleFile={uploadSingleFile}
                      deleteFile={deleteFile}
                      handleblue={handleblue}
                      aadharFile={aadharFile}
                      personalDetail={personalDetail}
                      errors={errors}
                      photoFile={photoFile}
                      formErrors={formErrors}
                      cnicFile={cnicFile}
                      photoLoad={photoLoad}
                      AadhaarLoad={AadhaarLoad}
                      aadharFileBack={aadharFileBack}
                      panLoad={panLoad}
                      AadhaarLoadBack={AadhaarLoadBack}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2" className="mt-3" active={true}>
                  <Accordion.Header className="accordion-header-1">
                    <p className="faqText text-primary">Contact Details</p>
                  </Accordion.Header>
                  <Accordion.Body className="accordion-header-1">
                    <Form2
                      personalchk={personalchk}
                      uploadSingleFile={uploadSingleFile}
                      deleteFile={deleteFile}
                      handleblue={handleblue}
                      accountFile={accountFile}
                      personalDetail={personalDetail}
                      errors={errors}
                      formErrors={formErrors}
                      selectState={selectState}
                      setSelectState={setSelectState}
                      selectCity={selectCity}
                      setSelectCity={setSelectCity}
                      selectPinCode={selectPinCode}
                      setSelectPinCode={setSelectPinCode}
                      cnicFile={cnicFile}
                    />
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1" className="mt-3">
                  <Accordion.Header className="accordion-header-1">
                    <p className="faqText text-primary">Education Details</p>
                  </Accordion.Header>
                  <Accordion.Body className="accordion-header-1">
                    <form className='pl-2'>
                      <div className="form-row mt-3">
                        <div className="col-md-12 col-lg-4 p-3">
                          <div className="profile-form-groupp w-100">
                            <select
                              value={selectQualification}
                              onChange={(e) =>{
                                 setSelectQualification(e.target.value);
                                 validtion &&setFormErrors(validate(personalDetail))
                              }
                              }
                              className="profile-form-controll w-100"
                            >
                              <option value="">Select Qualification</option>
                              {qualificationState &&
                                qualificationState.length > 0 &&
                                qualificationState.map(
                                  ({ id, qualification }) => {
                                    return (
                                      <option
                                        key={id}
                                        id={id}
                                        value={qualification}
                                      >
                                        {qualification}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                            <label
                              className="ms-3 profile-form-control-placeholderr"
                              for="education"
                            >
                             Last Educational Qualification*
                            </label>
                          </div>
                         <p className='p-error'>{selectQualification==""&&formErrors.selectQualification}</p>
                        </div>
                        {selectQualification ==='Others'&&
                        <div className="col-md-12 col-lg-4 p-3">
                          <div className="profile-form-groupp w-100">
                            <input
                              className="profile-form-controll w-100"
                              id="offers"
                              type="text"
                              name="offers"
                              onChange={(e) => personalchk('othersIfAny', e)}
                              value={personalDetail.othersIfAny}
                            />
                            <label
                              className="ms-3 profile-form-control-placeholderr"
                              for="offers"
                            >
                              Other ( if any)
                            </label>
                          </div>
                          <p className='p-error'>{errors.othersIfAny}</p>
                        </div>}
                      </div>

                      <div className="form-row">
                        {/* <h2 className='edu'>Education Proof</h2> */}
                        <label
                          style={{
                            fontFamily: "ErasDemiITC",
                            fontSize: "16px",
                            paddingLeft: "1.3rem",
                            paddingTop: "1rem",
                            paddingBottom: "1rem",
                          }}
                        >
                          Education Proof{"* "}
                        </label>
                      </div>
                      {/* <div className='form-row'> */}
                      <div className='col-md-12 col-lg-4 pt-lg-3 pl-3 topstop '>
					<label className='btn btn-primary me-3'  style={{ borderRadius: '5px',width:150 }}>
						{' '}
            {eduload?
						<span className='spinner-border spinner-border-sm'></span>
						:"Choose File"
						}
						<input
							type='file'
							className='file_'
							size='60'
							name='file'
							disabled={offersFile.length === 2}
							onChange={(e) => uploadSingleFile(e, 'Offers')}
						/>
					</label>
					{!offersFile.length > 0 && (
						<span className='col-md-4 col-lg-4 pt-lg-3 file-choose '>
							<label className='terms'> No file Chosen </label>
						</span>
					)}
         { !offersFile.length > 0 && <p className='p-error'>{errors.offer||formErrors.offer}</p>}
				</div>
                      
                      <div className="form-row">
                        <div className="col-md-12 col-lg-12 p-3">
                          <div className="row form-group">
                            {offersFile.length > 0 &&
                              offersFile.map((item, index) => {
                                return (
                                  <div className="preview" key={item}>
                                    <img className="img" src={item} alt="" />
                                    <img
                                      onClick={() =>
                                        deleteFile(index, "Offers")
                                      }
                                      className="button"
                                      src="./images/crossbtn.png"
                                      alt=""
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        {/* <div className="col-md-12 col-lg-4 pt-lg-3 pl-3 topstop">
                          <label
                            className="file_label"
                            style={{
                              borderRadius: "5px",
                              backgroundColor: "#29166F",
                            }}
                          >
                            {" "}
                            + Add
                            
                          </label>
                        </div> */}
                      </div>
                    </form>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" className="mt-3">
                  <Accordion.Header className="accordion-header-1">
                    <p className="faqText text-primary">Bank Details</p>
                  </Accordion.Header>
                  <Accordion.Body className="accordion-header-1">
                    <form className='pl-2'>
                      <div className="form-row mt-3">
                        <div className="col-md-12 col-lg-4 p-3">
                          <div className="profile-form-groupp w-100">
                            <input
                              className="profile-form-controll w-100"
                              id="offers"
                              type="text"
                              name="offers"
                              onChange={(e) => personalchk('bankName', e)}
                              value={personalDetail.bankName}
                            />
                            <label
                              className="ms-3 profile-form-control-placeholderr"
                              for="offers"
                            >
                              Bank Name
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4 p-3">
                          <div className="profile-form-groupp w-100">
                            <input
                              className="profile-form-controll w-100"
                              id="offers"
                              type="text"
                              name="offers"
                               onChange={(e) => personalchk('accountHolderName', e)}
                              value={`${personalDetail.firstName} ${personalDetail.middleName} ${personalDetail.lastName}`}
                            />
                            <label
                              className="ms-3 profile-form-control-placeholderr"
                              for="offers"
                            >
                              Account Holder Name
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-row mt-3">
                        <div className="col-md-12 col-lg-4 p-3">
                          <div className="profile-form-groupp w-100">
                            <input
                              className="profile-form-controll w-100"
                              id="offers"
                              type="number"
                              name="offers"
                              onChange={(e) => personalchk('accountNumber', e)}
                              value={personalDetail.accountNumber}
                            />
                            <label
                              className="ms-3 profile-form-control-placeholderr"
                              for="offers"
                            >
                              Account Number
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4 p-3">
                          <div className="profile-form-groupp w-100">
                            <input
                              className="profile-form-controll w-100"
                              id="offers"
                              type="text"
                              name="offers"
                              onChange={(e) => personalchk('ifscCode', e)}
                              value={personalDetail.ifscCode}
                            />
                            <label
                              className="ms-3 profile-form-control-placeholderr"
                              for="offers"
                            >
                              IFSC Code
                            </label>
                          </div>
                        </div>
                        <div></div>
                        <div className="col-md-12 col-lg-12 p-3">
                          {/* <div className="radio">Cancelled Cheque / Passbook / Bank Statement</div> */}
                          <span>
                            {" "}
                            <input
                              type="radio"
                              id="Cancelled Cheque"
                              name="radio-group"
                              className="radio-custom"
                              value="Cancelled Cheque"
                              onChange={(e) => personalchk('bankcopyName', e)}
                            />{" "}
                            <label
                              className="radio-custom-label"
                              for="Cancelled Cheque"
                            >
                              Cancelled Cheque
                            </label>{" "}
                          </span>{" "}
                          &nbsp; &nbsp;
                          <span>
                            {" "}
                            <input
                              type="radio"
                              id="Passbook"
                              className="radio-custom"
                              name="radio-group"
                              value="Passbook"
                              onChange={(e) => personalchk('bankcopyName', e)}
                            />{" "}
                            <label className="radio-custom-label" for="Passbook">
                              Passbook
                            </label>
                          </span>{" "}
                          &nbsp; &nbsp;
                          <span>
                            {" "}
                            <input
                              type="radio"
                              id="Bank Statement"
                              className="radio-custom"
                              name="radio-group"
                              onChange={(e) => personalchk('bankcopyName', e)}
                              value="Bank Statement"
                            />{" "}
                            <label
                              className="radio-custom-label"
                              for="Bank Statement"
                            >
                              Bank Statement
                            </label>{" "}
                          </span>
                        </div>
                        <div className='col-md-12 col-lg-4 pt-lg-3 pl-3 topstop '>
                        <label className='btn btn-primary me-3' style={{ borderRadius: '5px',width:150 }}>
                          {' '}
                          {bankLoad?
						<span className='spinner-border spinner-border-sm'></span>
						:"Choose File"
						}
                          <input
                            type='file'
                            className='file_'
                            size='60'
                            name='file'
                            disabled={chequeFile.length === 2}
                            onChange={(e) => uploadSingleFile(e, 'Cheque')}
                          />
                        </label>
                        {!chequeFile.length > 0 && (
                          <span className='col-md-4 col-lg-4 pt-lg-3 file-choose '>
                            <label className='terms'> No file Chosen </label>
                          </span>
                        )}
                          <p className='p-error'>{errors.updateBank}</p>
                      </div>
                     
                      
                      </div>
                      <div className="form-row">
                        <div className="col-md-12 col-lg-12 p-3">
                          <div className="row form-group">
                            {chequeFile.length > 0 &&
                              chequeFile.map((item, index) => {
                                return (
                                  <div className="preview" key={item}>
                                    <img className="img" src={item} alt="" />
                                    <img
                                      onClick={() =>
                                        deleteFile(index, "Cheque")
                                      }
                                      className="button"
                                      src="./images/crossbtn.png"
                                      alt=""
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              {/* <img src="./images/Banner.png" alt="banner image" /> */}

              <div className="form-row">
                <div className="privacypolicy-chechbox mt-4">
                  <input
                    className="acceptTandC"
                    style={{ width: "20px", paddingTop: "5rem" }}
                    type="checkbox"
                    defaultChecked={formValues.isChecked}
                    onChange={() => {
                      setChecked(!checked);
                      setPersonalDetail({
                        ...personalDetail,
                        isChecked: !personalDetail.isChecked,
                      });
                    }}
                  />
                  <span
                    className="terms ms-2"
                    style={{ fontSize: "16px", fontFamily: "ErasMediumITC" }}
                  >
                    {" "}
                    I Hereby declare that above submitted details are valid and
                    submitted by myself only
                  </span>
                  {!checked &&  <p className="p-error" style={{marginTop:15,marginLeft:-595}}>{formErrors.isChecked}</p>}
                </div>
              </div>
              <div className="text-center agreeDiv">
                <button
                  className="btn btn-primary agree Continue__Button mt-3"
                  onClick={handleSubmit}
                  style={{
                    width: "50%",
                    paddingLeft: "4rem",
                    fontSize: "38px",
                    fontWeight: "normal",
                    fontStyle: "ErasMediumITC",
                  }}
                >
                  Submit for Approval
                </button>

                <Modal
                  show={show}
                  onHide={()=> setShow(false)}
                  id="confirmnavigation"
                >
                  <Modal.Body>
                    <div className="modal-block">
                      <h3
                        style={{
                          paddingLeft: "0.5rem",
                          fontFamily: "ErasDemiITC",
                          color: "rgb(41, 22, 111)",
                          fontSize: "20px",
                        }}
                      >
                        Confirm Navigation
                      </h3>
                      <div className="mb-4">
                        <div className="d-flex justify-content-between ">
                          <label
                            className=""
                            style={{
                              textAlign: "center",
                              fontFamily: "Calibri",
                              color: "black",
                              paddingLeft: "5rem",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                fontFamily: "Calibri",
                                color: "black",
                              }}
                            >
                              Your content has not been properly saved yet!{" "}
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                fontFamily: "Calibri",
                                color: "black",
                              }}
                            >
                              Are you sure you want to leave this page?
                            </p>
                          </label>
                        </div>
                        <div className="py-4 ">
                          <div className="row" style={{ paddingLeft: "0rem" }}>
                            <div className="col-6">
                              <button
                                className="badge badge-primary "
                                onClick={()=>{
                                  setShow(false)
                                  window.onbeforeunload = function() {
                                    return false;
                                };
                                }}
                                style={{
                                  marginLeft: "65px",
                                  lineHeight: "20px",
                                  height: "30px",
                                  fontSize: "12px",
                                }}
                              >
                                Stay on this Page
                              </button>
                            </div>

                            <div className="col-6 ">
                              <button
                                className="badge badge-secondary badge-outlined"
                                style={{
                                  marginLeft: "-15px",
                                  lineHeight: "20px",
                                  height: "30px",
                                  fontSize: "12px",
                                }}
                                onClick={()=>{
                                  setShow(false)
                                  window.onbeforeunload = function() {
                                    return true;
                                };
                                }}
                              >
                                Leave this Page
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                <Modal show={showModal} onHide={handleCloseModal} id="thankyou">
                  <Modal.Body>
                    <div className="modal-block-close-btn">
                      <button
                        type="button"
                        className="btn text-muted modal-btn-size"
                        onClick={handleCloseModal}
                      >
                        <i className="bi bi-x-circle fas fa-lg modal-btn-icon"></i>
                      </button>
                    </div>
                    <div className="modal-block">
                      <img
                        className="thankyou"
                        src="images/thankyou.png"
                        alt="alternative"
                        style={{ width: "100px" }}
                      />
                      <h2 style={{ paddingLeft: "0rem" }}>Thank You!</h2>
                      <div className="mb-4">
                        <div className="d-flex justify-content-between">
                          <label
                            className=""
                            style={{
                              textAlign: "center",
                              fontFamily: "Calibri",
                              color: "black",
                              fontSize:"18px", lineHeight:"2rem"
                            }}
                          >
                            for submitting your information. One of our
                            executive will contact you for further process
                          </label>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Register Area End */}

      <Footer />
    </div>
  );
};

export default ProfileDetail;
