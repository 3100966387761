import React, { useEffect, useState } from "react";
//import axios from 'axios'
import "./MyTraining.scss";
//import { makeStyles } from '@material-ui/core';
import Carousel from "react-bootstrap/Carousel";
import { useHistory } from "react-router-dom";
import { alertShow } from "../../../store/actions/alert";
import { MyTimer } from "./Timer";

// import Banner from "../../../assets/bgs/Banner.jpg";
// import certificate from "../../../assets/bgs/certificate.png";
import {
  trainingStart,
  traningCompl,
  isTrainingCompleted,
  GetTrainingduration,
} from "../../../store/actions/getPosPersonalDetails";

import Countdown from "react-countdown";
const MyTraining = (props) => {
  const { pathname } = window.location;
  const history = useHistory();
  const [active, setActive] = useState(false);
  const [val, setVal] = useState();
  const [page, setpage] = useState(1);
  const [timer, setTimer] = useState(Date.now() + 54000000);
  const [isPaise, setPause] = useState(false);
  const [isFinish, setFinish] = useState(false);
  const [Next, setNext] = useState(true);
  const [isCompleted, setIsCompleted] = useState("");
  const [detailSubmitted, setDetailSubmitted] = useState(false);

  function handleChange(newValue) {
    setFinish(newValue);
  }
  const handleChangePage = () => {
    window.scrollTo(0, 0);
    if (isFinish) {
      trainingStart()
        .then((res) => {
          if (res.status) {
            setActive(true);
          }
        })
        .catch((error) => {
          alertShow("err", error.message);
        });
    } else {
      setActive(true);
    }
  };

  const time = new Date();

  useEffect(() => {
    // Update the document title using the browser API
    isTrainingCompleted()
      .then((res) => {
        // alert(JSON.stringify(res.data.message))
        if (
          res.data.message === "Training is PENDING" ||
          res.data.message === "Details Submitted" ||
          res.data.message === "Approved"
        ) {
          // setFinish(false)
          setIsCompleted(false);
          if (res.data.message === "Details Submitted") {
            setDetailSubmitted(true);
          }
        } else if (
          res.data.message === "Training is Completed" ||
          res.data.message === "Pass" ||
          res.data.message === "Fail" ||
          res.data.message === "Exam Completed" ||
          res.data.message === "Agreement Pending" ||
          res.data.message === "Finally Approved"
        ) {
          setIsCompleted(true);
          // setFinish(true);
        } else {
          setFinish(true);
        }
      })
      .catch((error) => {
        alertShow("err", error.message);
      });

    GetTrainingduration()
      .then((res) => {
        if (res && res.data && res.data.training_duration != null) {
          time.setSeconds(
            time.getSeconds() + parseInt(res.data.training_duration)
          );
          // time.setSeconds(time.getSeconds() + 171);
        } else {
          time.setSeconds(time.getSeconds() + 180);
        }
      })
      .catch((error) => {
        alertShow("err", error.message);
      });
  });

  const nextButtonClick = () => {
    if (page == 75) {
      setTimeout(() => {
        setpage(1);
      }, 300);
    } else {
      setTimeout(() => {
        setpage(page + 1);
      }, 300);
    }
  };

  // const finishButtonClick = () =>{
  //   traningCompl().then(res => {

  //     // alert(JSON.stringify(res))
  //     setFinish(true)
  //    })
  //    .catch( (error)=> {

  //     alertShow('err',error.message);
  //    });

  //   // setFinish(true)
  //   // localStorage.setItem('isFin', true);

  // };

  const pauseButtonClick = () => {
    setPause(!isPaise);
  };

  const prevButtonClick = () => {
    if (page == 1) {
      setTimeout(() => {
        setpage(75);
      }, 300);
    } else {
      setTimeout(() => {
        setpage(page - 1);
      }, 300);
    }
  };

  const Completionist = () => <span>Your training is completed</span>;
  // const renderer = ({ hours, minutes, seconds, completed }) => {
  //  if (completed) {
  //   setFinish(true)
  //   localStorage.setItem('isFin', true);
  //    // Render a complete state
  //    return <Completionist />;
  //  } else {
  //    // Render a countdown
  //    return (
  //      <span>
  //        {hours}:{minutes}:{seconds}
  //      </span>
  //    );
  //  }
  // };

  return (
    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
      <main className="mainBodyContainer" style={{ height: "200vh" }}>
        <div className="row my-2">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
            <div className="bg-transparent p-2  rounded-3">
              <nav
                style={{
                  bsbreadcrumbdivider:
                    'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")',
                }}
                aria-label="breadcrumb"
              >
                <h6>My Training</h6>
                <ol className="breadcrumb pt-2 mb-0 bg-transparent">
                  <li className="breadcrumb-item bg-transparent">
                    <a
                      href="/"
                      className="text-decoration-none "
                      style={{ fontFamily: "Calibri" }}
                    >
                      Dashboard
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-muted"
                    aria-current="page"
                  >
                    Account Information
                  </li>
                  <li
                    className="breadcrumb-item text-muted"
                    aria-current="page"
                  >
                    My Training
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </main>
      {Next ? (
        <div className="trainingPng" style={{ marginTop: "-20px" }}>
          <img src="images/training.png" width="99%" height="auto" />
          {isCompleted ? (
            <button onClick={() => setNext(false)}>
              Training Completed / View Modules
            </button>
          ) : (
            <div>
              {/* {detailSubmitted ? ( */}
              {/* <button disabled>Waiting for approval from admin</button> */}
              {/* ) : ( */}
              <button onClick={() => setNext(false)}>Start Training</button>
              {/* )} */}
            </div>
          )}
        </div>
      ) : (
        <>
          {!active ? (
            <div
              className="section-examination"
              style={{ marginTop: "-20px", marginBottom: "20px" }}
            >
              <div className="tbody">
                <div
                  className="container starttraining"
                  style={{
                    // backgroundColor: " #F8F5FF",
                    fontFamily: "ErasDemiITC ",
                  }}
                >
                  <div className="tbody-section__main-content training ">
                    <h1 style={{ fontFamily: "ErasDemiITC" }}>
                      {!isCompleted ? "Start Your Training" : "List of Modules"}
                    </h1>

                    <div className="Intro">
                      <br></br>{" "}
                      <label
                        style={{
                          fontSize: "18px",
                          textAlign: "center",
                          paddingLeft: "17rem",
                        }}
                      >
                        SYLLABUS FOR POINT OF SALE PERSON EXAMINATION
                      </label>
                      <div style={{ textAlign: "left" }}>
                        <br></br>
                        <h6>MODULE -1 </h6>
                        <ul style={{ fontSize: "18px", fontFamily: "Calibri" }}>
                          <li>1) About POS Person.</li>
                          <li>2) Introduction to Insurance.</li>
                        </ul>
                        <br></br>
                        <h6>MODULE -2 </h6>
                        <ul style={{ fontSize: "18px", fontFamily: "Calibri" }}>
                          <li>Indian Insurance Market.</li>
                        </ul>
                        <br></br>
                        <h6>MODULE -3 </h6>
                        <ul style={{ fontSize: "18px", fontFamily: "Calibri" }}>
                          <li>Principles and Practices of Insurance.</li>
                        </ul>
                        <br></br>
                        <h6>MODULE -4 </h6>
                        <ul style={{ fontSize: "18px", fontFamily: "Calibri" }}>
                          <li>
                            POS General Insurance Products. ( Specified Products
                            Only ).
                          </li>
                        </ul>
                        <br></br>
                        <h6>MODULE -5 </h6>
                        <ul style={{ fontSize: "18px", fontFamily: "Calibri" }}>
                          <li>Miscellaneous Topics .</li>
                        </ul>
                      </div>
                    </div>
                    <div className="tbody-section__main-content__examination-information__btn-exam btn-training">
                      {isCompleted ? (
                        <button
                          className="btn-button"
                          style={{ fontFamily: "ErasDemiITC" }}
                          onClick={() => {
                            setActive(true);
                          }}
                        >
                          Training Completed / View Training
                        </button>
                      ) : (
                        <button
                          className="btn-button"
                          onClick={handleChangePage}
                          style={{ fontFamily: "ErasDemiITC" }}
                        >
                          START TRAINING
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className="topic-section"
                style={{ backgroundColor: "white", marginTop: "-20px" }}
              >
                <div className="topic-section__carousel">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide carousel-fade "
                    data-ride="carousel"
                    data-interval="false"
                  >
                    {!isFinish ? (
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="Scroll-bar" data-interval="false">
                            <h1
                              style={{
                                fontFamily: "ErasDemiITC",
                                color: "#0093dd",
                              }}
                            >
                              Module 1
                            </h1>{" "}
                            <br></br>
                            <h4
                              style={{
                                fontFamily: "ErasDemiITC",
                                color: "gray",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              POINT OF SALE PERSONS (POS PERSONS)
                            </h4>{" "}
                            <br></br>
                            <label
                              style={{
                                fontSize: "16px",
                                fontFamily: "ErasDemiITC",
                              }}
                            >
                              Background{" "}
                            </label>
                            <div
                              className="Para"
                              style={{
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <p>
                                Insurance Regulatory and Development Authority
                                of India has observed that there are number of
                                persons who are involved in undertaking simple
                                and routine activities pertaining to
                                solicitation and marketing of insurance
                                policies. For e.g. bulk of products in motor
                                insurance, travel insurance, personal accident
                                insurance, etc. require very little
                                underwriting. These happen to be largely
                                pre-underwritten products wherein based on the
                                information provided by the prospect, the
                                insurance policy is automatically generated by
                                the system. The intervention required for such a
                                product is minimal and the training and
                                examination for such persons could be of a
                                lesser degree. In order to facilitate the growth
                                of insurance business in the country and to
                                enhance insurance penetration and insurance
                                density, the Authority as part of its
                                developmental agenda has issued the following
                                guidelines on “Point of Sales Persons”.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  color: "#0093dd",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                1. Eligibility Conditions for POS Person
                              </label>
                              <span>
                                <p>
                                  Any person, who has attained age of 18 years
                                  as on date of application for POS and passed
                                  at least 10th Examination and desirous to work
                                  for the Non-Life Insurance Co. on direct basis
                                  or through any intermediary i.e. Agent,
                                  Brokers is eligible to work as POS.{" "}
                                </p>
                              </span>

                              <label
                                style={{
                                  fontSize: "16px",
                                  color: "#0093dd",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                2. Training and Examination:
                              </label>
                              <span>
                                <p>
                                  Minimum 15 Hrs Training is required to be
                                  imparted to the candidate who on successful
                                  completion of the training has to pass the
                                  Examination.
                                </p>{" "}
                              </span>

                              <label
                                style={{
                                  fontSize: "16px",
                                  color: "#0093dd",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                3. Disqualification:
                              </label>
                              <span>
                                <p>
                                  Any applicant whose name is appearing in
                                  Agency data base of IRDA for general Insurance
                                  or POS data base of IIB as POS person of any
                                  other Insurer. The application of such
                                  applicant shall not be considered.
                                </p>{" "}
                              </span>

                              <label
                                style={{
                                  fontSize: "16px",
                                  color: "#0093dd",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                4. Documentation{" "}
                              </label>
                              <span>
                                <p>
                                  1. Application form completed and signed by
                                  applicant.{" "}
                                </p>
                                <p>2. PAN CARD – Self attested copy. </p>
                                <p>3. AADHAAR CARD - Self attested copy.</p>
                                <p>
                                  4. Self Attested Educational Qualification
                                  Certificate.{" "}
                                </p>
                                <p>
                                  5. Self Attested copy of Latest address proof
                                </p>
                                <p>6. Two Photographs </p>
                              </span>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 1/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <div
                              className="Para"
                              style={{
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <br></br>
                              <p>
                                <b>
                                  “Point of Sales Person” can solicit and market
                                  only certain pre- underwritten products
                                  approved by the Authority.
                                </b>
                              </p>
                              <p>
                                <b>
                                  Every “Point of Sales Person” shall be
                                  identified by his Aadhaar Card Number or his
                                  PAN Card
                                </b>
                              </p>
                              <p>
                                An insurance company or an insurance
                                intermediary can engage a “Point of Sales
                                Person” to represent him.{" "}
                              </p>
                              <br></br>
                              <h5 style={{ color: "gray" }}>
                                Do's and Don'ts for POS Person:{" "}
                              </h5>
                              <label
                                style={{
                                  fontSize: "16px",
                                  color: "#0093dd",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Do's for POS Person
                              </label>{" "}
                              <br></br>
                              <br></br>
                              <p>
                                {" "}
                                a) POSP has to conduct business with utmost good
                                lath and integrity, with due care and diligence.{" "}
                              </p>
                              <p>
                                b) Identify himself and show the appointment
                                letter it a prospect demands it.
                              </p>
                              <p>
                                c) He has to provide information about insurance
                                products, on sale. While advising the prospect
                                to purchase a specific insurance policy, he has
                                to take into account needs of the prospect.{" "}
                              </p>
                              <p>
                                d) He has to keep all information given by the
                                prospect, confidential,{" "}
                              </p>
                              <p>
                                e) He has to disclose the commission he earns,
                                if asked by prospect.
                              </p>
                              <p>
                                f) He has to indicate premium that will be
                                charged.{" "}
                              </p>
                              <p>
                                g) He has to explain the prospect the
                                information and other details, and its
                                importance that will be required for insurance /
                                intermediary.{" "}
                              </p>
                              <p>
                                h) He has to inform insurance company /
                                intermediary about any adverse health
                                conditions, personal habits or income
                                inconsistencies of their prospect in a
                                confidential report, along with every proposal.{" "}
                              </p>
                              <p>
                                i) He has to inform the consequences of
                                non-disclosure and inaccuracies to prospect.{" "}
                              </p>
                              <p>
                                J) He has to communicate promptly to prospect
                                about. acceptance / rejection of a proposal.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 2/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <h2
                              style={{
                                fontFamily: "ErasDemiITC",
                                color: "gray",
                              }}
                            >
                              {" "}
                            </h2>
                            <br></br>
                            <div
                              className="Para"
                              style={{
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <p>
                                k. &nbsp; He has to advice policyholder to
                                effect nomination or assignment or change of
                                address or exercise of option, as the case may
                                be, and provide necessary assistance in these
                                matters.{" "}
                              </p>
                              <p>
                                l. &nbsp; He has to provide assistance to the
                                Policy holders/ claimants / beneficiaries in
                                submitting requirements for the settlement of
                                claims to the insurance company( intermediary.{" "}
                              </p>
                              <p>
                                m. &nbsp; He has to forward the information
                                received from the policyholder regarding claim
                                or any event likely to cause claim, without
                                delay:{" "}
                              </p>
                              <p>
                                n. &nbsp; He has to communicate the insurance
                                company intermediary decision regarding claim to
                                the claimant without delay.
                              </p>
                              <p>
                                o. &nbsp; He has to provide all reasonable
                                assistance to claimant in pursuing the claim.{" "}
                              </p>
                              <p>p. &nbsp; He has to ensure compliance of </p>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <li>Section 64-VB of Insurance Act, 1938 </li>
                                &nbsp;
                                <li>
                                  Section 41 of the insurance Act, 1938 by the
                                  drawing attention of the prospect
                                </li>
                                &nbsp;
                                <li>
                                  Anti Money Laundering [AML] and Know Your
                                  Customer [KYC] guidelines{" "}
                                </li>
                                &nbsp;
                              </ol>
                              <label
                                style={{
                                  fontSize: "16px",
                                  color: "#0093dd",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Don'ts for POS Person:
                              </label>{" "}
                              <br></br>
                              <br></br>
                              <ol type="a" style={{ fontSize: "16px" }}>
                                <li>
                                  Do not to solicit or accept any insurance
                                  business without a valid appointment letter
                                </li>{" "}
                                &nbsp;
                                <li>
                                  Do not induce prospect to omit any material
                                  information in the proposal form
                                </li>{" "}
                                &nbsp;
                                <li>
                                  Do not induce prospect to submit wrong
                                  information in the proposal form or the
                                  documents submitted to insurance company for
                                  acceptance of proposal{" "}
                                </li>{" "}
                                &nbsp;
                                <li>
                                  Do not behave in discourteous manner with the
                                  prospect, the policyholder or the claimant{" "}
                                </li>{" "}
                                &nbsp;
                                <li>
                                  Do not interfere with proposals brought by
                                  others
                                </li>
                                &nbsp;
                                <li>
                                  {" "}
                                  Do not offer different rates and terms than
                                  those offered by insurer
                                </li>
                                &nbsp;
                                <li>
                                  Do not demand or receive benefit/share out of
                                  insurance money paid to the beneficiary
                                </li>
                                &nbsp;
                                <li>
                                  Do not force policy holders to terminate
                                  existing and to effect a new policy.
                                </li>
                                &nbsp;
                              </ol>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 3/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <div>
                              <label
                                style={{
                                  fontSize: "16px",
                                  color: "gray",
                                  lineHeight: "26px",
                                }}
                              >
                                SPECIFIC/ PRE UNDERWRITTEN PRODUCTS TO
                                BESOLICITED AND MARKETED BY “POINT OF SALES
                                PERSON”{" "}
                              </label>
                            </div>{" "}
                            <br></br>
                            <div>
                              <br></br>
                              <ol
                                style={{
                                  listStyleType: "1",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  Motor Comprehensive Insurance Package Policy
                                  for Two-wheeler, Private car and Commercial
                                  vehicles.
                                </li>{" "}
                                &nbsp;
                                <li>
                                  Third party liability (Act only) Policy for
                                  Two-wheeler, Private car and Commercial
                                  vehicles.
                                </li>{" "}
                                &nbsp;
                                <li>Personal Accident Policy</li> &nbsp;
                                <li>
                                  Third party liability (Act only) Policy for
                                  Two-wheeler, Private car and Commercial
                                  vehicles.
                                </li>{" "}
                                &nbsp;
                                <li>Personal Accident Policy</li> &nbsp;
                                <li>Travel Insurance Policy</li> &nbsp;
                                <li>Home Insurance Policy</li> &nbsp;
                                <li>Cattle/ Live stock</li> &nbsp;
                                <li>Agricultural Pump Set Insurance</li> &nbsp;
                                <li>
                                  {" "}
                                  Fire and Allied Perils policy for Dwellings
                                </li>
                                &nbsp;
                                <li>
                                  Crop Insurance (Govt Schemes such as Pradhan
                                  Mantri Fasal Bima Yojna( PMFBY)
                                </li>{" "}
                                &nbsp;
                                <li>
                                  {" "}
                                  Whether based crop Insurance Scheme (WBCIS)
                                </li>{" "}
                                &nbsp;
                                <li>Coconut Palm Insurance Scheme (CPIS )</li>
                              </ol>
                            </div>
                            <p>
                              Any other Policy specifically approved by the
                              Authority from time to time.{" "}
                            </p>
                            <br></br>
                            <label
                              style={{
                                fontSize: "18px",
                                fontFamily: "ErasDemiITC",
                              }}
                            >
                              Note:
                            </label>
                            <p>
                              Every policy sold by the Point of sale person will
                              be separately identified and pre fixed by the name
                              –POS ( Name of the product )
                            </p>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 4/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div className="Para">
                              <h4
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  color: "gray",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                INTRODUCTION TO INSURANCE
                              </h4>{" "}
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Topics to be covered:{" "}
                              </label>{" "}
                              <br />
                              <ol
                                style={{
                                  listStyleType: "1",
                                  fontWeight: "bold",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                {" "}
                                &nbsp;
                                <li>
                                  {" "}
                                  Basic Philosophy and concept of Insurance.{" "}
                                </li>
                                <li>Purpose and need of Insurance. </li>
                                <li>Concept of Risk, Peril and Hazard. </li>
                              </ol>{" "}
                              <br></br>
                              <h4
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  color: "#2196F3",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                BASIC PHILOSOPHY / CONCEPT OF INSURANCE:
                              </h4>{" "}
                              <br></br>
                              <div
                                className="para"
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <p>
                                  <b>
                                    Insurance basic concept is spreading and
                                    sharing a risk among a number of persons,
                                  </b>
                                  who are exposed to similar risk. Accident may
                                  happen to a few among the group who made a
                                  pool and those suffering loss/damage are
                                  compensated from the pooled amount.
                                </p>
                                <p>
                                  Out of this fund, the insurance company will
                                  compensate for loss to those unfortunate ones
                                  who suffered loss.{" "}
                                </p>{" "}
                                <br></br>
                                <b>Thus insurance is a: </b> <br></br>
                                <b>“The collective bearing of risk .”</b>{" "}
                                <br></br> <br></br>
                                <b>
                                  System by which risk is transferred by a
                                  person, business or an organisation to an
                                  Insurance Company which reimburses the insured
                                  for a loss covered under insurance policy.{" "}
                                  <br></br>{" "}
                                </b>
                                <p>
                                  Obviously someone has to initiate and organise
                                  the process and bring members of the community
                                  together for this purpose. That someone is
                                  known as an Insurer who determines the
                                  contribution that each individual must make to
                                  the pool and arranges to pay to those who
                                  suffer the loss.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 5/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <h4
                              style={{
                                fontFamily: "ErasDemiITC",
                                color: "gray",
                              }}
                            >
                              {" "}
                              HOW INSURANCE WORKS
                            </h4>{" "}
                            <br></br>
                            <div
                              className="Para"
                              style={{
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <ul>
                                <li>
                                  a) &nbsp; Firstly, these must be an asset
                                  which has an economic value. The ASSET
                                </li>{" "}
                                &nbsp;
                                <ul>
                                  <li>
                                    {" "}
                                    i. &nbsp;May be physical (like a car or a
                                    building) or{" "}
                                  </li>
                                  <li>
                                    {" "}
                                    ii. &nbsp;May be non-physical (like name and
                                    goodwill) or{" "}
                                  </li>
                                  <li>
                                    {" "}
                                    iii. &nbsp;May be personal (like one’s eyes,
                                    limbs and other aspects of one’s (Body){" "}
                                  </li>
                                </ul>{" "}
                                &nbsp;
                                <li>
                                  b) &nbsp; The asset may lose its value if a
                                  certain event happens. This chance of loss is
                                  called as risk. The cause of the risk event is
                                  known as peril.{" "}
                                </li>{" "}
                                &nbsp;
                                <li>
                                  c) &nbsp; There is a principle known as
                                  pooling. This consists of collecting numerous
                                  individual contributions (known as premiums)
                                  from various persons. These persons have
                                  similar assets which are exposed to similar
                                  risks. This is managed by INSURER.{" "}
                                </li>
                                &nbsp;
                                <li>
                                  d) &nbsp;This pool of funds is used to
                                  compensate the few who might suffer the losses
                                  as caused by a peril.{" "}
                                </li>{" "}
                                &nbsp;
                                <li>
                                  e) &nbsp; This process of pooling funds and
                                  compensating the unlucky few is carried out
                                  through an institution known as the insurer.{" "}
                                </li>{" "}
                                &nbsp;
                                <li>
                                  f) The insurer enters into an insurance
                                  contract with each person who seeks to
                                  participate in the scheme. Such a participant
                                  is known as insured.
                                </li>{" "}
                                &nbsp; <br></br>{" "}
                              </ul>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 6/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <h4
                              style={{
                                fontFamily: "ErasDemiITC",
                                color: "#2196F3",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              PURPOSE AND NEED OF INSURANCE:
                            </h4>{" "}
                            <br></br>
                            <b style={{ fontSize: "16px" }}>
                              We can highlight the significance of insurance, in
                              terms of the following advantages offered by it:{" "}
                            </b>{" "}
                            <br></br> <br></br>
                            <div style={{ paddingLeft: "10rem" }}>
                              <div
                                style={{
                                  border: "1px solid black",
                                  width: "60%",
                                }}
                              >
                                <h5 style={{ textAlign: "center" }}>
                                  Significance of insurance- at a glance
                                </h5>

                                <ul>
                                  <li>1. Concentration on business issues</li>
                                  <li>2. Better utilization of capital</li>
                                  <li>3. Promotion of foreign trade</li>
                                  <li>4. Feeling of security to dependents</li>
                                  <li>5. Social welfare</li>
                                  <li>
                                    6. Speeding up the process of economic
                                    development
                                  </li>
                                  <li>
                                    7. Generation of employement opportunities.
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <ul
                              style={{
                                fontSize: "16px",
                                fontFamily: "Calibri",
                              }}
                            >
                              {" "}
                              &nbsp;
                              <li>
                                <b>
                                  (i)&nbsp; CONCENTRATION ON BUSINESS ISSUES:
                                </b>
                              </li>{" "}
                              &nbsp;
                              <p>
                                Insurance help businessmen to concentrate their
                                attention on business issues, as their risks are
                                undertaken by the insurance company. Insurance
                                gives them peace of mind. Thus due to insurance,
                                business efficiency increases.
                              </p>{" "}
                              &nbsp;
                              <li>
                                <b>(ii)&nbsp; BETTER UTILIZATION OF CAPITAL:</b>
                              </li>{" "}
                              &nbsp;
                              <p>
                                Businessmen, in the absence of insurance, will
                                maintain funds for meeting future contingencies.
                                Insurance does away with this need to maintain
                                contingency funds by them. Thus businessmen can
                                better utilize their funds for business
                                purposes.
                              </p>{" "}
                              &nbsp;
                              <li>
                                <b>(iii) &nbsp;PROMOTION OF FOREIGN TRADE: </b>
                              </li>{" "}
                              &nbsp;
                              <p>
                                There are many risks in foreign trade much more
                                than involved in home trade. Insurance of risks
                                involved in foreign trade gives a boost to it
                                volume, which is a healthy feature of economic
                                development.
                              </p>
                            </ul>{" "}
                            <br></br>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 7/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <ul>
                                {" "}
                                &nbsp;
                                <li>
                                  <b>
                                    (iv)&nbsp; FEELING OF SECURITY TO
                                    DEPENDENTS:
                                  </b>
                                </li>{" "}
                                &nbsp;
                                <p>
                                  Life insurance provides a feeling of economic
                                  security to the dependents of the insured, on
                                  whose life insurance is affected.
                                </p>{" "}
                                &nbsp;
                                <li>
                                  <b>(v) &nbsp; SOCIAL WELFARE: </b>
                                </li>{" "}
                                &nbsp;
                                <p>
                                  Life insurance also provides for policies in
                                  respect of education of children, marriage of
                                  children etc. Such special policies provide a
                                  sense of security to the poor who take these
                                  policies. Thus life insurance is a device for
                                  ensuring social welfare.
                                </p>
                                &nbsp;
                                <li>
                                  <b>
                                    (vi) &nbsp; SPEEDING UP THE PROCESS OF
                                    ECONOMIC DEVELOPMENT:{" "}
                                  </b>
                                </li>
                                &nbsp;
                                <p>
                                  Insurance companies mobilize the savings of
                                  community through collection of premiums, and
                                  invest these savings in productive channels.
                                  This process speeds up economic development.
                                  Huge funds at the disposal of LIC (Life
                                  Insurance Corporation) available for
                                  investment purposes support the
                                  above-mentioned point of advantage of
                                  insurance.{" "}
                                </p>
                                &nbsp;
                                <li>
                                  <b>
                                    (vii) &nbsp; GENERATION OF EMPLOYMENT
                                    OPPORTUNITIES:{" "}
                                  </b>
                                </li>
                                &nbsp;
                                <p>
                                  Insurance companies provide a lot of
                                  employment in the economy. This is due to the
                                  ever growing business done by insurance
                                  companies.
                                </p>
                              </ul>
                              <br></br>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 8/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div>
                              <br></br>
                              <h4
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  color: "#2196F3",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                CONCEPT OF RISK, PERIL AND HAZARD
                              </h4>{" "}
                              <br></br>
                              <label
                                style={{
                                  fontSize: "18px",
                                  paddingLeft: "1rem",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                RISK:{" "}
                              </label>{" "}
                              <br></br>
                              <br></br>
                              <div
                                style={{
                                  paddingLeft: "2rem",
                                  paddingRight: "1rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <p>
                                  The term Risk, refers to a situation of
                                  uncertain possibility of loss or damage. Only
                                  uncertain risks are covered by insurance.
                                </p>
                              </div>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "18px",
                                  paddingLeft: "1rem",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                PERIL :{" "}
                              </label>{" "}
                              <br></br>
                              <br></br>
                              <div
                                style={{
                                  paddingLeft: "2rem",
                                  paddingRight: "1rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <p>
                                  A peril is the cause of loss .Anything which
                                  causes the loss/damage is a peril. For
                                  example: Fire, Flood, Earthquake, Accident,
                                  Lightening, Landslide etc. Further, an event
                                  or incident that may cause a loss is called a
                                  Peril.
                                </p>
                                <p>
                                  Perils differ according to the frequency and
                                  intensity. If such perils can cause damage to
                                  an asset, we say that the asset is exposed to
                                  that risk. Risk may be:
                                </p>
                                <div style={{ marginTop: "1rem" }}>
                                  <ul>
                                    <li>i) Catastrophic</li> <br></br>
                                    <li>
                                      ii) Financial / Non-financial risks
                                      (Health)
                                    </li>
                                    <br></br>
                                    <li>iii) Dynamic / Static risk.</li>
                                  </ul>
                                  <div
                                    style={{
                                      marginLeft: "4rem",
                                      marginRight: "2rem",
                                    }}
                                  >
                                    <p>
                                      Dynamic results from change in economy,
                                      price level, consumer taste, Fashion. Such
                                      risk affects a large number of
                                      individuals.
                                    </p>
                                    <p>
                                      Static Risks Destruction of an asset,
                                      dispossession as a result of dishonesty.
                                      Such risks can be managed through
                                      insurance as there frequency and
                                      possibility can be studied and forecast.
                                    </p>
                                  </div>
                                  <br></br>
                                  <div
                                    style={{
                                      marginRight: "3rem",
                                      fontFamily: "Calibri",
                                      fontSize: "16px",
                                    }}
                                  >
                                    <ul>
                                      <li>i) Pure and Speculative Risk ,</li>{" "}
                                      <br></br>
                                      <p>
                                        <b>Speculative risk </b>describes a
                                        situation in which the consequence can
                                        be either a profit or a loss. Typical
                                        examples of taking such risk are
                                        gambling on horses or stock market
                                        speculation. One assumes such risk
                                        deliberately in the hope of a gain.{" "}
                                        <b>
                                          SPECULATIVE RISKS ARE NOT INSURABLE.{" "}
                                        </b>
                                      </p>
                                      <p>
                                        <b>Pure risk </b>on the other hand
                                        involves situations in which the outcome
                                        can result only in loss or no loss, but
                                        never in gain.
                                      </p>
                                      <p>
                                        For example, a flood or a fire either
                                        occurs or does not occur. If it happens
                                        ,there is a loss. If it does not happen
                                        there is neither loss nor gain.
                                        Similarly, a person may or may not fall
                                        seriously ill.
                                      </p>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 9/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                marginRight: "3rem",
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <ul
                                style={{
                                  marginLeft: "3rem",
                                  marginRight: "2rem",
                                }}
                              >
                                <li>
                                  ii) &nbsp; Fundamental ( affect lot of people
                                  together , Train accident )and particular
                                  risks which affect individuals ( Theft )
                                </li>{" "}
                                <br></br>
                                <p>
                                  Risk is uncertainty of outcome. If there is a
                                  chance that outcome will be different from
                                  expectation, there is a risk.
                                </p>
                              </ul>{" "}
                              <br></br>
                              <p style={{ marginLeft: "2rem" }}>
                                <b>
                                  Insurance does not protect the asset and it
                                  does not prevent its loss due to peril and
                                  peril cannot be avoided through insurance.
                                  Insurance only tries to reduce the impact of
                                  the financial loss on the owner/ beneficiary
                                  of the asset by providing compensation for the
                                  loss that comes as a result of insured peril
                                  coming in to operation.
                                </b>
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "18px",
                                  paddingLeft: "1rem",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                HAZARDS{" "}
                              </label>{" "}
                              <br></br>
                              <br></br>
                              <div
                                style={{
                                  paddingLeft: "2rem",
                                  paddingRight: "1rem",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <p>
                                  Hazard is a condition that increases the
                                  chances of a loss. It accelerates the peril.
                                  For example, Explosives in factory will result
                                  in a rapid spread of fire destroying
                                  everything in a very short time.
                                </p>
                              </div>
                              <p style={{ marginLeft: "2rem" }}>
                                Hazards could be:
                              </p>
                              <br></br>
                              <ul>
                                <li>
                                  a) &nbsp;{" "}
                                  <mark>
                                    {" "}
                                    <b>Physical Hazard.</b> This refers to the
                                    nature and qualities of the subject matter
                                    of insurance.{" "}
                                  </mark>
                                  Petrol is more hazardous than edible oil for a
                                  Fire Policy.
                                </li>{" "}
                                &nbsp;
                                <li>
                                  Other examples are Smoking which can increase
                                  the likelihood of fire, loose wiring resulting
                                  in short circuiting, ware house with
                                  Inflammable material.
                                </li>{" "}
                                &nbsp;
                                <li>
                                  Probability of damage is greater in these
                                  cases.
                                </li>{" "}
                                &nbsp;
                                <li>
                                  Excessive Smoking will lead to cancer.
                                </li>{" "}
                                &nbsp;
                                <li>
                                  In a factory, Explosive will enhance the risk
                                  of fire.
                                </li>{" "}
                                &nbsp;
                                <li>
                                  Water seeping in cargo and spoiling; Cargo not
                                  packaged in waterproof containers. Physical
                                  hazard is a physical condition that increases
                                  the chance of loss.
                                </li>{" "}
                                &nbsp;
                                <ul>
                                  <li>i. Defective wiring in a building</li>{" "}
                                  &nbsp;
                                  <li>ii. Indulging in water sports</li> &nbsp;
                                  <li>
                                    iii. Leading a sedentary lifestyle
                                  </li>{" "}
                                  &nbsp;
                                </ul>
                              </ul>
                              <br></br>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 10/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                marginLeft: "2rem",
                                marginRight: "3rem",
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <br></br>
                              <ul>
                                <li>
                                  b) &nbsp; <b>Moral hazard </b> refers to
                                  dishonesty or character defects in an
                                  individual that Influence the frequency or
                                  severity of the loss. A dishonest individual
                                  may attempt to commit fraud and make money by
                                  misusing the facility of insurance.
                                </li>{" "}
                                &nbsp;
                                <br></br>
                                <label
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "ErasDemiITC",
                                  }}
                                >
                                  EXAMPLE
                                </label>{" "}
                                &nbsp; <br></br>
                                <div
                                  style={{
                                    fontFamily: "Calibri",
                                    fontSize: "16px",
                                  }}
                                >
                                  <p>
                                    {" "}
                                    <br></br>A classic instance of moral hazard
                                    is purchasing insurance for a factory and
                                    then burning it down to collect the
                                    insurance amount or buying health insurance
                                    after onset of a major ailment.
                                  </p>
                                </div>{" "}
                                &nbsp;
                                <li
                                  style={{
                                    fontFamily: "Calibri",
                                    fontSize: "16px",
                                  }}
                                >
                                  c) &nbsp;<b>Legal hazard </b> is more
                                  prevalent in cases involving a liability to
                                  pay for damages. It arises when certain
                                  features of the legal system or regulatory
                                  environment can increase the incidence or
                                  severity of losses.
                                </li>
                                <br></br>
                                <label
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "ErasDemiITC",
                                  }}
                                >
                                  EXAMPLE
                                </label>{" "}
                                &nbsp; <br></br>
                                <p
                                  style={{
                                    fontFamily: "Calibri",
                                    fontSize: "16px",
                                  }}
                                >
                                  {" "}
                                  <br></br>
                                  The enactment of law governing workmen’s
                                  compensation in the case of Accidents can
                                  raise the amount of liability payable
                                  considerably.
                                </p>
                              </ul>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 11/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div className="Para">
                              <h1
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  color: "#0093dd",
                                }}
                              >
                                Module 2
                              </h1>{" "}
                              <br></br>
                              <h4
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  color: "gray",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                <u>INDIAN GENERAL INSURANCE MARKET.</u>
                              </h4>{" "}
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                {" "}
                                <u>TOPICS COVERED :</u>{" "}
                              </label>{" "}
                              &nbsp;
                              <br></br>
                              <ul
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                <li>
                                  <b>
                                    1){" "}
                                    <u>
                                      Insurance Regulatory and Development
                                      Authority of India{" "}
                                    </u>
                                  </b>
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <b>
                                    2) <u>Insurance companies ( Insurers) </u>
                                  </b>
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <b>
                                    3) <u>Insurance Intermediaries . </u>
                                  </b>
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <b>
                                    4){" "}
                                    <u>
                                      {" "}
                                      Roles and Responsibilities, Regulations &
                                      Code of Conduct for Agents.{" "}
                                    </u>
                                  </b>
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <b>
                                    5) <u> Third Party Administrator </u>
                                  </b>
                                </li>{" "}
                                &nbsp;
                              </ul>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                <b>
                                  {" "}
                                  <u>
                                    INSURANCE REGULATORY AND DEVELOPMENT
                                    AUTHORITY (IRDA){" "}
                                  </u>
                                </b>
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Insurance Regulatory and Development Authority
                                (IRDAI) – is an autonomous, statutory Regulatory
                                body constituted by an Act of Parliament in
                                1999, and reports to Ministry of Finance. It was
                                constituted by the Insurance Regulatory and
                                Development Authority Act, 1999, an{" "}
                                <u
                                  style={{
                                    color: "blue",
                                    fontFamily: "Calibri",
                                  }}
                                >
                                  act of Parliament{" "}
                                </u>
                                passed by the{" "}
                                <u
                                  style={{
                                    color: "blue",
                                    fontFamily: "Calibri",
                                  }}
                                >
                                  government of India.
                                </u>{" "}
                                The agency's headquarters are in{" "}
                                <u
                                  style={{
                                    color: "blue",
                                    fontFamily: "Calibri",
                                  }}
                                >
                                  Hyderabad, Telangana.
                                </u>{" "}
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                COMPOSITION
                              </label>
                              <span>
                                <p>
                                  The Authority is a ten member team consisting
                                  of{" "}
                                </p>
                              </span>
                              <ul
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li> (a) A Chairman; </li> &nbsp;
                                <li> (b) Five whole-time members; </li> &nbsp;
                                <li> (c) Four part-time members, </li>
                              </ul>
                              <p>(all appointed by the Government of India)</p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 12/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  color: "#0093dd",
                                  marginLeft: "3rem",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Important Duties/ Powers and functions of the
                                IRDAI are as under:
                              </label>
                              <br></br>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  paddingLeft: "5rem",
                                  paddingRight: "2rem",
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <br></br>
                                <li>
                                  Issue to the applicant a certificate of
                                  registration, renew, modify, withdraw, suspend
                                  or cancel such registration;
                                </li>
                                <li>
                                  Protection of the interests of the policy
                                  holders in matters concerning assigning of
                                  policy, nomination by policy holders,
                                  insurable interest, settlement of insurance
                                  claim, surrender value of policy and other
                                  terms and conditions of contracts of
                                  insurance;
                                </li>
                                <li>
                                  Specifying requisite qualifications, code of
                                  conduct and practical training for
                                  intermediary or insurance intermediaries and
                                  agents
                                </li>
                                <li>
                                  Specifying the code of conduct for surveyors
                                  and loss assessors;
                                </li>
                                <li>
                                  Promoting efficiency in the conduct of
                                  insurance business;
                                </li>
                                <li>
                                  Promoting and regulating professional
                                  organisations connected with the insurance and
                                  re-insurance business;
                                </li>
                                <li>
                                  Levying fees and other charges for carrying
                                  out the purposes of this Act;
                                </li>
                                <li>
                                  Calling for information from, undertaking
                                  inspection of, conducting enquiries and
                                  investigations including audit of the
                                  insurers, intermediaries, insurance
                                  intermediaries and other organisations
                                  connected with the insurance business
                                </li>
                                <li>
                                  Control and regulation of the rates,
                                  advantages, terms and conditions that may be
                                  offered by insurers in respect of general
                                  insurance business not so controlled and
                                  regulated by the Tariff Advisory Committee
                                  under section 64U of the Insurance Act, 1938
                                  (4 of 1938);
                                </li>
                                <li>
                                  Specifying the form and manner in which books
                                  of account shall be maintained and statement
                                  of accounts shall be rendered by insurers and
                                  other insurance intermediaries;
                                </li>
                                <li>
                                  Regulating investment of funds by insurance
                                  companies;
                                </li>
                                <li>
                                  Regulating maintenance of margin of solvency;
                                </li>
                                <li>
                                  Adjudication of disputes between insurers and
                                  intermediaries or insurance intermediaries;
                                </li>
                                <li>
                                  Supervising the functioning of the Tariff
                                  Advisory Committee;
                                </li>
                                <li>
                                  Specifying the percentage of premium income of
                                  the insurer to finance schemes for promoting
                                  and regulating professional organisations
                                  referred to in clause (f);
                                </li>
                                <li>
                                  Specifying the percentage of life insurance
                                  business and general insurance business to be
                                  undertaken by the insurer in the rural or
                                  social sector; and
                                </li>
                                <li>
                                  Exercising such other powers as may be
                                  prescribed
                                </li>
                              </ol>{" "}
                              <br></br>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 13/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br> <br></br>
                            <div
                              style={{
                                paddingLeft: "2rem",
                                paddingRight: "2rem",
                              }}
                            >
                              <label
                                style={{
                                  paddingLeft: "2rem",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <u> INSURANCE COMPANIES:</u>
                              </label>{" "}
                              &nbsp;
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                An entity which provides insurance is known as
                                an insurer, insurance company, or insurance
                                carrier. A person or entity who buys insurance
                                is known as an insured or policyholder. The
                                insurance transaction involves the insured
                                assuming a guaranteed and known relatively small
                                loss in the form of payment to the insurer in
                                exchange for the insurer's promise to compensate
                                the insured in the event of a covered loss.
                              </p>{" "}
                              &nbsp;
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Basically insurance companies are of two types –
                                one LIFE INSURANCE COMPANIES which deals with
                                human life and other General Insurance Companies
                                deal with everything on the earth except human
                                life. Here we are going to deal with General
                                Insurance companies in detail.
                              </p>{" "}
                              &nbsp;
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Government Insurance Companies – Also known as
                                Public Sector Insurers. They are 100% owned by
                                the Government of India and work under the
                                Department of Bank and Insurance, Ministry of
                                Finance. They have an association known as
                                General Insurers (Public Sector) Association of
                                India (GIPSA) which is headquartered in Delhi.
                              </p>{" "}
                              &nbsp;
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Private Insurance Companies – These can be
                                either 100% Indian Owned Companies or in joint
                                venture with a foreign insurer,{" "}
                                <b>
                                  the foreign partner’s stake (FDI) is
                                  restricted to 49%.
                                </b>
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The main function of insurance company is to
                                provide and manage capital. To manage capital,
                                insurance company have two key roles-
                              </p>
                              <ul
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>a. That of the underwriter and</li>
                                <li>b. That of the claims technician</li>
                              </ul>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                And failure of either of these tasks can
                                adversely affect results of the company.
                                Underwriter is one who accepts the business and
                                claims technician deals with processing of claim
                                in an unforeseen event.
                              </p>{" "}
                              <br></br>
                              <label
                                style={{
                                  paddingLeft: "2rem",
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <u> Classification of insurance companies</u>
                              </label>{" "}
                              &nbsp;
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Apart from basic classification as Public Sector
                                and Private Sector, general insurance companies
                                can also be classified into different groups on
                                the lines of business.
                              </p>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  paddingLeft: "5rem",
                                  paddingRight: "2rem",
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <br></br>
                                <li>
                                  {" "}
                                  Companies conducting all lines of business –
                                  existing both in public and private insurance
                                  companies
                                </li>
                                <li>
                                  Health insurance companies – deals with
                                  insurance related to health ailments
                                </li>
                                <li>
                                  Agriculture Insurance Companies – deals with
                                  various types of agriculture activities
                                </li>
                                <li>
                                  Credit Insurance Companies – lends support to
                                  Indian exporters
                                </li>
                                <li>
                                  Reinsurance Companies - Indian Reinsurance
                                  Company – GIC Re
                                </li>
                                <li>
                                  State Government Insurance Departments – takes
                                  care of government properties
                                </li>
                              </ol>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 14/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                paddingLeft: "2rem",
                                paddingRight: "2rem",
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <p>
                                <br></br>
                                There are about 24 insurance companies in life
                                Insurance business and about 30 in non life
                                business. In non life ,there are FOUR Public
                                Sector companies and in non life ONLY ONE.
                              </p>
                              <p>
                                <ul style={{ fontSize: "16px" }}>
                                  <li>GIC Re is the National Reinsurer.</li>{" "}
                                  &nbsp;
                                  <li>
                                    Foreign Investment cap in Indian Insurance
                                    Companies is 49% with the safeguard of
                                    Indian Ownership and control.
                                  </li>{" "}
                                  &nbsp;
                                  <li>Insurance Councils:</li> &nbsp;
                                  <li>
                                    There is Life Insurance Council and General
                                    Insurance Council. These are the advisory
                                    bodies to advice and provide inputs to IRDAi
                                    and the Government on different issues.
                                  </li>{" "}
                                  &nbsp;
                                  <li>Insurance Ombudsman :</li> &nbsp;
                                  <li>
                                    Insurance Ombudsman is a non judicial
                                    authority created by the central Government
                                    for Redressal of Public Grievances.
                                  </li>
                                </ul>
                              </p>
                              <h2
                                style={{
                                  textAlign: "left",
                                  fontFamily: "ErasDemiITC",
                                  color: "#0093dd",
                                  fontSize: "16px",
                                }}
                              >
                                <u>INSURANCE INTERMEDIARIES:</u>
                              </h2>
                              <br></br>
                              <div
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <ul>
                                  <li>
                                    1) Insurance Agents ( Individual/Corporate
                                    Agents)
                                  </li>{" "}
                                  &nbsp;
                                  <li>2) Insurance Brokers</li>&nbsp;
                                  <li>3) Bancassurance</li>&nbsp;
                                  <li>4) Micro insurance Agents</li>&nbsp;
                                  <li>5) Point of sale person</li>&nbsp;
                                </ul>
                              </div>
                              <p>
                                In some way, Surveyors, TPA( Health claims
                                management ) are also classified under
                                intermediaries.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 15/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br> <br></br>
                            <div
                              style={{
                                paddingLeft: "2rem",
                                paddingRight: "2rem",
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <label
                                style={{
                                  fontSize: "16px",
                                  paddingLeft: "2rem",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                1) INSURANCE AGENTS:{" "}
                              </label>
                              <p
                                style={{
                                  paddingLeft: "3rem",
                                  paddingRight: "3rem",
                                }}
                              >
                                <br></br>
                                Agents are enrolled by the insurance companies
                                as per the provisions laid down by IRDA.
                                Individuals as well as corporate bodies like
                                Banks, companies, firms, cooperative societies
                                etc are allowed to become agents.{" "}
                              </p>
                              <p
                                style={{
                                  paddingLeft: "3rem",
                                  paddingRight: "3rem",
                                }}
                              >
                                <br></br>
                                Agents have to obtain license from the insurance
                                company for selling insurance. There has to be
                                separate license for life and non-life insurance
                                and can work with only one insurer in each
                                class.{" "}
                              </p>
                              <br></br>
                              <label
                                style={{
                                  paddingLeft: "3rem",
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Individual Agent:
                              </label>
                              <p
                                style={{
                                  paddingLeft: "3rem",
                                  paddingRight: "3rem",
                                }}
                              >
                                <br></br>
                                Eligibility Criteria: Should have passed 12th
                                standard if staying in a population of 5000 or
                                more and should have passed 10th standard if
                                staying in other place. Candidate has to undergo
                                50 hour training if he does not have license for
                                life and if already has licence for Life
                                Insurance, training would be for 25 hours.
                                Thereafter, candidate has to pass the
                                examination before he is issued license .
                              </p>
                              <p
                                style={{
                                  paddingLeft: "3rem",
                                  paddingRight: "3rem",
                                }}
                              >
                                <br></br>
                                <b>Corporate License </b> is issued to corporate
                                bodies. Designated individuals are required to
                                undergo prescribed training and pass the
                                examination.
                              </p>
                              <p
                                style={{
                                  paddingLeft: "3rem",
                                  paddingRight: "3rem",
                                }}
                              >
                                Agents are remunerated by way of commission
                                which is regulated by IRDA.
                              </p>
                              <p
                                style={{
                                  paddingLeft: "3rem",
                                  paddingRight: "3rem",
                                }}
                              >
                                <br></br>
                                Reward and recognition system has just been
                                approved by IRDA.
                              </p>
                              <p
                                style={{
                                  paddingLeft: "3rem",
                                  paddingRight: "3rem",
                                }}
                              >
                                <br></br>
                                Agents should sell insurance policies as per the
                                prospective of customer and look after their
                                interest till the claims are settled.
                              </p>{" "}
                              <br></br>
                              <div
                                style={{
                                  paddingLeft: "2rem",
                                  paddingRight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <label
                                  style={{
                                    fontSize: "16px",
                                    paddingLeft: "2rem",
                                    fontFamily: "ErasDemiITC",
                                  }}
                                >
                                  2) INSURANCE BROKER{" "}
                                </label>
                                <p>
                                  <br></br>
                                  could be an individual, a company, a society
                                  or a firm wholly engaged in sourcing insurance
                                  business for various insurance companies. A
                                  broker is not appointed by the insurance
                                  company. They are independent professionals
                                  and have to obtain license from IRDA after
                                  going through a prescribed training course and
                                  passing prescribed examination.
                                </p>
                                <p>
                                  Brokers could be a direct broker or a
                                  reinsurance broker and also a composite
                                  broker.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 16/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                paddingLeft: "2rem",
                                paddingRight: "2rem",
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <br></br>
                              <p>
                                Brokers – IRDA (Insurance Brokers) Regulations
                                2002 came into force and allows three type of
                                brokers –
                                <ol
                                  style={{
                                    listStyleType: "circle",
                                    paddingLeft: "5rem",
                                  }}
                                >
                                  <li>
                                    Direct who acts as an intermediary between
                                    insured and insurer
                                  </li>
                                  <li>
                                    Reinsurance Broker who acts as an
                                    intermediary between the insurance company
                                    and reinsurer
                                  </li>
                                  <li>
                                    Composite Broker – combination of the above
                                    two.
                                  </li>
                                </ol>
                              </p>
                              <p style={{ fontFamily: "ErasDemiITC" }}>
                                <br></br>
                                <b>
                                  BROKERS HAVE BEEN AUTHORISED BY IRDA TO ENROLL
                                  POINT OF SALE PERSONS AS PER STIPULATED
                                  GUIDELINES.{" "}
                                </b>
                              </p>
                              <p>
                                <b
                                  style={{
                                    fontFamily: "ErasDemiITC",
                                    color: "#0093dd",
                                  }}
                                >
                                  3) BANKASSURANCE:
                                </b>{" "}
                                Banks are licensed under a corporate agency. As
                                per the prevailing guidelines, a bank can become
                                corporate agent for any two General Insurance
                                companies and any two Life Insurance Companies
                                and One stand alone Health Insurance company.
                                They share the information relating to their
                                clients with insurance company with whom tie up
                                arrangement is made and act as facilitators too.
                                Banks get insurance commission as per IRDAI
                                guidelines for the business booked under their
                                respective corporate Agency
                              </p>
                              <p>
                                <b
                                  style={{
                                    fontFamily: "ErasDemiITC",
                                    color: "#0093dd",
                                  }}
                                >
                                  {" "}
                                  4) MICRO INSURANCE{" "}
                                </b>{" "}
                                AGENTS are licenced to canvass and market micro
                                insurance products. Objective is to reach out to
                                the public in rural areas to provide insurance
                                protection.
                              </p>
                              <p>
                                <b
                                  style={{
                                    fontFamily: "ErasDemiITC",
                                    color: "#0093dd",
                                  }}
                                >
                                  {" "}
                                  5) POS Person :
                                </b>{" "}
                                This has been dealt with in details in Module
                                number -1
                              </p>{" "}
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  color: "#0093dd",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <u>
                                  INSURANCE AGENT’S ROLE AND RESPONSIBILITIES.
                                </u>
                              </label>
                              <p>
                                <br></br>
                                Role of an agent begins at the stage of sale and
                                continues through the duration of the contract
                                and includes the following steps.
                              </p>
                              <p style={{ color: "#0093dd" }}>
                                <b>1. THE POINT OF SALE - BEST ADVICE </b>
                              </p>
                              <p>
                                The first point for service is the point of
                                sale. One of the critical issues involved in
                                purchase of non-life Insurance is to determine
                                the{" "}
                                <b>
                                  amount of coverage [Sum Insured to be bought].{" "}
                                </b>
                              </p>
                              <p>
                                Here it is important to keep a basic percept in
                                mind - Do not recommend insuring where the risk
                                can be managed otherwise. The insured needs to
                                make sure that the expected loss involved is
                                greater than the cost of insurance. If the
                                premium payments are high compared to the loss
                                involved, it may be advisable to just bear the
                                risk.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 17/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                paddingLeft: "3rem",
                                paddingRight: "3rem",
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <br></br>
                              <p>
                                On the other hand, if the occurrence of any
                                contingency would lead to financial burden, it
                                is wise to insure against such contingency.
                              </p>
                              <p>
                                Whether insurance is needed or not, depends on
                                the rcumstances. If the probability of loss or
                                damage to an asset due to a peril is negligible,
                                one may retain the risk rather than insure it.
                                Similarly if an item has insignificant value,
                                one may not insure it.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                EXAMPLE{" "}
                              </label>
                              <p>
                                <br></br>
                                To a home owner living in a flood prone area,
                                purchasing cover against floods would prove to
                                be helpful. <br></br>
                                On the other hand, if the home owner owns a home
                                at a place where the risk of floods is
                                negligible it may not be necessary to obtain
                                cover.
                              </p>
                              <p>
                                <br></br>
                                <b>
                                  {" "}
                                  In India, motor insurance against third party
                                  is compulsory under the law.
                                </b>{" "}
                                In that case, the debate about whether one needs
                                insurance or not is irrelevant.
                              </p>
                              <p>
                                <br></br>
                                One must purchase third party insurance if he
                                owns a vehicle because it is mandatory if one
                                wants to drive on a public road. At the same
                                time it would be prudent to cover the
                                possibility of loss of own damage to the car
                                which is not mandatory.
                              </p>
                              <p>
                                <br></br>
                                In case a portion of the possible loss can be
                                borne by oneself, it would be economical for the
                                insured to opt for a <b>deductible.</b> A
                                corporate customer may have varied needs, right
                                from the coverage of factory, people, cars,
                                liability exposures etc. It needs the right
                                advice for the coverage and policies to be taken
                              </p>
                              <p>
                                <br></br>
                                In other words the role of an insurance agent is
                                more than that of mere sales person. He{" "}
                                <b>
                                  needs to be a risk assessor, underwriter, risk
                                  management Counsellor, designer of customised
                                  solutions and a relationship builder who
                                  thrives on building trust and long-term
                                  relationships,
                                </b>{" "}
                                all rolled into one.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                2. THE PROPOSAL STAGE
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                The agent has to support the customer in filling
                                out the proposal for insurance. The insured is
                                required to take responsibility for the
                                statements made therein.The salient aspects of a
                                proposal form have been discussed in chapter 5.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 18/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                paddingLeft: "3rem",
                                paddingRight: "3rem",
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                It is very important that the agent should
                                explain and clarify to proposer the details to
                                be filled as answer to each of questions in the
                                proposal form. In the event of a claim, a
                                failure to give proper and complete information
                                can jeopardise the customer’s claim.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Sometimes there may be additional information
                                that may be required to complete the policy. In
                                such cases the company may inform the customer
                                directly or through the agent / advisor. In
                                either case, it becomes necessary to help the
                                customer complete all the required formalities
                                and even explain to him or her why these are
                                necessary.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                3. ACCEPTANCE STAGE
                              </label>
                              <br></br> <br></br>
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                a) DELIVERY OF THE POLICY DOCUMENT
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Delivery of the policy is another major
                                opportunity that an agent gets to make contact
                                with the customer. If company rules permit a
                                policy document being delivered in person, it
                                may be a good idea to collect it and present the
                                document to the customer.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                If the policy is being sent directly by mail,
                                one must ontact the customer. This is an
                                opportunity to visit the customer and explain
                                anything that is unclear in the document
                                received. This is also an occasion to clarify
                                various kinds of policy provisions, and the
                                policy holder’s rights and privileges that the
                                customer can avail of. This act demonstrates a
                                willingness to provide a level of service beyond
                                the sale.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                This meeting is also an occasion to pledge the
                                agent’s commitment to serving the customer and
                                communicating full support.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The next logical step would be to ask for the
                                names and particulars of other individuals he
                                knows who can possibly benefit from the agent’s
                                services. If the client can himself contact
                                these people and introduce the agent to them, it
                                would mean a great breakthrough in business.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                b) POLICY RENEWAL
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Non-life insurance policies have to be renewed
                                each year( with few exceptions where long term
                                policies are issued or Project Policies ) and
                                the customer has a choice at the time of each
                                renewal, to continue insuring with the same
                                company or switch to another company. This is a
                                critical point where the good will and trust
                                created by the agent and the company gets
                                tested.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The agent needs to be in touch with the customer
                                well before the renewal due date to remind the
                                latter about renewal so that he can make
                                provision for the same The relationship gets
                                strengthened by keeping in touch with the client
                                from time to time, by greeting him on some
                                occasion like a festival or a family event.
                                Similarly when there is a moment of difficulty
                                or sorrow by offering assistance.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 19/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br> <br></br>
                            <div
                              style={{
                                paddingLeft: "3rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                4. THE CLAIM STAGE
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                The agent has a crucial role to play at the time
                                of claim settlement. It is her task to ensure
                                that the incident giving rise to the claim is
                                immediately informed to the insurer and that the
                                customer carefully follows all the formalities
                                and assists in all the investigations that may
                                need to be done to assess the loss.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <u>
                                  REGULATIONS APPLICABLE TO INSURANCE AGENTS
                                </u>
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                As per the Insurance Act 1938, Section 42, one
                                must Have a licence to work as an insurance
                                agent. IRDA deals with issuance of licences and
                                other matters relation to agency recruitment.
                                These regulations must be complied with at all
                                stages in the process.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b> Important Provisions are: </b>
                              </p>
                              <ul
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; An agent can be an individual agent or
                                  corporate agent. Individual agent represent an
                                  insurance company, while a corporate agent is
                                  other than an individual representing an
                                  insurance company
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; An agent can be issued licence for
                                  doing “Life” or “General” or “Health” or all.
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; Agents have to undergo training as
                                  stipulated by IRDA imparted by Insurance
                                  company
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; Training requirements for fresh agents
                                  effective from 1st October 2016 is 50 hours
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; At the time of renewal of licence,
                                  agent has to undergo 25 hours training
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; Passing marks for agent is 35%
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; Examination body for agent is Insurance
                                  Institute of India
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; The prospective agent must fulfil the
                                  following requirements to obtain licence
                                </li>
                              </ul>
                              <ol
                                style={{
                                  listStyleType: "circle",
                                  paddingLeft: "7rem",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  Should produce Agency application in Form I A,
                                </li>{" "}
                                &nbsp;
                                <li>PAN Details</li> &nbsp;
                                <li>Undergo training and pass exam</li> &nbsp;
                                <li>Does not have any disqualification</li>
                                &nbsp;
                              </ol>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 20/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                paddingLeft: "3rem",
                                paddingRight: "3rem",
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <br></br>
                              <ul>
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; Designated official must process the
                                  agency application and grant appointment as
                                  insurance agent by an appointment letter
                                  within 15 days of receipt of all documents
                                  from the applicant.
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; The agency appointment letter covering
                                  the terms and conditions of appointment and
                                  code of conduct shall be dispatched to the
                                  agent within 7 days
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; The Appelate Officer shall consider the
                                  application and communicate the final decision
                                  in writing within 15 days of receipt of review
                                  application.
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; The conditions for disqualification
                                  shall be as stipulated under Section 42(3) of
                                  the Act.
                                </li>
                              </ul>
                              <br></br>
                              <label
                                style={{
                                  color: "#2196F3",
                                  textAlign: "left",
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <u>Code of Conduct for Agents </u>
                              </label>
                              <br></br> <br></br>
                              <label
                                style={{ color: "#2196F3", fontSize: "16px" }}
                              >
                                <br></br>
                                Every Individual insurance agent shall
                              </label>
                              <ol style={{ lineHeight: "2rem" }}>
                                <br></br>
                                <li>
                                  Identify herself/himself and the insurance
                                  company of whom she/he is an insurance agent
                                </li>
                                <li>
                                  Disclose her/his licence to the prospect on
                                  demand
                                </li>
                                <li>
                                  Explain carefully the requisite information in
                                  respect of insurance products offered for sale
                                  by her/his insurer and take into account the
                                  needs of the prospect while recommending a
                                  specific insurance plan
                                </li>
                                <li>
                                  Disclose the scales of commission in respect
                                  of the insurance product offered for sale, if
                                  asked by the prospect
                                </li>
                                <li>
                                  Indicate the premium to be charged by the
                                  insurer for the insurance productoffered for
                                  sale
                                </li>
                                <li>
                                  Explain to the prospect the nature of
                                  information required in the proposal form by
                                  the insuerer, and also the importance of
                                  disclosure of material information in the
                                  purchase of an insurance contract
                                </li>
                                <li>
                                  Bring to the notice of the insurer any adverse
                                  habits or income inconsistency of the
                                  prospect, in the form of a report (called
                                  “Insurancew Agent’s confidential report”)
                                  along with evbery proposal submitted to the
                                  insurer, and any material fact that may
                                  adversely affect the underwriting decision of
                                  the insurer as regards acceptance of the
                                  proposal by making all reasonable enquiries
                                  about the prospect
                                </li>
                                <li>
                                  Inform promptly the prospect about the
                                  acceptance or rejection of the proposal by the
                                  insurer
                                </li>
                                <li>
                                  Obtain the requisite documents at the time of
                                  filing the proposal form with the insurer and
                                  other documents subsequently asked for by the
                                  insurer for completion of the proposal
                                </li>
                                <li>
                                  Render necessary assistance to the policy
                                  holders or claimants or beneficiaires in
                                  complying with the requirements for settlement
                                  of claims by the insurer
                                </li>
                                <li>
                                  Advise every individual policyholder to effect
                                  nomination or assignment or change of address
                                  or exercise of options, as the case may be,
                                  and offer necessary assistance in this behalf
                                  wherever necessary.
                                </li>
                              </ol>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 21/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                paddingLeft: "3rem",
                                paddingRight: "3rem",
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <br></br>
                              <label
                                style={{ fontSize: "16px", color: "#2196F3" }}
                              >
                                No insurance agent shall:{" "}
                              </label>

                              <ol style={{ lineHeight: "2rem" }}>
                                <br></br>
                                <li>
                                  Solicit or procure insurance business without
                                  holding a valid licence
                                </li>
                                <li>
                                  Induce the prospect to omit any material
                                  information in the proposal form
                                </li>
                                <li>
                                  Induce the prospect to submit wrong
                                  information in the proposal form or documents
                                  submitted to the insurer for acceptance of
                                  proposal
                                </li>
                                <li>
                                  Behave in a discourteous manner with the
                                  prospect
                                </li>
                                <li>
                                  Interfere with any proposal introduced by any
                                  other insurance agent
                                </li>
                                <li>
                                  Offer difference rates, advantages, terms and
                                  conditions other than those offered by her/his
                                  insurer
                                </li>
                                <li>
                                  Demand or receive a share of proceeds from the
                                  beneficiary under an insurance contract
                                </li>
                                <li>
                                  Force a policyholder to terminate the existing
                                  policy and to effect a new proposal from him
                                  within three years from the date of such
                                  termination’
                                </li>
                                <li>
                                  Have, in case of corporate agent, a portfolio
                                  of insurance business under whichthe premium
                                  is in excess of fifty percent of total premium
                                  procured, in any year, from one person (who is
                                  not an individual) or one organization or one
                                  group of organization.
                                </li>
                                <li>
                                  Apply for fresh licence to act as an insurance
                                  agent if her/his licence was earlier cancelled
                                  by the designated person and a period of five
                                  years has not elapsed from the date of such
                                  cancellation.
                                </li>
                                <li>
                                  Become or remain a director of any insurance
                                  company.
                                </li>
                              </ol>
                              <br></br>
                              <h6
                                style={{ textAlign: "center", color: "gray" }}
                              >
                                SUSPENSION OF APPOINTMENT OF AN AGENT:
                              </h6>
                              <p>
                                <br></br>
                                The appointment of an agent may be cancelled or
                                suspended after due notice and after giving
                                him/her a reasonable opportunity of being
                                heardif he/she:-{" "}
                              </p>
                              <ul style={{ lineHeight: "2rem" }}>
                                <li>
                                  a. &nbsp;Violates the provisions of the
                                  Insurance Act, 1938 (4 of 1938), Insurance
                                  Regulatory and Development Authority Act, 1999
                                  (41 of 1999) or rules or regulations, made
                                  there under as amended from time to time;
                                </li>
                                <li>
                                  b. &nbsp; Attracts any of the
                                  disqualifications mentioned above.
                                </li>
                                <li>
                                  c. &nbsp;Fails to comply with the code of
                                  conduct stipulated in Regulation 8 and
                                  directions issued by the Authority from time
                                  to time
                                </li>
                                <li>d. &nbsp;Violates terms of appointment.</li>
                              </ul>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 22/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                paddingLeft: "3rem",
                                paddingRight: "3rem",
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <br></br>
                              <ul style={{ lineHeight: "2rem" }}>
                                <li>
                                  e. &nbsp;Fails to furnish any information
                                  relating to his/her activities as an agent as
                                  required by the Insurer or the Authority;
                                </li>
                                <li>
                                  f.&nbsp;Fails to comply with the directions
                                  issued by the Authority;
                                </li>
                                <li>
                                  g. &nbsp;Furnishes wrong or false information;
                                  or conceals or fails to disclose material
                                  facts in the application submitted for
                                  appointment of Insurance Agent or during the
                                  period of its validity.
                                </li>
                                <li>
                                  h. &nbsp;Does not submit periodical returns as
                                  required by the Insurer/Authority
                                </li>
                                <li>
                                  i.&nbsp;Does not co-operate with any
                                  inspection or enquiry conducted by the
                                  Authority;
                                </li>
                                <li>
                                  j.&nbsp;Fails to resolve the complaints of the
                                  policyholders or fails to give a satisfactory
                                  reply to the Authority in this behalf;
                                </li>
                                <li>
                                  k. &nbsp;Either directly or indirectly
                                  involves in embezzlement of premiums / cash
                                  collected from policyholders/prospects on
                                  behalf of insurer. However this proviso does
                                  not permit an agent to collect cash/premium
                                  without specific authorisation by the insurer.
                                </li>
                              </ul>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <b>
                                  <u>THIRD PARTY ADMINISTRATORS</u>
                                </b>
                              </label>
                              <p>
                                <br></br>
                                Third Party Administrators referred as TPAs have
                                come into existence since 2000. Insurance
                                companies appoint TPA to act on their behalf and
                                pay a remuneration for the services rendered.
                                The main functions of TPAs are
                              </p>
                              <br></br>
                              <ul style={{ lineHeight: "2rem" }}>
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; Interact with hospitals on behlf of
                                  insurance companies and empanel them to offer
                                  medical services
                                </li>
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; Issuance of id proof to the insured
                                  along with list of empaneled hospitals
                                </li>
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; Processing and settlement of claim
                                  lodged by the insured.
                                </li>{" "}
                                &nbsp;
                                <p>
                                  <b>For this purpose, TPA has to </b>
                                </p>
                                &nbsp;
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; Obtain licence from IRDA
                                </li>
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; Provide share capital of minimum Rs.1
                                  crore
                                </li>
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; At least one of the directors must be a
                                  qualified Medical professional
                                </li>
                                <li>
                                  <i className="bi bi-chevron-double-right"></i>{" "}
                                  &nbsp; One of the officer has to undergo
                                  prescribed training and pass prescribed
                                  examination.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 23/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              className="Para"
                              style={{
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <h1
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  color: "#0093dd",
                                }}
                              >
                                Module 3
                              </h1>{" "}
                              <br></br>
                              <h4
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  color: "gray",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                PRINCIPLES AND PRACTICES OF INSURANCE{" "}
                              </h4>{" "}
                              <br></br>
                              <ul
                                style={{
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  1) Elements of a valid contract (Offer and
                                  Acceptance, Consideration-Premium)
                                </li>
                                <li>2) Principles of Insurance.</li>
                                <li>3) Proposal Form , Sales Literature.</li>
                                <li>
                                  4) Payment of premium and Section 64 VB of the
                                  Insurance Act.
                                </li>
                                <li>
                                  5) Cover note, Certificate of Ins, Insurance
                                  Policy/Endorsement/ Warranty.
                                </li>
                                <li>
                                  6) Prohibition of Rebate (Section 41 of
                                  Insurance Act)
                                </li>
                                <li>7) Renewal Notice.</li>
                                <li>
                                  8) Insurance Claims—Intimation, Claim Form,
                                  Appointment of Surveyor,Investigation and
                                  Assessment of loss, Supporting Documents,
                                  Assessment and Settlement of loss, Category of
                                  claims, discharge voucher,Post settlement
                                  issues including Grievances, General
                                  Instructions.
                                </li>
                              </ul>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <b> 1) ELEMENTS OF A VALID CONTRACT. </b>
                              </label>
                              <br></br>
                              <p>The elements of a valid contract are:</p>
                              <br></br>
                              <ul
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li style={{ fontWeight: "bold" }}>
                                  a) OFFER AND ACCEPTANCE:
                                </li>
                                <p>
                                  Usually,{" "}
                                  <b>
                                    the offer is made by the proposer, and
                                    acceptance is made by the insurer.{" "}
                                  </b>
                                  Ultimately, Insurance Policy the evidence of
                                  the contract.
                                </p>
                                <li style={{ fontWeight: "bold" }}>
                                  b) CONSIDERATION (PREMIUM)
                                </li>
                                <p>
                                  This means that the contract must contain some
                                  mutual benefit for the parties.{" "}
                                  <b>
                                    The premium is the consideration from the
                                    insured, and the promise to indemnify, is
                                    the consideration from the insurers.{" "}
                                  </b>
                                </p>
                                <li style={{ fontWeight: "bold" }}>
                                  c) AGREEMENT BETWEEN THE PARTIES
                                </li>
                                <p>
                                  Both the parties should agree to the same
                                  thing in the same sense. <b> FREE CONSENT </b>{" "}
                                  There should be free consent while entering
                                  into a contract Consent is said to be free
                                  when it is not caused by
                                </p>
                                <li>
                                  <i className="bi bi-check"></i> Coercion{" "}
                                  <br></br>
                                  <i className="bi bi-check"></i> Undue
                                  influence <br></br>
                                  <i className="bi bi-check"></i> Fraud{" "}
                                  <br></br>
                                  <i className="bi bi-check"></i>{" "}
                                  Misrepresentation <br></br>
                                  <i className="bi bi-check"></i> Mistake{" "}
                                  <br></br>
                                </li>
                                When consent to an agreement is caused by
                                coercion, fraud or Misrepresentation, the
                                agreement is voidable.
                              </ul>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 24/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br> <br></br>
                            <div
                              style={{
                                paddingLeft: "3rem",
                                paddingRight: "3rem",
                                fontFamily: "Calibri",
                              }}
                            >
                              <br></br>
                              <ul
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li style={{ fontWeight: "bold" }}>
                                  d) CAPACITY OF THE PARTIES
                                </li>
                                <p>
                                  Both the parties to the contract must be
                                  legally competent to enter into the contract.
                                  The policy holder must have attained the age
                                  of majority at the time of signing the
                                  proposal and should be of sound mind and not
                                  disqualified under law. For example, minors
                                  cannot enter into insurance contracts.
                                </p>
                                <li style={{ fontWeight: "bold" }}>
                                  e) LEGALITY
                                </li>
                                <p>
                                  The object of the contract must be legal, for
                                  example, no insurance can be had for smuggled
                                  goods. Every agreement of which the object or
                                  consideration is unlawful is void. The object
                                  of an insurance contract is a lawful object.
                                </p>
                              </ul>{" "}
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                IMPORTANT
                              </label>
                              <ol
                                style={{
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                <li>
                                  <b>COERCION</b> - Involves pressure applied
                                  through criminal means.
                                </li>
                                <li>
                                  <b>UNDUE INFLUENCE </b>-When a personwho is
                                  able to dominate the will of another, uses her
                                  position to obtain an undue advantage over the
                                  other.
                                </li>
                                <li>
                                  <b>FRAUD </b>- When a person induces another
                                  to act on a false belief that is caused by a
                                  representation he or she does not believe to
                                  be true. It can arise either from deliberate
                                  concealment of factsor through misrepresenting
                                  them.
                                </li>
                                <li>
                                  <b>MISTAKE</b> - Error in One’s knowledge or
                                  belief or interpretation of a thing or event.
                                  This can lead to an error in understanding and
                                  agreement about the subject matter of
                                  contract.
                                </li>
                              </ol>
                              <label style={{ fontSize: "16px" }}>
                                <b>
                                  {" "}
                                  2) THE FUNDAMENTAL PRINCIPLES OF INSURANCE.{" "}
                                </b>
                              </label>{" "}
                              <br></br>
                              <br></br>
                              <label style={{ fontSize: "16px" }}>
                                <b> SPECIAL FEATURES OF INSURANCE CONTRACT. </b>
                              </label>
                              <br></br>
                              <p>
                                <b> Principal of insurance- at a glance </b>
                              </p>
                              <div
                                style={{
                                  border: "2px solid black",
                                  width: "80%",
                                  fontWeight: "bold",
                                  paddingLeft: "2rem",
                                }}
                              >
                                1. Utmost good faith <br></br>
                                2. Indemnity <br></br>
                                3. Insurable interest <br></br>
                                4. Contribution <br></br>
                                5. Suborgation <br></br>
                                6. Proximate cause <br></br>
                                7. Mitigation of loss
                              </div>
                              <br></br>
                              <label
                                style={{
                                  color: "#0093dd",
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                (1) PRINCIPLE OF UTMOST GOOD FAITH:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>A contract of insurance is based on the
                                principle of utmost good faith to be observed by
                                both the parties – the insured and the insurance
                                company – towards each other. If one party
                                conceals any material information from the other
                                party, which may influence the other party’s
                                decision to enter into the contact of insurance;
                                the other party can avoid the contract. The
                                principle of utmost good faith is equally
                                applicable to both the parties. However, the
                                onus (i.e. burden) of making a full and fair
                                disclosure of all material facts usually rests
                                primarily upon the insured; because the insured
                                is supposed to have an intimate knowledge of the
                                subject-matter of insurance. <br></br>
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 25/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                paddingLeft: "3rem",
                                paddingRight: "4rem",
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <p>
                                <br></br>
                                The duty of disclosing material facts is not a
                                continuing obligation i .e. the insured is under
                                no obligation to disclosure any material fact
                                which comes to his knowledge after the
                                conclusion of the contract of insurance.
                                <br></br>
                                <b>
                                  Material fact is the one that would affect the
                                  judgement of an insurer in deciding whether to
                                  accept the risk and if so on what terms,
                                  conditions and rate.
                                </b>
                              </p>
                              <br></br>
                              <label
                                style={{
                                  color: "#0093dd",
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                (2) THE PRINCIPLE OF INDEMNITY:{" "}
                              </label>

                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                The objective of the Principle of indemnity is
                                to PLACE THE INSURED AFTER LOSS IN THE SAME
                                FINANCIAL POSITION AS FOR AS POSSIBLE AS HE WAS
                                BEFORE THE LOSS.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Suppose A insured his house against fire with an
                                insurance company for Rs. 1, 00,000. The loss
                                caused to the house due to fire is Rs. 80, 000
                                only. A can recovery only Rs.80, 000 from the
                                insurance company.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Except life insurance , Personal Accident
                                Insurance , all other contracts of insurance are
                                contacts of indemnity; which means that in the
                                event of the loss caused to the subject matter
                                of insurance, the insured can recovery only the
                                actual amount of loss-subject to a maximum of
                                sum assured.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                THE OBJECTIVES OF THE PRINCIPLE OF INDEMNITY
                                ARE:
                              </label>

                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                (1) To put the insured in the same position in
                                which he would have been; had there been no
                                loss.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                (2) Not to allow the insured to make any profit,
                                out of the transaction of insurance.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                In case of life insurance, however, it is not
                                possible to estimate the loss caused due to the
                                death of the insured; as life is invaluable.
                                Hence, the full amount of insurance policy can
                                be claimed from the insurance company.
                              </p>
                              <br></br>

                              <label
                                style={{
                                  color: "#0093dd",
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                (3) PRINCIPLE OF INSURABLE INTEREST:{" "}
                              </label>

                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                The principle of insurable interest is the
                                foundation of a contract of insurance. In the
                                absence of insurable interest, the contract of
                                insurance is a mere gamble and not enforceable
                                in a court of law.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                INSURABLE INTEREST MAY BE DEFINED AS FOLLOWS:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                It is an essential part of every insurance
                                contract and is considered as legal Pre
                                requisite for insurance .A person is said to
                                have insurable interest in the subject matter of
                                insurance; when with respect to the subject
                                matter he is so situated that he will benefit
                                from its existence and lose from its
                                destruction.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 26/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                                fontFamily: "Calibri",
                                fontSize: "16px",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                Insurable interest must exist in life, fire and
                                marine insurances, as per the following rules:{" "}
                              </label>
                              <br></br>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                (1) In case of life insurance; insurable
                                interest must exist at the time of making the
                                contract.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                (2) In case of fire insurance; insurable
                                interest must exist both at the time of making
                                the contract and also at the time of loss.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                (3) In case of marine insurance; insurable
                                interest must exist, at the time of loss.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                GAMBLING AND INSURANCE{" "}
                              </label>
                              <br></br>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Consider a game of cards, where one either loses
                                or wins. The loss or gain happens only because
                                the person enters the bet. The person who plays
                                the game has no further interest or relationship
                                with the game other than that he might win the
                                game. Betting or, wagering is not legally
                                enforceable in a court of law and thus any
                                contract in pursuance of it will be held to be
                                illegal. In case someone pledges his house if he
                                happens to lose a game of cards, the other party
                                cannot approach the court to ensure its
                                fulfilment.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  color: "#0093dd",
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                (4) PRINCIPLE OF CONTRIBUTION:{" "}
                              </label>

                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                The principle of contribution applies in cases
                                of double-insurance. In case of double
                                insurance, each insurer will contribute to the
                                total payment in proportion to the amount
                                assured by each. In case, one insurer has paid
                                the full amount of loss; he can claim
                                proportionate contribution from other insurers.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Suppose, A insures his house against fire with
                                two insurance companies, X and Y, for Rs.40, 000
                                and Rs.80, 000 respectively. If the houses
                                catches fire and the actual loss amount to
                                Rs.48, 000, then
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                X will pay Rs. 16,000 to A
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                And Y will pay Rs.32, 000 to A
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                i.e. the loss of Rs.48, 000 is divided between X
                                and Y in the ratio of 40, 000: 80,000 or 1:2.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                If X pays the whole loss of Rs.48, 000 to A; it
                                can recover Rs.32, 000 from Y. And if Y pays
                                Rs.48, 000 to A; it can recover Rs. 16,000 from
                                X.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The principle of contribution does not apply to
                                life insurance; where each insurer will pay the
                                full amount of policy to the insured; because
                                life insurance is a sort of investment and life
                                insurance contract is not a contract of
                                indemnity.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 27/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  color: "#0093dd",
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                (5) PRINCIPLE OF SUBROGATION:{" "}
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                According to the principle of subrogation, after
                                the insurance company has compensated for the
                                loss caused to the insured; the insurance
                                company steps into the shoes of the insured i.e.
                                the insurance company acquires all the rights of
                                the insured, in respect of the damaged property.
                              </p>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Suppose A insures his house for Rs.2, 00,000
                                against fire. The house is fully damaged by fire
                                and the insurance company pays Rs.2, 00,000 to
                                A. Later on, the damaged house is sold for
                                Rs.25, 000. The insurance company is entitled to
                                receive this sum of Rs.25, 000. Suppose further,
                                it is found that someone tried to put the house
                                on fire.
                              </p>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The insurance company can take action against
                                that person also; because the insurance company
                                acquires all the rights and remedies available
                                to the insured i.e. Mr. A.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                Implications of the principle of subrogation
                                are:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                (1) The insurance company gets the rights of the
                                insured, only after compensating for the loss
                                caused to the insured. <br></br>
                                (2) This principle does not apply to life
                                insurance.
                              </p>{" "}
                              <br></br>
                              <label
                                style={{
                                  color: "#0093dd",
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                (6) PRINCIPLE OF CAUSE PROXIMA(i.e. THE
                                PROXIMATE CAUSE):{" "}
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                According to this principle, we find out which
                                is the proximate cause or the nearest cause of
                                loss to the insured property. If the nearest
                                cause of loss is a factor which is insured
                                against; then only the insurance company is
                                liable to compensate for the loss, otherwise
                                not. This principle is significant in cases when
                                the loss is caused by a series of events.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Suppose X has taken a marine insurance policy
                                against loss or damage to goods caused by sea
                                water. During the voyage rats made a hole in the
                                bottom of the ship, through which sea water
                                seeped into the ship and caused damaged to the
                                goods.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Here, the insurance company is liable to
                                compensate for loss caused to goods; because the
                                proximate cause of loss is sea-water against
                                which insurance is affected. Making of a hole in
                                the bottom of the ship by rats is only the
                                remote cause of loss.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 28/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  color: "#0093dd",
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                (7) PRINCIPLE OF MITIGATION OF LOSS:{" "}
                              </label>

                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                (Mitigation means making something less
                                harmful). According to the principle of
                                mitigation of loss, it is the duty of the
                                insured to take all possible steps to minimize
                                the loss caused to the property covered by the
                                insurance policy. He should behave as a prudent
                                person and must not become careless after taking
                                the insurance policy.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Suppose a house is insured against fire and a
                                fire breaks out. The owner must immediately
                                inform the Fire Brigade department and do each
                                and every thing to extinguish fire; as if the
                                house were not insured. That is, he must make
                                all efforts to minimize the loss caused by fire.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                3) PROPOSAL FORMS
                              </label>

                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                The insurance documentation is provided for the
                                purpose of bringing understanding and clarity
                                between insured and insurer. There are certain
                                documents that are conventionally used in the
                                insurance business. The insurance agent, being
                                the person closest to the customer, has to face
                                the customer and clarify all doubts about the
                                documents involved and help her in filling them
                                up. The insurance company comes to know the
                                customer and her insurance needs only from the
                                documents that are submitted by customer. They
                                help the insurer to understand the risk better.
                              </p>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Agents should understand the purpose of each
                                document involved and the Importance and
                                relevance of information contained in the
                                documents used in insurance.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The first stage of documentation is essentially
                                the proposal forms through which the insured
                                informs:
                              </p>
                              <p>
                                <i className="bi bi-check"></i> Who He/she is,{" "}
                                <br></br>
                                <i className="bi bi-check"></i> What kind of
                                insurance He/ she needs, <br></br>
                                <i className="bi bi-check"></i> Details of what
                                He/she wants to insure, and <br></br>
                                <i className="bi bi-check"></i> For what period
                                of time <br></br>
                                Details of Previous Insurance and claims.{" "}
                                <br></br>{" "}
                              </p>

                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Details would mean the monetary value of and all
                                material facts connected with the subject matter
                                of insurance.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                A) RISK ASSESSMENT BY INSURER
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                <b>
                                  Proposal form is to be filled in by the
                                  proposer{" "}
                                </b>{" "}
                                for insurance, for furnishing all material
                                information required by the insurer in respect
                                of a risk, in order to enable the insurer to
                                decide: <br></br>
                                <i className="bi bi-check"></i> Whether to
                                accept or decline and <br></br>
                                <i className="bi bi-check"></i> In the event of
                                acceptance of the risk, to determine the
                                rates,terms and conditions of a cover to be
                                granted <br></br>
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 29/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Proposal form contains information which are
                                useful for the insurance Companyto accept the
                                risk offered for insurance. The principle of
                                utmost good faith and the duty of disclosure of
                                material information begin with the proposal
                                form for insurance.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The duty of disclosure of material information
                                arises prior to the inception of the policy, and
                                continues even after the conclusion of the
                                contract. (This principle has been discussed in
                                Chapter 2 in detail.)
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Proposal forms are printed by insurers usually
                                with the insurance company’s name, logo, address
                                and the class / type of insurance / product that
                                it is used for. It is customary for insurance
                                companies to add a printed note in the proposal
                                form, though there is no standard format or
                                practice in this regard.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                IMPORTANT
                              </label>

                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                <b style={{ fontSize: "16px" }}>
                                  {" "}
                                  MATERIAL FACTS:{" "}
                                </b>{" "}
                                <br></br>
                                These are important, essential and relevant
                                information for underwriting of the risk to be
                                covered by the insurer. In other words, these
                                are facts connected with the subject matter of
                                insurance which may influence an insurer’s
                                decision in the following:
                              </p>
                              <ul
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  i. Accepting or not accepting a risk for
                                  insurance,
                                </li>
                                <li>
                                  ii. Fixing the amount of premium to be
                                  charged, and
                                </li>
                                <li>
                                  iii. Including special provisions in the
                                  contract about the conditions under which the
                                  risk would be covered and how a loss would be
                                  payable.
                                </li>
                              </ul>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                DECLARATION IN THE PROPOSAL FORM:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Insurance companies usually add a declaration at
                                the end of the proposal form to be signed by the
                                insurer. This ensures that the insured has
                                filled up the form accurately and understood the
                                facts given therein, so that at the time of a
                                claim there is no scope for disagreements, on
                                account of misrepresentation of facts. This
                                serves the main principle of utmost good faith
                                on the part of the insured.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Proposal Form includes a declaration where the
                                insured declares that THE ANSWESRS TO ALL
                                QUESTIONS IN THE POROSAL FORM ARE TRUE AND
                                ACCURATE and he agrees that the proposal form
                                shall be the basis of the insurance contract and
                                any wrong answer will give the right to the
                                insurer to avoid the contract.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 30/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br> <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                NATURE OF QUESTIONS IN A PROPOSAL FORM
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                The number and nature of questions in a proposal
                                form vary according to the class of insurance
                                concerned.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                i) For motor insurance, questions are asked
                                about the vehicle, its perations, make and
                                carrying capacity, how it is managed by the
                                owner and related insurance history. <br></br>
                                ii) In personal lines like health, personal
                                accident and travel insurance, proposal forms
                                are designed to get information about the
                                proposer’s health, way of life and habits,
                                pre-existing health conditions, medical history,
                                hereditary traits, past insurance experience
                                etc. <br></br>
                                iii) In other miscellaneous insurances, proposal
                                forms are compulsory and they incorporate a
                                declaration which extends the common law duty of
                                good faith.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                ELEMENTS OF A PROPOSAL
                              </label>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b> i.&nbsp; Proposer‘s name in full </b>{" "}
                                <br></br>
                                <b>
                                  {" "}
                                  ii. &nbsp; Proposer’s address and contact
                                  details
                                </b>{" "}
                                <br></br>
                                <b>
                                  {" "}
                                  iii. &nbsp;Proposer‘s profession, occupation
                                  or business
                                </b>{" "}
                                <br></br>
                                <b>
                                  iv.&nbsp;Details and identity of the subject
                                  matter of insurance{" "}
                                </b>{" "}
                                <br></br>
                                <p
                                  style={{
                                    fontFamily: "Calibri",
                                    fontSize: "16px",
                                  }}
                                >
                                  The proposer is required to clearly state the
                                  subject matter that is proposed for insurance.{" "}
                                </p>
                                <b>v.&nbsp; Sum insured </b> indicates limit of
                                liability of the insurer under the policy and
                                has to be indicated in all proposal forms.{" "}
                                <br></br>
                                <b>
                                  vi.&nbsp;Previous and present insurance{" "}
                                </b>{" "}
                                <br></br>
                                <b>vii. &nbsp; Loss experience</b>
                                <p
                                  style={{
                                    fontFamily: "Calibri",
                                    fontSize: "16px",
                                  }}
                                >
                                  The proposer is asked to declare full details
                                  of all losses suffered by him / her, whether
                                  or not they were insured. This will give the
                                  insurer information about the subject matter
                                  of insurance and how the insured has managed
                                  the risk in the past. Underwriters can
                                  understand the risk better from such answers
                                  and decide on conducting risk inspections or
                                  collecting further details.
                                </p>
                                <b>viii.&nbsp;Declaration by insured</b>
                                <p
                                  style={{
                                    fontFamily: "Calibri",
                                    fontSize: "16px",
                                  }}
                                >
                                  As the purpose of the proposal form is to
                                  provide all material information to the
                                  insurers, the form includes a declaration by
                                  the insured that the answers are true and
                                  accurate and he agrees that the form shall be
                                  the basis of the insurance contract. Any wrong
                                  answer will give the right to insurers to
                                  avoid the contract. Other sections common to
                                  all proposal forms relate to signature, date
                                  and in some cases agent‘s recommendation.
                                </p>
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 31/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                B. ACCEPTANCE OF THE PROPOSAL (UNDERWRITING)
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                We have seen that a completed proposal form
                                broadly gives the following information:
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <i className="bi bi-check"></i> Details of the
                                insured <br></br>
                                <i className="bi bi-check"></i> Details of the
                                subject matter <br></br>
                                <i className="bi bi-check"></i> Type of cover
                                required <br></br>
                                <i className="bi bi-check"></i> Details of the
                                physical features both positive and
                                negative-including type and quality of
                                construction, age, presence of fire fighting
                                equipment’s, the type of security etc.,{" "}
                                <br></br>
                                <i className="bi bi-check"></i> Previous history
                                of insurance and loss <br></br>
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The insurer may also arrange for pre-inspection
                                survey of the risk before acceptance, depending
                                on the nature and value of the risk. Based on
                                the information available in the proposal and in
                                the risk inspection report, additional
                                questionnaire and other documents, the insurer
                                takes the decision. The insurer then decides
                                about the rate to be applied to the risk factor
                                and calculates the premium based on various
                                parameters, which is then conveyed to the
                                insured.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Proposals are processed by the insurer with
                                speed and efficiency and all decisions thereof
                                are communicated by it in writing within a
                                reasonable period.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b>UNDERWRITING:</b> &nbsp;As per guidelines,
                                the company has to process the proposal within
                                15 days‟ time. The agent is expected to keep
                                track of these timelines, follow up internally
                                and communicate with the prospect / insured as
                                and when required by way of customer service.
                                This entire process of scrutinizing the proposal
                                and deciding about acceptance is known as
                                underwriting.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b>Sales Literature: </b> <br></br>
                                As per IRDAI, specific disclosures have to be
                                made in sales literature, especially features
                                like there are two insurers involved, that each
                                risk is distinct from the other, who will settle
                                claims, matters relating to renewability of both
                                or only one of the covers at the option of the
                                insured, servicing facilities etc.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Insurers have also to clearly draw the attention
                                of the policyholder in the policy contract and
                                sales literature that:
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                (a) All health insurance policies are portable;{" "}
                                <br></br>
                                (b) Policyholder should initiate action to
                                approach another insurer, to take advantage of
                                portability, well before the renewal date to
                                avoid any break in the policy coverage due to
                                delays in acceptance of the proposal by the
                                other insurer. <br></br>
                                IRDAI has cautioned members of the public not to
                                get carried away by unapproved sales
                                presentations being circulated in the market.
                                They may take an informed decision while
                                purchasing a policy, or the basis of proper
                                disclosures by the licensed representatives of
                                the insurer.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 32/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                4) PREMIUM
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b> Premium </b>is the consideration or amount
                                paid by the insured to the insurer for insuring
                                the subject matter of insurance, under a
                                contract of insurance.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                MODES OF PAYMENT OF PREMIUM
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The premium to be paid by any person proposing
                                to take an insurance policy or by the
                                policyholder to an insurer may be made in any
                                one or more of the following methods:
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                a) Cash <br></br>
                                b) Any recognised banking negotiable instrument
                                such as cheques, demand drafts, pay order,
                                banker’s cheques drawn on any schedule bank in
                                India; <br></br>
                                c) Postal money order; <br></br>
                                d) Credit or debit cards; <br></br>
                                e) Bank guarantee or cash deposit; <br></br>
                                f) Internet; <br></br>
                                g) E-transfer <br></br>
                                h) Direct credits via standing instruction of
                                proposer or the policyholder or the life insured
                                through bank transfers; <br></br>
                                i) Any other method or payment as may be
                                approved by the Authority from time to time;{" "}
                                <br></br>
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                As per IRDA Regulations, in case the proposer /
                                policyholder opts for premium payment through
                                net banking or credit / debit card, the payment
                                must be made only through net banking account or
                                credit / debit card issued on the name of such
                                proposer / policyholder.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                PREMIUM RECEIPT is issued against receipt of
                                premium by any of the above modes. If payment is
                                made through cheque, insurance cover is always
                                subject to relaisation of the cheque .
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 33/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br> <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                SECTION 64 VB OF INSURANCE CONTRACT
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                <b>
                                  {" "}
                                  Payment of Premium in Advance (Section 64 VB
                                  of Insurance Act, 1938){" "}
                                </b>{" "}
                                As per Insurance Act,{" "}
                                <b>
                                  premium is to be paid in advance, before the
                                  inception date of the insurance contract.
                                </b>{" "}
                                This is an important provision, which ensures
                                that only when the premium is received by the
                                insurance company, a valid insurance contract
                                can be completed and the risk can be assumed by
                                the insurance company. This section is a special
                                feature of non-life insurance industry in India.
                              </p>
                              <p
                                style={{
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                a) Section 64 VB of the Insurance Act-1938
                                providesthat no insurer shall assume any risk
                                unless and until the premium is received in
                                advance or is guaranteed to be paid or a deposit
                                is made in advance in the prescribed manner{" "}
                                <br></br>
                                b) Where an insurance agent collects a premium
                                on a policy of insurance on behalf of an
                                insurer, he shall deposit with or dispatch by
                                post to the insurer the premium so collected in
                                full without deduction of his commission within
                                twenty-four hours of the collection excluding
                                bank and postal holidays. <br></br>
                                c) It is also provided that the risk may be
                                assumed only from the date on which the premium
                                has been paid in cash or by cheque. <br></br>
                                d) Where the premium is tendered by postal or
                                money order or cheque sent by post, the risk may
                                be assumed on the date on which the money order
                                is booked or the cheque is posted as the case
                                may be. <br></br>
                                e) Any refund of premium which may become due to
                                an insured on account of the cancellation of
                                policy or alteration in its terms and conditions
                                or otherwise, shall be paid by the insurer
                                directly to the insured by a crossed or order
                                cheque or by postal / money order and a proper
                                receipt shall be obtained by the insurer from
                                the insured, and such refund shall in no case be
                                credited to the account of the agent. There are
                                exceptions to the bove pre-condition payment of
                                premium, provided in the Insurance Rules 58 and
                                59.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 34/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br> <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                5) COVER NOTES / CERTIFICATE OF INSURANCE /
                                POLICY DOCUMENT
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                After underwriting is completed it may take some
                                time before the policy is issued. Pending the
                                preparation of the policy or when the
                                negotiations for insurance are in progress and
                                it is necessary to provide cover on a
                                provisional basis or when the premises are being
                                inspected for determining the actual rate
                                applicable, a cover note is issued to confirm
                                protection under the policy. It gives
                                description of cover. Sometimes, insurers issue
                                a letter confirming the provisional insurance
                                cover instead of a cover note. <br></br>
                                <br></br>
                                Although the cover note is not stamped, the
                                wording of the cover note makes it clear that it
                                is subject to the usual terms and conditions of
                                the insurers' policy for the class of insurance
                                concerned. If the risk is governed by any
                                warranties, then the cover note would state that
                                the insurance is subject to such warranties. The
                                cover note is also made subject to special
                                clauses, if applicable e.g. Agreed Bank Clause,
                                Declaration Clause etc.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <br></br>A COVER NOTE WOULD INCORPORATE THE
                                FOLLOWING:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                a) Name and address of insured <br></br>
                                b) Sum insured <br></br>
                                c) Period of insurance <br></br>
                                d) Risk covered <br></br>
                                e) Rate and premium: if rate is not known, the
                                provisional premium <br></br>
                                f) Description of the risk covered: for example
                                a fire cover note would indicate identification
                                particulars of the building, its construction
                                and occupancy. <br></br>
                                g) Serial number of the cover note <br></br>
                                h) Date of issue <br></br>
                                i) Validity of cover note is usually for a
                                period of a fortnight and rarely up to 60 days{" "}
                                <br></br>
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <br></br>
                                The Motor Cover Note generally contains the
                                following particulars:
                              </label>

                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                a) Registration mark and number, or description
                                of the vehicles insured / cubic capacity /
                                carrying capacity / make / year of manufacture,
                                engine number, chassis number <br></br>
                                b) Name and address of the insured <br></br>
                                c) Effective date and time of commencement of
                                insurance for the purpose of the Act. Time……,
                                Date…… <br></br>
                                d) Date of expiry of insurance <br></br>
                                e) Persons or classes of persons entitled to
                                drive <br></br>
                                f) Limitations as to use <br></br>
                                g) Additional risks, if any <br></br>
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 35/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br> <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The Motor Cover Note incorporates a certificate
                                to the effect that it is issued in accordance
                                with the provisions of Chapters X and XI of the
                                Motor Vehicles Act, 1988.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                IMPORTANT
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                The validity of the Cover Note may be extended
                                for a further period of 15 days at a time, but
                                in, but in no case the total period of validity
                                of a Cover Note shall exceed two months.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b> NOTE: </b>The wordings of the cover note may
                                vary from insurer to insurer <br></br>
                                <b>
                                  {" "}
                                  Use of cover notes is being discouraged by
                                  most companies. Present day technology
                                  facilitates issuance of policy document
                                  immediately.{" "}
                                </b>
                              </p>
                              <br></br>
                              <h6
                                style={{ textAlign: "center", color: "gray" }}
                              >
                                CERTIFICATE OF INSURANCE – MOTOR INSURANCE{" "}
                              </h6>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                A certificate of insurance provides existence of
                                insurance in cases where proof may be required.
                                For instance in motor insurance, in addition to
                                the policy, a certificate of insurance is issued
                                as required by the Motor Vehicles Act. This
                                certificate provides evidence of insurance to
                                the Police and Registration Authorities.A
                                specimen certificate for private cars is
                                reproduced below, showing salient features.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 36/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br> <br></br>
                            <div
                              style={{
                                paddingLeft: "6rem",
                                paddingRight: "2rem",
                              }}
                            >
                              <label
                                style={{ paddingLeft: "15rem", color: "gray" }}
                              >
                                MOTOR VEHICLES ACT, 1988 <br></br> <br></br>
                                CERTIFICATE OF INSURANCE
                              </label>
                              <p>
                                Certificate No.{" "}
                                <span
                                  style={{
                                    paddingLeft: "29rem",
                                    fontWeight: "normal",
                                  }}
                                >
                                  Policy No.{" "}
                                </span>
                              </p>
                              <p>
                                <br></br>
                                1. Registration mark and Number, Place of
                                registration, Engine No. / Chassis No. / Make /
                                Year of manufacture. <br></br>
                                2. Type of Body / C.C / Seating capacity / Net
                                Premium / Name of Registration Authority,{" "}
                                <br></br>
                                3. Geographical area – India. ` <br></br>
                                4. Insured declared value (IDV) <br></br>
                                5. Name and address of the Insured, Business or
                                profession. <br></br>
                                6. Effective date of commencement of Insurance
                                for the purpose of the Act. From………. 'O' clock
                                on ……… <br></br>
                                7. Date of expiry of insurance: midnight on
                                …………… <br></br>
                                8. Persons or classes of persons entitled to
                                drive. <br></br>
                              </p>
                              <p>
                                Any of the following: <br></br>
                                (a) The insured: <br></br>
                                (b) Any other person who is driving on the
                                insured's order or with his permission
                              </p>
                              <p>
                                Provided that the person driving holds an
                                effective driving license at the time of the
                                accident and is not disqualified from holding or
                                obtaining such a license. Provided also that the
                                person holding an effective learner's license
                                may also drive the vehicle and such a person
                                satisfies the requirement of Rule 3 of Central
                                Motor Vehicles Rules 1989.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  fontSize: "16px",
                                }}
                              >
                                LIMITATIONS AS TO USE
                              </label>{" "}
                              <br></br>
                              <p>
                                The policy covers use for any purpose other
                                than: <br></br>
                                (a) Hire or reward; <br></br>
                                (b) Carriage of goods (other than personal
                                luggage) <br></br>
                                (c) Organised racing, <br></br>
                                (d) Race making, <br></br>
                                (e) Speed testing <br></br>
                                (f) Reliability Trials <br></br>
                                (g) Any purpose in connection with Motor Trade.{" "}
                                <br></br>
                              </p>
                              <p>
                                I/we hereby certify that the Policy to which
                                this Certificate relates as well as this
                                Certificate of Insurance are issued in
                                accordance with the provisions of Chapter X and
                                Chapter XI of the Motor Vehicles Act, 1988.
                                Examined .......
                              </p>
                              <p>(Authorized Insurer)</p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 37/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br> <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <h6
                                style={{ textAlign: "center", color: "gray" }}
                              >
                                INSURANCE POLICY DOCUMENT
                              </h6>
                              <p>
                                <br></br>
                                The policy is a formal document which provides
                                an evidence of the contract of insurance. This
                                document has to be stamped in accordance with
                                the provisions of the Indian Stamp Act, 1899.{" "}
                                <br></br>A general insurance policy usually
                                contains:
                              </p>
                              <p>
                                a) The name(s) and address(es) of the insured
                                and any other person having insurable interest
                                in the subject matter; <br></br>
                                b) Full description of the property or interest
                                insured; <br></br>
                                c) The location/s of the property or interest
                                insured under the policy and where appropriate,
                                with respective insured values; <br></br>
                                d) Period of insurance; <br></br>
                                e) Sums insured; <br></br>
                                f) Perils covered and exclusions ; <br></br>
                                g) Any excess / deductible applicable; <br></br>
                                h) Premium payable and where the premium is
                                provisional subject to adjustment, the basis of
                                adjustment of premium ; <br></br>
                                i) Policy terms, conditions and warranties;{" "}
                                <br></br>
                                j) Action to be taken by the insured upon
                                occurrence of a contingency likely to give rise
                                to a claim under the policy; <br></br>
                                k) The obligations ofthe insuredin relationto
                                the subject-matter of insurance upon occurrence
                                of an event giving rise to a claim and the
                                rights of the insurer in the circumstances;{" "}
                                <br></br>
                                l) Any special conditions ; <br></br>
                                m) Provision for cancellation of the policy on
                                grounds of misrepresentation, fraud,
                                non-disclosure of material facts or
                                non-cooperation of the insured; <br></br>
                                n) The address of the insurer to which all
                                communications in respect of the policy should
                                be sent; <br></br>
                                o) The details of the riders if any; <br></br>
                                p) Details of grievance redressal mechanism and
                                address of ombudsman <br></br>
                              </p>
                              <p>
                                Every insurer has to inform and keep (the
                                insured) informed periodically on the
                                requirements to be fulfilled by the insured
                                regarding lodging of a claim arising in terms of
                                the policy and the procedures to be followed by
                                him to enable the nsurer to settle a claim
                                early.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 38/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                ENDORSEMENT
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                It is the practice of insurers to issue policies
                                in a standard form; covering certain Perils and
                                excluding certain others.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Definition
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                If certain terms and conditions of the policy
                                need to be modified at the time of issuance, it
                                is done by setting out the amendments / changes
                                through a document called endorsement. <br></br>
                                <br></br>
                                It is attached to the policy and forms part of
                                it. The policy and the endorsement together
                                constitute the evidence of the contract.
                                Endorsements may also be issued during the
                                currency of the policy to record changes /
                                amendments. Whenever material information
                                changes, the insured has to advice the insurance
                                company who will take note of this and
                                incorporate the same as part of the insurance
                                contract through the endorsement. Endorsements
                                normally required under a policy related to:
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                a) Variations /changes in sum insured <br></br>
                                b) Change of insurable interest by way of sale,
                                mortgage, etc. <br></br>
                                c) Extension of insurance to cover additional
                                perils / extension of policy period. <br></br>
                                d) Change in risk, e.g. change of construction,
                                or occupancy of the building in Fire insurance{" "}
                                <br></br>
                                e) Transfer of property to another location{" "}
                                <br></br>
                                f) Cancellation of insurance <br></br>
                                g) Change in name or address etc. <br></br>
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                WARRANTY
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Warranties are used in an insurance contract to
                                limit the liability of the insurer under a
                                contract. Insurers incorporate appropriate
                                warranties to reduce the hazard. With a
                                warranty, one party to the insurance contract,
                                the insured, undertakes certain obligations that
                                need to be complied within a certain period of
                                time and the liability of the insurer depends on
                                the insured’s compliance with these obligations.
                                Warranties play an essential role in managing
                                and improving the risk.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 39/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b>
                                  A warranty is a condition expressly stated in
                                  the policy which has to be literally complied
                                  with for validity of the contract. Warranty is
                                  not a separate document.
                                </b>{" "}
                                It is part of both cover notes and policy
                                document. It is a condition precedent to the
                                contract. It must be observed and complied with
                                trictly and literally, irrespective of the fact
                                whether it is material to the risk or not. If a
                                warranty is breached, the policy becomes
                                voidable at the option of the insurers even when
                                it is clearly established that the breach has
                                not caused or contributed to a particular loss.
                                However, in practice, if the breach of warranty
                                is of a purely technical nature and does not, in
                                any way, contribute to or aggravate the loss,
                                (losses can be treated as non-standard claims
                                and settled) insurers at their discretion may
                                process the claims according to norms and
                                guidelines as per company policy.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                6) PROHIBITION OF REBATES( Section 41 of
                                Insurance Act )
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                No person shall allow or offer to allow, either
                                directly or indirectly, as an inducement to any
                                person to 1[take out or renew or continue] an
                                insurance in respect of any kind of risk
                                relating to lives or property in India, any
                                rebate of the whole or part of the commission
                                payable or any rebate of the premium shown on
                                the policy, nor shall any person taking out or
                                renewing[or continuing] a policy accept any
                                rebate, except such rebate as may be allowed in
                                accordance with the published prospectuses or
                                tables of the insurer: 2[Provided that
                                acceptance by an insurance agent of commission
                                in connection with a policy of life insurance
                                taken out by himself on his own life shall not
                                be deemed to be acceptance of a rebate of
                                premium within the meaning of this sub-section
                                if at the time of such acceptance the insurance
                                agent satisfies the prescribed conditions
                                establishing that he is a bona fide insurance
                                agent employed by the insurer. Any person making
                                default in complying with the provisions of this
                                section shall be punishable with fine which may
                                extend to five hundred rupees.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                7) RENEWAL NOTICE
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                <b>
                                  Most of the non-life insurance policies are
                                  insured on annual basis.
                                </b>
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Although there is no legal obligation on the
                                part of insurers to advise the insured that his
                                policy is due to expire on a particular date,
                                yet as a matter of courtesy and healthy business
                                practice, insurers issue a renewal notice in
                                advance of the date of expiry, inviting renewal
                                of the policy. The notice incorporates all the
                                relevant particulars of the policy such as sum
                                insured, the annual premium, etc. It is also the
                                practice to include a note advising the insured
                                that he should intimate any material alterations
                                in the risk. <br></br>
                                <b>
                                  In motor renewal notice, for example, the
                                  insured‘s attention is to be drawn to revise
                                  the sum insured (i.e. the insured‘s declared
                                  value of the vehicle) in the light of current
                                  requirements.
                                </b>{" "}
                                <br></br>
                                The insured’s attention is also to be invited to
                                the statutory provision that no risk can be
                                assumed unless the premium is paid in advance.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 40/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <br></br>8 ) CLAIMS SETTLEMENT PROCESS.
                              </label>
                              <br></br>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                1. IMPORTANCE OF SETTLING CLAIMS
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                The most important function of an insurance
                                company is to settle claims of policyholders on
                                the happening of a loss event. Insurer fulfils
                                this promise by providing prompt, fair and
                                equitable service in either paying the
                                policyholder or paying claims made against the
                                insured by a third party. <br></br>
                                Insurance is marketed as a financial mechanism
                                to provide indemnity on losses due to insured
                                perils. Were it not for insurance and the claim
                                settlement process, recovery to normal state
                                after an unfortunate accident / event might be
                                slow, inefficient and difficult. <br></br>
                                One of the non-life insurance companies had the
                                inscription “Pay if you can; repudiate if you
                                must” in its board room. That is the spirit of
                                the noble business of insurance.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b>
                                  Settling claims professionally is regarded the
                                  biggest advertisement for an insurance company
                                </b>
                              </p>
                              <br></br>
                              <ul
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  <b>a) PROMPTNESS</b>

                                  <p>
                                    <br></br>
                                    Prompt settlement of claims, whether the
                                    insured is a corporate client or an
                                    individual or whether the size of the loss
                                    is big or small is very important. It must
                                    be understood that the insured needs
                                    insurance compensation as soon as the
                                    possible after the loss. <br></br>
                                    If he gets the money promptly, it is of
                                    maximum use to him. It is insurance
                                    company’s duty to pay the claim amount when
                                    insured needs it most – as early as possible
                                    after the loss.
                                  </p>
                                </li>{" "}
                                &nbsp;
                                <li>
                                  <b> b) PROFESSIONALISM</b>
                                  <p>
                                    <br></br>
                                    The insurance officials consider each and
                                    every claim on its merits and do not apply
                                    prejudicial or pre-conceived notions to
                                    reject the claim without examining all the
                                    documents that would answer the following
                                    questions.
                                  </p>
                                  <p>
                                    i. Did the loss really happen? <br></br>
                                    ii. If so, did the loss making event really
                                    cause the damage? <br></br>
                                    iii. The extent of damage out of this event.{" "}
                                    <br></br>
                                    iv. What was the reason for the loss?{" "}
                                    <br></br>
                                    v. Was the loss covered under the policy?{" "}
                                    <br></br>
                                    vi. Is the claim payable as per the
                                    contract/ policy conditions? <br></br>
                                    vii. If so, how much is payable?
                                  </p>
                                  <p>
                                    The answers to all these questions need to
                                    be found out by the insurance Company.
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 41/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Processing claims is an important activity. All
                                claims forms, procedures and processes have been
                                carefully designed by the company to ensure that
                                all claims „payable‟ under the policy are
                                promptly paid and those that are not payable are
                                not paid. <br></br>
                                <br></br>
                                The agent, being the representative of the
                                company known to the insured, has to ensure that
                                all the relevant forms are properly filled up
                                with correct information, all documents
                                evidencing the loss are attached and all
                                prescribed procedures are followed in a timely
                                manner and duly submitted to the company. The
                                role of the agent at the time of loss has
                                already been discussed earlier.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                2. INTIMATION OR NOTICE OF LOSS
                              </label>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Policy conditions provide that the loss be
                                intimated to the insurer immediately. The
                                purpose of an immediate notice is to allow the
                                insurer to investigate a loss at its early
                                stages. Delays may result in loss of valuable
                                information relating to the loss. It would also
                                enable the insurer to suggest measures to
                                minimise the loss and to take steps to protect
                                salvage. The notice of loss is to be given as
                                soon as reasonably possible. <br></br>
                                <br></br>
                                After this initial check/scrutiny, the claim is
                                allotted a number and entered in the claims
                                register, with details like policy number, name
                                of insured, estimate of amount of loss, date of
                                loss, the claim is now ready to be processed.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b>
                                  Under certain types of policies (e.g.
                                  Burglary) notice is also to be given topolice
                                  authorities. Under cargo rail transit
                                  policies, notice has to be served on the
                                  Railways.{" "}
                                </b>
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                3) CLAIM FORM
                              </label>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                The contents of the claim form vary with each
                                class of insurance. In general the Claim form is
                                designed to get full information regarding the
                                circumstances of the loss, such as date of loss,
                                time, cause of loss, extent of loss, etc. The
                                other questions vary from one class of insurance
                                to another.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 42/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                EXAMPLE
                              </label>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                An example of information sought in a fire claim
                                form is given here under:
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                i. Name of the insured, policy number and
                                address <br></br>
                                ii. Date, time, cause and circumstances of the
                                fire <br></br>
                                iii. Details of damaged property <br></br>
                                iv. Sound value of the property at the time of
                                fire. Where the insurance consists of several
                                items under which the claim is made. [The claim
                                must be based on actual value of property at the
                                place and time of occurrence after allowance for
                                depreciation, wear and tear (unless the policy
                                in respect of building, plant and machinery is
                                on “reinstatement value” basis). It shall not
                                include profit] <br></br>
                                v. Amount claimed after deduction of salvage
                                value <br></br>
                                vi. Situation and occupancy of the premises in
                                which the fire occurred <br></br>
                                vii. Capacity in which the insured claims,
                                whether as owner, mortgage or the like <br></br>
                                viii. If any other person is interested in the
                                property damaged <br></br>
                                ix. If any other insurance is in force upon such
                                property if so, details thereof
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                This is followed by the declaration as to the
                                truth and accuracy of the statement of in the
                                form and signature of the insured and the date.{" "}
                                <br></br>A sample of fire claim form of an
                                insurance company is given as “Exhibit 1” in
                                this chapter. The issuance of claim form by the
                                insurance company does not imply or mean that
                                liability for the claim is admitted by insurers.
                                Claim forms are issued with the remark without
                                prejudice.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                4. APPONTMENT OF SURVEYORS AND LOSS ASSESSORS
                              </label>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Surveyors are professionals licensed by IRDAI.
                                They are experts in inspecting and evaluating
                                losses in specific areas. Surveyors are
                                generally paid fees by the insurance company,
                                engaging them. Surveyors and loss assessors are
                                hired by general insurance companies normally,
                                at the time of a claim. They inspect the
                                property in question, examine and verify the
                                causes and circumstances of the loss. They also
                                estimate the quantum of the loss and submit
                                reports to the insurance company.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 43/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                They also advise insurers, regarding appropriate
                                measures to prevent further losses. Surveyors
                                are governed by provisions of the Insurance Act,
                                1938, Insurance Rules 1939 and specific
                                regulations issued by IRDAI. Claims made outside
                                the country in case of Travel Policy or Marine
                                Open Cover for exports, are assessed by the
                                claims settling agents abroad named in the
                                policy. <br></br>
                                These agents may assess the loss and make
                                payment, which is reimbursed by the insurers
                                along with their settling fees. Alternatively,
                                all the claims papers are collected by the
                                insurance claim settling agents and submitted to
                                the insurers, along with their assessment.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                IMPORTANT
                              </label>
                              <br></br>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                SECTION 64 UM OF INSURANCE ACT
                              </label>

                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Where, in the case of a claim of less than
                                twenty thousand rupees in value on any policy of
                                insurance it is not practicable for an insurer
                                to employ an approved surveyor or loss assessor
                                without incurring expenses disproportionate to
                                the amount of the claim, the insurer may employ
                                any other person (not being a person
                                disqualified for the time being for being
                                employed as a surveyor or loss assessor) for
                                surveying such loss and may pay such reasonable
                                fee or remuneration to the person so employed as
                                he may think fit.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                5) INVESTIGATION AND ASSESSMENT
                              </label>

                              <ul
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                <li>
                                  <b>a) OVERVIEW</b>
                                </li>
                                <br></br>
                                <p>
                                  On receipt of the claim form, from the
                                  insured, the insurers decide about
                                  investigation and assessment of the loss. If
                                  the claim amount is small, the investigation
                                  to{" "}
                                  <b>determine the cause and extent of loss </b>
                                  is done, by an officer of the insurers.{" "}
                                  <br></br>
                                  <b>The investigation </b>of other claims is
                                  entrusted to independent licensed professional
                                  surveyor s who are specialists in loss
                                  assessment. The assessment of loss by
                                  independent surveyors is based on the
                                  principle that since both the insurers and
                                  insured are interested parties, the unbiased
                                  opinion of an independent professional person
                                  should be acceptable to both the parties as
                                  well as to a court of law in the event of any
                                  dispute.
                                </p>
                              </ul>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 44/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                b) CLAIMS ASSESSMENT
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                In case of fire, claim is assessed on the basis
                                of a police report, investigators report if
                                cause is unknown and a survey report. For
                                personal accident claims, the insured is
                                required to submit a report from the attending
                                doctor specifying the cause of accident or the
                                nature of illness as the case may be, and the
                                duration of disablement. <br></br>
                                Under policy conditions, the insurers reserve
                                the right to arrange an independent medical
                                examination. Medical evidence is also required
                                in support of “Workmen’s Compensation’s claims.
                                Livestock and cattle claims are assessed on the
                                basis of the report of a veterinary doctor.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                INFORMATION
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                On receipt of intimation of loss or damage
                                insurers check whether:
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                1. The insurance policy is in force on the date
                                of occurrence of the loss or damage <br></br>
                                2. The loss or damage is caused by an insured
                                peril <br></br>
                                3. The property (subject matter of insurance)
                                affected by the loss is the same as insured
                                under the policy <br></br>
                                4. Notice of loss has been received without
                                delay.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Motor third party claims involving death and
                                personal injuries are assessed on the basisof
                                doctor’s report. These claims are dealt by Motor
                                Accident Claims Tribunal and the amount to be
                                paid is decided by factors like the age and
                                Income of the claimant.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Claims involving third party property damage are
                                assessed on the basis of a survey report.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <i className="bi bi-check"></i> Motor own damage
                                claim is assessed on the basis of surveyors
                                report. <br></br>
                                <i className="bi bi-check"></i> It may require
                                police report if third party damage is involved.{" "}
                                <br></br>
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                INFORMATION
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Investigation is different from the assessment
                                of loss. Investigation is done to ensure that a
                                valid claim has been made and verify the
                                important details and doubts like absence of
                                insurable interest, suppression or
                                misrepresentation of material facts,
                                deliberately creating the loss, etc. are ruled
                                out.
                                <br></br>
                                Health insurance claims are assessed either in
                                house or by third party administrators (TPA’s)
                                on behalf of the non-life insurance companies.
                                The assessment is based on the medical reports
                                and expert opinion.
                                <br></br>
                                Insurance surveyors undertake the work of
                                investigation also. It helps if a surveyor gets
                                on to the job as early as possible. Therefore,
                                the practice is to appoint the surveyor, as soon
                                as possible after the intimation of the claim is
                                received.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 45/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                6) SUPPORTING DOCUMENTS
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                In addition to the claim form, certain documents
                                are required to be submitted by the claimant or
                                secured by the insurers to substantiate the
                                claim.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                i. For fire claims, a report from the Fire
                                Brigade would be necessary. <br></br>
                                ii. For cyclone damage, a report from the
                                Meteorological office may be a. called for{" "}
                                <br></br>
                                iii. In burglary claims, a report from the
                                Police may be necessary. <br></br>
                                iv. For fatal accident claims, reports may be
                                necessary from the Coroner and the Police.{" "}
                                <br></br>
                                v. For motor claims, the insurer may like to
                                examine driving license, a. Registration book,
                                police report etc. <br></br>
                                vi. In marine cargo claims, the nature of
                                documents varies according to the type of loss
                                i.e. total loss, particular average, inland or
                                overseas transit claims etc.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                7) LOSS ASSESSMENT AND CLAIM SETTLEMENT
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Claims assessment is the process of determining
                                whether the loss suffered by the insured is
                                caused by the insured peril and there is no
                                breach of warranty.
                                <br></br> <br></br>
                                Settlement of claims has to be based on
                                considerations of fairness and equity. For a
                                non-life Insurance company, expeditious
                                settlement of claim is the benchmark of
                                efficiency for its services. Each company has
                                internal guidelinesabout time taken in claims
                                processing, which its employees follow.
                                <br></br>
                                <br></br>
                                This is generally known by the term “Turnaround
                                time” (TAT). Some insurers have also put in
                                place, facility for the insured to check claim
                                status online from time to time.Some non-life
                                insurance companies have also set up claims hub
                                for speedy processing of claims.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                IMPORTANT
                              </label>
                              <br></br> <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                IMPORTANT ASPECTS IN AN INSURANCE CLAIM
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                i. The first aspect to be decided is whether the
                                loss is within the scope of the Policy. The
                                legal doctrine of proximate cause provides
                                guidelines to decide whether the loss is caused
                                by an insured peril or an excluded peril. The
                                burden of proof that the loss is within the
                                scope of the policy is upon the insured.
                                However, if the loss is caused by an excluded
                                peril the onus of proof is on the insurer.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 46/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                ii. The second aspect to be decided is whether
                                the insured has complied with policy conditions,
                                especially conditions which are precedent to
                                liability. <br></br>
                                iii. The third aspect is in respect of
                                compliance with warranties. The survey report
                                would indicate whether or not warranties have
                                been complied with. <br></br>
                                iv. The fourth aspect relates to the observance
                                of utmost good faith by the proposer,during the
                                currency of the policy. <br></br>
                                v. On the occurrence of a loss, the insured is
                                expected to act as if he is uninsured. In other
                                words, he has a duty to take measures to
                                minimise the loss. <br></br>
                                vi. The sixth aspect concerns the determination
                                of the amount payable. The amount of loss
                                payable is subject to the sum insured. However,
                                the amount payable will also depend upon the
                                following:
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                a. The extent of the insured’s insurable
                                interest in the property affected <br></br>
                                b. The value of salvage <br></br>
                                c. Application of underinsurance <br></br>
                                d. Application of contribution and subrogation
                                conditions
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                8) CATEGORIES OF CLAIM
                              </label>

                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                The claims which are dealt with in insurance
                                policies fall into the following categories:
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b> i. STANDARD CLAIMS </b> <br></br>
                                These are claims which are clearly within the
                                terms and conditions of the policy. The
                                assessment of claim is done keeping in view
                                scope and the sum insured opted for and other
                                methods of indemnity laid down for various
                                classes of insurance. The claim amount payable
                                by the insurer takes into account various
                                factors like valuation at time of loss,
                                insurable interest, salvage prospects, loss of
                                earnings, loss of use, depreciation, replacement
                                value depending on the policy taken.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b>ii. NON-STANDARD CLAIMS </b> <br></br>
                                These are claims where the insured may have
                                committed a breach of condition or warranty. The
                                settlement of these claims is considered subject
                                to rules and regulations framed by the non-life
                                insurance companies.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b>
                                  iii.CONDITION OF AVERAGE OR AVERAGE CLAUSE{" "}
                                </b>{" "}
                                <br></br>
                                This is a condition in some policies which
                                penalises the insured for insuring his property
                                at a sum insured less than its actual value
                                known as underinsurance. In the event of a claim
                                the insured gets an amount that is
                                proportionately reduced from his actual loss in
                                accordance to the amount underinsured.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 47/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b>
                                  {" "}
                                  iv. ACT OF GOD PERILS - CATASTROPHIC LOSSES{" "}
                                </b>{" "}
                                <br></br>
                                Natural perils like storm, cyclone, flood,
                                inundation, and earthquake are termed as “Act of
                                God” perils. These perils may result in losses
                                to many policies of insurer in the affected
                                region. <br></br> <br></br>
                                In such major and catastrophic losses, the
                                surveyor is asked to proceed to the loss site
                                immediately for an early assessment and loss
                                minimisation efforts. Simultaneously, insurers‟
                                officials also visit the scene of loss
                                particularly when the amount involved is large.
                                The purpose of the visit is to obtain an
                                immediate, on the spot idea of the nature and
                                extent of loss. <br></br> <br></br>
                                Preliminary reports are also submitted if the
                                surveyors face some problems in regards to the
                                assessment and may desire guidance and
                                instructions from insurers who are thus given an
                                opportunity to discuss the issues with the
                                insured, if necessary.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                ON ACCOUNT PAYMENT
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Apart from preliminary reports, interim reports
                                are submitted from time to time where repairs
                                and/or replacements are made over a long period.
                                Interim reports also give the insurer an idea of
                                the development of assessment of loss. It also
                                helps in recommendation of "On account payment"
                                of the claim if desired by the insured. This
                                usually happens if the loss is large and the
                                completion of assessment may take some time.{" "}
                                <br></br>
                                <br></br>
                                If the claim is found to be in order, payment is
                                made to the claimant and entries made in the
                                company records. Appropriate recoveries are made
                                from the co-insurers and reinsurers, if any. In
                                some cases, the insured may not be the person to
                                whom the money is to be paid.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                EXAMPLE
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                If the property insured under a fire policy is
                                mortgaged to a bank, then according to the
                                “Agreed Bank Clause”, claim monies are to be
                                paid to the bank. Similarly claims for “Total
                                Loss” on vehicles subject to hire purchase
                                agreements are paid to financiers. <br></br>{" "}
                                <br></br>
                                Marine cargo claims are paid to the claimant who
                                produces the marine policy duly endorsed in his
                                favour, at the time of the loss.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 48/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "4rem",
                              }}
                            >
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b>9) DISCHARGE VOUCHERS </b> Settlement of the
                                claim is made only after obtaining a discharge
                                under the policy. A sample of discharge receipt
                                for claims (under personal accident insurance)
                                for injuries is worded along the following
                                lines: (may vary from company to company
                              </p>
                              <br></br>
                              <div
                                style={{
                                  border: "1px solid black",
                                  paddingLeft: "2rem",
                                }}
                              >
                                Name of the Insured <br></br> <br></br>
                                The Sum of Rs. ...............is full and final
                                settelment of compensation due to Me/us on
                                account of injuries sustained by me/us due to
                                accident which occured on or about the
                                ................ I/We give the discharge receipt
                                to the Company in full and final settelment of
                                all my/our claim present or future arising
                                directly or indirectly in respect of the said
                                claim. <br></br> <br></br>
                                Date{" "}
                                <span
                                  style={{
                                    fontFamily: "Calibri",
                                    paddingLeft: "35rem",
                                  }}
                                >
                                  (Signature)
                                </span>
                              </div>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                10) POST SETTLEMENT ACTION
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                The action taken after settlement of the claim
                                in relation to underwriting varies from one
                                class of business to another.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                EXAMPLE
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Sum insured under a fire policy stands reduced
                                to the extent of the amount of claim paid.
                                However, it can be reinstated on payment of
                                pro-rata premium, which is deducted from the
                                amount of claim paid. <br></br>
                                On payment of the capital sum insured under a
                                personal accident policy, the policy stands
                                cancelled. <br></br>
                                Similarly, payment of a claim under individual
                                fidelity guarantee policy automatically
                                terminates the policy.
                              </p>{" "}
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                SALVAGE
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Salvage generally refers to damaged property. On
                                payment of loss, the salvage belongs to
                                insurers.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                EXAMPLE
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                When motor claims are settled on total loss
                                basis, the damaged vehicle is taken over by
                                insurers. Salvage can also arise in fire claims,
                                marine cargo claims etc. <br></br> Salvage is
                                disposed off according to the procedure laid
                                down by the companies for the purpose.
                                Surveyors, who have assessed the loss, will also
                                recommend methods of disposal.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                RECOVERIES
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                After settlement of claims, the insurers under
                                subrogation rights applicable to insurance
                                contracts, are entitled to the rights and
                                remedies of the insured and to recover the loss
                                paid from a third party who may be responsible
                                for the loss under respective laws applicable.
                                Thus, insurers can recover the loss from
                                shipping companies, railways, road carriers,
                                airlines, port trust authorities etc
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 49/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                EXAMPLE
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                In the case of non-delivery of consignment, the
                                carriers are responsible for the loss.
                                Similarly, the port trust is liable for goods
                                which are safely landed but subsequently
                                missing. For this purpose, a letter of
                                subrogation duly stamped is obtained from the
                                insured before the settlement of the claim.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                DISPUTES RELATED TO CLAIMS
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Despite best efforts, there could be reasons for
                                either delay or non-payment Repudiation) of
                                claim, either due to delay in notice of loss or
                                non-submission of Documents by clients.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Apart from these, the most common reasons, to
                                name a few are:
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <i className="bi bi-check"></i> Non-disclosure
                                of material facts <br></br>
                                <i className="bi bi-check"></i> Lack of coverage{" "}
                                <br></br>
                                <i className="bi bi-check"></i> Loss caused by
                                excluded perils <br></br>
                                <i className="bi bi-check"></i>Lack of adequate
                                sum insured <br></br>
                                <i className="bi bi-check"></i> Breach of
                                warranty <br></br>
                                <i className="bi bi-check"></i>Issues regarding
                                quantum due to underinsurance, depreciation,
                                etc. <br></br>
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                All this could cause considerable grief to the
                                insured at a time when he is already suffering
                                from financial constraints arising due to
                                losses. In order to reduce his sufferings,
                                grievance redressal and dispute handling
                                procedures are well laid out in the policy
                                itself.
                              </p>{" "}
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                GENERAL INFORMATION :
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                <b>Survey Report: </b> <br></br>
                                As laid down in the Insurance Act, 1938, if the
                                amount of loss is Rs 20,000/- or more, a
                                licensed Surveyor is to be appointed to assess
                                the loss. Surveyor has to be appointed within 72
                                hours by the insurance company. Surveyor would
                                visit the site of happening and submit his
                                report to the insurer including therein —
                              </p>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>Cause of loss</li>
                                <li>Quantum of the loss</li>
                                <li>Quantum of the loss</li>
                                <li>
                                  Comments about admissibility of claim and
                                  compliance of terms and conditions of the
                                  policy by the insured.
                                </li>
                              </ol>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                In marine branch, the Surveyor's fee is
                                initially borne by the insured. It is reimbursed
                                if the insurer accepts the claim.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 50/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                First Information Report (FIR):
                              </label>

                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                First information report is information to
                                police authorities generally regarding-
                              </p>
                              <br></br>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "1.5rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>Theft, burglary or housebreaking cases</li>
                                <li>
                                  Major vehicular accident injuring third
                                  parties or damaging the r-properties
                                </li>
                                <li>
                                  Fire incident involving injuries to any
                                  persons or causing major property loss
                                </li>
                                <li>
                                  Cases involving financial frauds by employees
                                  or others cause of loss to the insured
                                </li>
                                <li>Death of an individual-due to accident</li>
                              </ol>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                From the insurer's point, FIR is the document
                                which gives information about the intimation of
                                crime, circumstances and likely cause of loss
                                and likely persons involved and approximate
                                value of loss.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Death Certificate and Post Mortem Report:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Death Certificate is required for claim under
                                personal accident and health insurance policies.
                                It is also required in case of cattle or other
                                animal insurances. Death certificate relating to
                                humans is issued Municipality or Gram Panchayat
                                of the area in which the deceased. was residing
                                or died. For cattle and animals, it is issued by
                                a' veterinary surgeon treating the animal or who
                                attended it at the time of the death. Post
                                Mortem Report (PMR) is examination of dead body,
                                generally conducted in accidental or doubtful
                                deaths for testing various organs to find out
                                the cause of death. The pos.- mortem is
                                performed at government or local body's
                                hospital. In case
                                <br></br>
                                <br></br>
                                of insurance of animals post mortem report is
                                required, which is conducted by a veterinary
                                surgeon.
                              </p>

                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Specific Documents in addition to the claim
                                form:
                              </label>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <br></br>
                                Motor Insurance Claims:
                              </label>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>Estimate of repairs, repair bills</li>
                                <li>Survey report</li>
                                <li>Vehicle documents</li>
                                <li>
                                  FIR in case of theft of vehicle or injury or
                                  damage to a third party/ property
                                </li>
                              </ol>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <br></br>
                                Cattle Insurance Claims:
                              </label>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  Death Certificate on insurance company's form.
                                </li>
                                <li>
                                  In case of death of animal insured under
                                  Integrated Rural Development Programme (IRDP)
                                  or other similar scheme, death certificate may
                                  be issued by Panchayats.
                                </li>
                              </ol>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 51/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>Post mortem report</li>
                                <li>Ear tag.</li>
                                <li>
                                  Valuation certificate by veterinary doctor.
                                </li>
                                <li>
                                  In case of claims for disablement veterinary
                                  doctor's certificate with treatment details.
                                </li>
                              </ol>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <br></br>
                                Poultary Insurance Claims:
                              </label>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  Veterinary doctor post mortem report of sample
                                  birds
                                </li>
                                <li>
                                  Daily records of mortality, feeding etc.
                                </li>
                                <li>Purchase invoice for birds</li>
                                <li>Photographs, medical bills etc.</li>
                              </ol>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                <br></br>
                                KYC Documents:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                In order to avoid money-laundering, KYC norms
                                are carried out at settlement stage where
                                pay-out amount is more than one lakh. In case
                                where payments are made to service providers
                                such as hospitals/garages/repairers etc. the KYC
                                norms are applied on the customer on whose
                                behalf they act. Documents relevant to KYC are:
                                -
                              </p>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  Proof of identify – any one out of the
                                  specified list for this purpose.
                                </li>
                                <li>
                                  Proof of Residence – any one out of the
                                  specified list
                                </li>
                              </ol>

                              <p>
                                For Micro insurance policies, bank passbook or
                                bank statement giving name and residence may be
                                acceptable.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 52/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <div
                              className="Para"
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "2rem",
                              }}
                            >
                              <h1
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  color: "#0093dd",
                                }}
                              >
                                Module 4
                              </h1>{" "}
                              <br></br>
                              <h4
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  color: "gray",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                POS GENERAL INSURANCE PRODUCTS{" "}
                              </h4>{" "}
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                SPECIFIC PRODUCTS TO BE CANVASSED AND SOLD BY
                                THE POS PERSONS.{" "}
                              </label>
                              <p
                                style={{
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                1) Motor Comprehensive Insurance Package Policy
                                for Two-wheeler, Private car and Commercial
                                vehicles. <br></br>
                                2) Third party liability (Act only) Policy for
                                Two-wheeler, Private car and Commercial
                                vehicles. <br></br>
                                3) Personal Accident Policy <br></br>
                                4) Janta and Gramin Personal Accident policy (
                                JPA/GPA ) <br></br>
                                5) Travel Insurance Policy <br></br>
                                6) Health Insurance Policy <br></br>
                                7) Home Insurance Policy( Fire and Allied Perils
                                policy for Dwellings.) <br></br>
                                8) Cattle/ Live stock /Poultry Insurance Policy.{" "}
                                <br></br>
                                9) Agricultural Pump Set Insurance. <br></br>
                                10) Crop Insurance (Govt Schemes such as Pradhan
                                Mantri Fasal Bima Yojna( PMFBY) <br></br>
                                11) Whether based crop Insurance Scheme (WBCIS){" "}
                                <br></br>
                                12) Coconut Palm Insurance Scheme (CPIS ){" "}
                                <br></br>
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Any other Policy specifically approved by the
                                Authority from time to time.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 53/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  paddingLeft: "15rem",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                MOTOR INSURANCE:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <br></br>
                                Must be taken by a vehicle owner of a vehicle
                                plying on public roads whose vehicle is
                                registered in her his name with the Regional
                                Transport Authority in India; <br></br>
                                <br></br>
                                Under The Motor Vehicles Act, 1988, it is
                                mandatory for ever, owner of a vehicle plying on
                                public roads, to take an insurant policy, to
                                cover the amount, which the owner becomes
                                legal!, liable to pay as damages to third
                                parties as a result o accidental death, bodily
                                injury or damage to property.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                A certificate of Insurance rust be carried in
                                the vehicle as a proof o such insurance.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                1) Third - Party Insurance:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                An insurance policy purchased for protection
                                against the legal actions of another party.
                                Purchased by the insured (first party) from an
                                insurance company (second party) for 0-otection
                                against another party's claims (third party) for
                                liability arising out of the action of the
                                insured is called "Liability Insurance" as well
                                as two popular and important types of covers:
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Act [Liability] Only Policy:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                As per Motor Vehicles Act, it is mandatory for
                                any vehicle plying in public place to insure
                                liabilities towards third parties and passengers
                                in Public Service Vehicle for example: bus, taxi
                                etc.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                2) Package Policy/Comprehensive Policy:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Package Policy/Comprehensive Policy: [own damage
                                + third party liability]. The sum insured of a
                                'vehicle in a motor policy is referred to as
                                Insured's Declared Value (IDV). <br></br>
                                Rating / remium calculation depends on factors
                                like the insured's declared value, cubic
                                capacity, geographical zone, age of the vehicle
                                etc.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                MOTOR INSURANCE COVERAGE
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The country has a large vehicle population. A
                                number of new vehicles keep coming on to the
                                road every day. Many of them are very costly as
                                well. People say that in India, vehicles do not
                                get junked, but only keep changing hands. This
                                means that old vehicles continue to be on the
                                road and new vehicles get added. The area of the
                                roads (the space for driving) is not growing
                                correspondingly with the number of vehicles. The
                                number of people walking on the road is also
                                increasing. Police and hospital statistics say
                                that the number of road accidents in the country
                                is increasing. The amount of compensations
                                awarded to accident victims by Courts of Law are
                                increasing. Even vehicle repair costs are going
                                up.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 54/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                All these show the importance of motor insurance
                                in the country
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Motor insurance covers the loss of vehicles and
                                the damages to them due to accidents and some
                                other reasons. Motor insurance also covers the
                                legal liability of vehicle owners to compensate
                                the victims of the accidents caused by their
                                vehicles.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Do you think all the vehicles in the country are
                                insured?
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Motor Insurance covers all types of vehicles
                                plying on public roads such as:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <i className="bi bi-check"></i> Scooters and
                                motorcycles <br></br>
                                <i className="bi bi-check"></i> Private cars{" "}
                                <br></br>
                                <i className="bi bi-check"></i> All types of
                                commercial vehicles: Goods carrying and
                                passenger carrying <br></br>
                                <i className="bi bi-check"></i>Miscellaneous
                                type of vehicles e.g. cranes <br></br>
                                <i className="bi bi-check"></i>Motor Trade
                                (Vehicles in Showrooms and Garages) <br></br>
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                INFORMATION:THIRD-PARTY INSURANCE
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                An insurance policy purchased for protection
                                against the legal actions of another party.
                                Third-party insurance is purchased by the
                                insured (first party) from an insurance company
                                (second party) for protection against another
                                party's claims (third party) for liability
                                arising out of the action of the insured{" "}
                                <br></br>
                                Third party insurance is called Liability
                                Insurance as well.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Two important types of covers that are popular
                                in the market are discussed below:
                              </label>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b> a) ACT [LIABILITY] ONLY POLICY:</b> As per
                                Motor Vehicles Act it is mandatory for any
                                vehicle plying in public place to insure
                                liabilities towards third parties.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The policy only covers the vehicle owner's legal
                                liability to pay compensation for:
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <i className="bi bi-check"></i> Third party
                                bodily injury or death <br></br>
                                <i className="bi bi-check"></i> Third party
                                property damage <br></br>
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Liability is covered for an unlimited amount in
                                respect of death or injury and damage.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The claims for compensation to third party
                                victims in case of death or injury caused by a
                                motor accident are to be filed by the
                                complainant in Motor Accident Claim Tribunal
                                (MACT).
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <b>
                                  b) PACKAGE POLICY / COMPREHENSIVE POLICY:(OWN
                                  DAMAGE + THIRD PARTY LIABILITY)
                                </b>{" "}
                                &nbsp; In addition to the above, the loss or
                                damage to the vehicle insured by specified
                                perils (known as own damage to motor vehicles)
                                is also covered subject to the value declared
                                (called IDV - already discussed in chapter 5)
                                and other terms and conditions in the policy.
                                Some of these perils are fire, theft, riot and
                                strike, earthquake, flood, accident etc.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 55/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Some insurers may also pay for towing charges
                                from the place of accident to the workshop. A
                                restricted cover is also available covering the
                                risk of fire and / or theft only, in addition to
                                the compulsory cover granted under Act
                                (Liability) Only Policy.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The policy can also cover loss or damage to
                                accessories fitted in the vehicle, personal
                                accident cover under private car policies for
                                passengers, paid driver; legal liability to
                                employees and non-fare paying passengers in
                                commercial vehicles. Insurers also provide free
                                emergency services or use of alternative car in
                                case of breakdown.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                EXCLUSIONS
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Some of the important exclusions under the
                                policies are wear and tear, breakdowns,
                                consequential loss, and loss due to driving with
                                invalid driving license or under the influence
                                of alcohol. Use of vehicle not in accordance
                                with `limitations as to use ' (e.g. private car
                                being used as a taxi) is not covered.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                SUM INSURED AND PREMIUM
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The sum insured of a vehicle in a Motor Policy
                                is referred to as Insured's Declared Value
                                (I.D.V.).
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                In case of theft of vehicle or total damage
                                beyond repairs in an accident, the claim amount
                                will be determined on the basis of the IDV. The
                                IDV of the vehicle is fixed on the basis of the
                                manufacturer’s / dealer’s listed selling price
                                of the brand and model of the vehicle proposed
                                for insurance at the commencement of insurance /
                                renewal and adjusted for depreciation as per
                                schedule. <br></br>
                                IDV of vehicle which is beyond 5 years of age
                                and of obsolete models of the vehicles (i.e.
                                models which the manufacturers have discontinued
                                to manufacture) is determined on the basis of an
                                understanding between insurers and insured.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Rating / premium calculation depends on factors
                                like the Insured’s Declared Value, cubic
                                capacity, geographical zone, age of the vehicle
                                etc.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                3) Personal Accident Insurance
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                If a person dies or is disabled totally or
                                partially his earnings would completely stop or
                                will reduce to a great extent. This will have
                                adverse effect on insured as well as his family.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The policy is available: -
                                <ol
                                  style={{
                                    listStyleType: "disc",
                                    fontFamily: "Calibri",
                                    fontSize: "16px",
                                  }}
                                >
                                  <li>For individuals</li>
                                  <li>
                                    For a family consisting of spouse and
                                    dependent children only (NOT PARENTS)
                                  </li>
                                </ol>
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 56/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                When a person meets with an accident and sustain
                                bodily injury may result into death, loss of
                                limbs or disability, P.A. policy provides
                                benefits as given in the table here below:
                              </p>
                              <table
                                style={{
                                  border: "1px solid black",
                                  width: "50%",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <tr
                                  style={{
                                    border: "1px solid black",
                                    width: "50%",
                                  }}
                                >
                                  <th
                                    style={{
                                      border: "1px solid black",
                                      width: "50%",
                                    }}
                                  >
                                    Benefit description
                                  </th>
                                  <th>Claim amount — as % of sum insured</th>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      width: "50%",
                                    }}
                                  >
                                    1. Death
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      width: "50%",
                                    }}
                                  >
                                    100%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      width: "50%",
                                    }}
                                  >
                                    2. Loss of 2 limbs, both eyes, 1 limb and 1
                                    eye
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      width: "50%",
                                    }}
                                  >
                                    100%
                                  </td>
                                </tr>
                              </table>
                              <br></br>
                              <table
                                style={{
                                  border: "1px solid black",
                                  width: "50%",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      width: "50%",
                                    }}
                                  >
                                    3. Loss of 1 limb or 1 eye
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      width: "50%",
                                    }}
                                  >
                                    50%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      width: "50%",
                                    }}
                                  >
                                    4. Permanent total disablement
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      width: "50%",
                                    }}
                                  >
                                    100%
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      width: "50%",
                                    }}
                                  >
                                    5. Permanent partial disablement
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid black",
                                      width: "50%",
                                    }}
                                  >
                                    as per type of loss e.g. little finger 4%,
                                    thumb 25%, loss of hearing of 1 ear 30%
                                  </td>
                                </tr>
                              </table>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The contingencies 1 to 4 above are called
                                Capital Benefits and in the event of any of
                                these losses the claim is paid inclusive of
                                Cumulative Bonus earned; and policy is called
                                back duly discharged for cancellation. <br></br>
                                In addition to the above benefits, if death
                                occurs, the insurer may provide for the
                                following benefits without charging any extra
                                premium:
                              </p>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  Expenses for carriage of dead body for a fixed
                                  amount as stated in the policy
                                </li>
                                <li>
                                  Education Fund: If the insured had minor
                                  dependent children upto the age of 25 years a
                                  sum of Rs. 5000 per child for maximum 2
                                  children as EK1catior Fund is also paid.
                                </li>
                              </ol>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Medical expense reimbursement can also be taken
                                as an add-on benefit with extra premium. The
                                amount payable will be 25% of the admissible
                                claim amount or 10% of the sum insured or actual
                                whichever is lowest. This percentage may vary.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Exclusions:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                (a) Claim for injury or death due to self
                                injury, suicide or attempted suicide <br></br>
                                (b) Under the influence of intoxicating liquor
                                or drugs <br></br>
                                (c) Participating in hazardous sports <br></br>
                                (d) Travelling as crew in an aircraft or as
                                passenger in unscheduled aircraft <br></br>
                                (e) Service in armed forces <br></br>
                                (f) By venereal disease or insanity or
                                childbirth or pregnancy <br></br>
                                (g) War, civil war and similar situations{" "}
                                <br></br>
                                Death/Permanent Total Disability/Permanent
                                Partial Disability must occur within 12
                                months-pf. accident. The policy is 24x7 and
                                covers accident anywhere the world; but claim is
                                payable in India in Indian currency.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 57/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Eligibility:
                              </label>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  The premium is charged on the basis of the
                                  occupation of the proposer and the
                                  contingencies opted for.
                                </li>
                                <li>
                                  Eligibility age is 18to 70 years depending
                                  upon company to company.
                                </li>
                                <li>
                                  Policy for a family can also be taken. Family
                                  means husband, wife and dependent
                                  children.Family discount is given on the
                                  premium.
                                </li>
                              </ol>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                4) Janata Personal Accident/Gramin Personal
                                Accident Policy:
                              </label>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                i) Janta Personal Accident Policy
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The policy is similar to PA above, with
                                following differences: <br></br>
                                (a) The minimum sum insured is Rs. 25,000 (ID){" "}
                                <br></br>
                                (b) Maximum Rs, 1,00,000 in multiples of Rs
                                25,000. <br></br>
                                (c) Education Fund and medical expenses are not
                                covered <br></br>
                                (d) Cumulative Bonus is not available <br></br>
                                (e) The policy can be taken for a maximum of 5
                                years at a time.<br></br>
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                ii) Gramin Personal Accident Policy:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                (a) Applicable to all persons residing in rural
                                areas only. <br></br>
                                (b) One policy per person. However person having
                                a Gramin Policy may also take one Janata Policy{" "}
                                <br></br>
                                (c) No medical examination <br></br>
                                (d) Age group: 10to 70 years <br></br>
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Government Schemes:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Sometimes covers are issued by the insurance
                                companies based on schemes drafted by the
                                Government. Pradhan Mantri Suraksha BimaYojna
                                where sum insured per person is Rs.2,00,00 and
                                Premium per year is Rs. 12 only paid through
                                their bank account. Cover in respect of Death
                                Rs.2,00,000, Loss of 2 limbs, both eyes, 1 limb
                                and 1 eye Rs.2,00,000. Loss of 1 limb or 1 eye
                                Rs.1„00„000. Cover is available for bank account
                                holders. Aadhaar card is necessary as part of
                                KYC validation. Cover is given through
                                nationalized insurance companies and also by
                                other insurance companies. Who agrees to follow
                                applicable rules and regulations for issuance of
                                such covers, standing instruction could be given
                                to the bank for debiting their account to pay
                                Annual renewal premium.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 58/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                5) Overseas Travel Insurance
                              </label>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  The policy is available to persons travelling
                                  overseas.
                                </li>
                                <li>
                                  The cover is for emergency medical treatment
                                  that may be needed when on tour.
                                </li>
                                <li>
                                  Persons between the ages of 5 years to 70
                                  years can be covered. Children from 3 ears
                                  onward can be covered, if accompanying
                                  parents.
                                </li>
                                <li>
                                  Premium is payable in Rupees but cover is in
                                  US dollars. Age and duration of trip are
                                  factors deciding the premium.
                                </li>
                                <li>
                                  The cover given is either worldwide excluding
                                  USA and Canada or including USA and Canada.
                                </li>
                              </ol>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiiTC",
                                }}
                              >
                                Coverage:
                              </label>
                              <p
                                style={{
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                (a) Medical expense and Repatriation. An excess
                                of US $100 is applicable for each and every
                                claim <br></br>
                                (b) Personal Accident US $50000. For children
                                restricted to US $2000. Only death and permanent
                                total disability are covered <br></br>
                                (c) Loss of checked baggage. Excess is
                                applicable for the claim Delay of checked
                                baggage. <br></br>
                                (d) Delay should exceed 12 hours from scheduled
                                date of arrival at final overseas destination
                                (not applicable to return journey home){" "}
                                <br></br>
                                (e) Loss of passport limited amount and subject
                                to an excess for the claim <br></br>
                                (f) Personal Liability <br></br>
                                The journey must commence within 14 days of the
                                date of commencement of the policy.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Special Conditions:
                              </label>
                              <p
                                style={{
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                As this is a medical and PA cover, the terms of
                                Mediclaim and PA apply with following
                                modifications: <br></br>
                                (a) The policy is for emergency medical
                                treatment and not for planned hospitalization{" "}
                                <br></br>
                                (b) In a medical emergency, the claim settling
                                agency mentioned in the policy is to be
                                contacted immediately and assistance sought for
                                the treatment. All other claims to be filed on
                                return to India. <br></br>
                                (c) Exiting health conditions are not covered.
                                Travel against medical advice is also no
                                covered. <br></br>
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 59/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                6) HEALTH INSURANCE
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The Insurance Laws (Amendment) Act, 2015 defines
                                the "health insurance business as contracts
                                which provide for sickness benefits or medical,
                                surgical or Hospital expense benefits, whether
                                in-patient or out-patient. travel cover and
                                personal accident cover." Each insurance company
                                has designed products of its own to meet the
                                customer demand. Uniformity therein may not be
                                possible, but the coverage and main features are
                                similar.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Hospitalization Insurance Policy:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Popularly known as 'Mediclaim', the
                                hospitalization policy covers medical expenses
                                of treatment of in-patients (persons has
                                Hospitalise for minimum 24 hours) with some
                                additional features.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Coverage:
                              </label>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <li>Room and board charges</li>
                                <li>
                                  Consultants', surgeons', anaesthetists' fees,
                                  including that of nurses etc.
                                </li>
                                <li>
                                  Blood transfusion, medicines, lab tests and
                                  other investigations
                                </li>
                                <li>
                                  Operation theatre, anaesthesia, artificial
                                  limbs etc.
                                </li>
                                <li>
                                  Ambulance Charges for a specified amount
                                </li>
                              </ol>

                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Special Features of the Policy:
                              </label>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                24 hours hospitalization in the Hospital:
                              </label>
                              <br></br>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                To avail the claim, the insured has to stay in a
                                registered nursing home/ hospital for a minimum
                                of 24 hours.
                              </p>

                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Day Care Treatment:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                There are certain diseases like cataract,
                                dialysis, chemotherapy, radiotherapy etc. the
                                treatment of which does not require 24 hours
                                hospitalization. These come under 'Day care
                                treatment'.
                              </p>

                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Domiciliary Hospitalization:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                According to this feature, the insured can get
                                the claim while being treated at home, provided
                                the following conditions are met;
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 60/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  The Patient is so critical that he cannot be
                                  taken to a hospital
                                </li>
                                <li>
                                  No bed is available in the hospital where the
                                  treating doctor is visiting. However, there is
                                  a 'waiting period' of 3 days for this feature.
                                </li>

                                <label
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "ErasDemiITC",
                                  }}
                                >
                                  Pre and Post Hospitalization Charges:
                                </label>
                                <li
                                  style={{
                                    fontFamily: "Calibri",
                                    fontSize: "16px",
                                  }}
                                >
                                  Pre hospitalization expenses could be in the
                                  form of tests, medicines, doctors' fees etc.
                                  Such expenses are relevant and pertaining to
                                  the hospitalization are covered under the
                                  health policies.
                                </li>
                                <li
                                  style={{
                                    fontFamily: "Calibri",
                                    fontSize: "16px",
                                  }}
                                >
                                  Post hospitalization expenses: After stay in
                                  the hospital, in most cases there would be
                                  expenses related to recovery and follow-up.
                                </li>
                              </ol>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Generally 30 days pre hospitalization and 60
                                days post hospitalization are covered.
                                However,this may vary from company to company.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Cumulative Bonus:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Cumulative Bonus means an increase in the sum
                                assured granted by the insurer without an
                                associated increase in premium. It was the
                                practice to provide incentive to clients to
                                renew the policy for which purpose the insurance
                                company would give additional sum insured of 5%
                                free of cost at the time of claim free renewal
                                up to certain maximum percentage. <br></br>
                                Health Insurance Regulations 2013 provide that
                                insurers may offer cumulative bonus on indemnity
                                based health insurance policies, which shall be
                                stated explicitly in the Prospectus and the
                                Policy document. <br></br>
                                <br></br>
                                if a claim is made in any particular year, the
                                cumulative bonus accrued may be reduced at the
                                same rate at which it is accrued. Cumulative
                                Bonus is not allowed on benefit based policies.
                              </p>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Who can take the Policy?
                              </label>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>Individuals</li>
                                <li>
                                  Members of a family on a floater basis
                                  (spouse, two children below the age of 25
                                  years and parents)
                                </li>

                                <li>
                                  Groups: This policy can also be taken by a
                                  group owner provided who could be an employer,
                                  an association, a bank's credit card division,
                                  where a single policy covers the entire group
                                  of individuals.
                                </li>
                              </ol>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Age Limit:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The eligibility varies from insurer to insurer,
                                from as young as 3 months to 80 years and above.
                                Some companies have no limit for age at entry.
                                All companies have to provide lifelong renewals
                                as per IRDA Regulations.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 61/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Exclusions:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                1. Pre existing conditions/diseases that the
                                proposer is suffering from even prior to cover
                                being obtained for the first time—(but the
                                exclusion period not to exceed 48 months){" "}
                                <br></br>
                                2. 30 days waiting period in general for other
                                than accidents <br></br>
                                3. 24 months waiting period for certain specific
                                diseases like cataract, hernia, hydrocele,
                                piles, gout etc. <br></br>
                                4. War and allied perils, nuclear weapon
                                /ionizing radiation <br></br>
                                5. Plastic/cosmetic surgery unless medically
                                recommended <br></br>
                                6. Preventive measures like vaccination
                                inoculation <br></br>
                                7. Cost of spectacles, contact lens, hearing aid{" "}
                                <br></br>
                                8. Dental treatment unless requiring
                                hospitalization <br></br>
                                9. Congenital diseases, defects, anomalies{" "}
                                <br></br>
                                10. Psychiatric psychosomatic disorders{" "}
                                <br></br>
                                11. HIV/AIDS <br></br>
                                12. Participation in hazardous sports <br></br>
                                13. Hospitalization primarily for diagnostic
                                purposes, lab examinations, unrelated to any
                                illness disease requiring: hospitalization{" "}
                                <br></br>
                                14. Pregnancy child birth any complications
                                thereof <br></br>
                                15. Naturopathy treatment <br></br>
                                This list is only illustrative. Actual
                                exclusions may vary from product to product and
                                company to Company.
                              </p>{" "}
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Critical Illness Policy:
                              </label>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  The policy covers specified dreaded diseases
                                  like cancer, kidney failure, stroke, multiple
                                  sclerosis, heart valve replacement etc.
                                </li>
                                <li>
                                  The special feature being that the sum insured
                                  is fully, paid if during the currency of the
                                  policy insured suffers from one of the covered
                                  diseases and survives for 30 days after
                                  confirmation of existence of such disease,
                                  irrespective of actual amount spent on the
                                  treatment of the said disease
                                </li>
                                <li>
                                  This cover is also given as an add-on cover
                                  under hospitalization policy and is
                                  alsoavailable separately.{" "}
                                </li>
                                <li>
                                  It is a Benefit Policy and not an indemnity
                                  policy.{" "}
                                </li>
                                <li>
                                  The waiting period is 90 days from inception
                                  of policy for any benefit
                                </li>
                              </ol>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 62/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Cashless facility provided and smart card is
                                issued.
                              </label>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Portability:
                              </label>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  Portability means transfer of the credit
                                  gained by the insured for pre--existing
                                  conditions and time bound exclusions if the
                                  policy holder chooses to switch from one
                                  insurer to another or from one plan of the
                                  same insurer, provided the previous policy has
                                  been maintained without any break.
                                </li>
                                <li>
                                  A break in policy occurs when premium due on a
                                  given policy is not paid on or before the
                                  premium renewal date or within 30 days
                                  thereof.{" "}
                                </li>
                                <li>
                                  Portability ensures that when a policyholder
                                  changes his health insurance policy from one
                                  insurance company to another, he does not lose
                                  the benefits he has accumulated.{" "}
                                </li>
                              </ol>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Cashless Facility:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Insurance Companies have tie up arrangements
                                with network of hospitals. If a policyholder
                                takes treatment in any of these hospitals, there
                                is no need for the insured person to pay the
                                hospital hills. The insurance company through
                                its Third 'arty Administrator (TPA) will arrange
                                direct 'payment to the hospital.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Health insurance of life insurers: The product
                                is similar with regard to the cover, exclusions
                                and conditions of the Mediclaim Policy the only
                                exception being. The cash component is provided
                                for surgeries and illnesses basis.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Free Look Period
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                As per IRDAI Regulations issued in February
                                2013, all health insurance policies are equired
                                to have a Free Look Period or Cooling Period of
                                15 days from the date of receipt of policy
                                document.{" "}
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                During this period, if the policyholder has
                                bought a policy and disagrees to any terms and
                                conditions of the policy, he/she can return it
                                and get a refund subject to some deductions for
                                expenses.{" "}
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                7) HOME INSURANCE POLICY
                              </label>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>
                                  Home/householder's insurance policy provides
                                  coverage on losses incurred to the insured's
                                  property from hazards or events named in the
                                  policy. The perils covered will be clearly
                                  spelt out.
                                </li>
                                <li>
                                  Home/Householder's insurance covers the house
                                  structure and its contents against fire,
                                  riots, bursting of pipes, earthquakes etc.
                                  apart from the structure, it covers the
                                  contents excluding money against burglary,
                                  housebreaking, larceny and theft.{" "}
                                </li>
                                <li>
                                  Methods of fixing the Sum Insured, Market
                                  Value (MV) and Reinstatement Value (RIV).{" "}
                                </li>
                              </ol>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 63/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                8) Cattle / Live Stock
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The term 'Livestock' refers to the domesticated
                                animals raised in an agricultural setting to
                                produce commodities such as food, fibre and
                                labour. 'Livestock Insurance' .includes
                                insurance of cattle, poultry and fisheries etc
                                Insurance of animals covered on the similar
                                lines are cattle, sheep & goat, hen, rabbits,
                                camels, horses, elephants etc.{" "}
                              </p>
                              <p>
                                Cattle Insurance: The word "Cattle" refers to:
                              </p>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <li>
                                  Milch cows and buffaloes (2 to 12 years)
                                </li>
                                <li>Calves /Heifers </li>
                                <li>Calves /Heifers </li>
                                <li>
                                  Bullocks [castrated bulls) and castrated male
                                  buffaloes, whether indigenous, exotic or
                                  cross-bred{" "}
                                </li>
                              </ol>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Explanation:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                1. Exotic animal means an animal with both
                                parents of foreign breed. This includes animals
                                born in India as well as those born abroad.{" "}
                                <br></br>
                                2. Cross-bred animal means an animal with one
                                parent of foreign breed.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Coverage: Only Death of the animal is covered
                                due to Act of God (AOG) Perils (natural
                                calamities), Disease occurring during the Policy
                                period, Surgical operations & Riot and Strike.
                                Theft of the animal is not covered. There is a
                                waiting period of 15 days under the Cattle
                                Policy.
                              </p>{" "}
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Poultry Insurance:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                {" "}
                                <br></br>
                                Poultry means birds like chicken, hen etc. they
                                are categorized as [a] layers [b] broilers
                                [c]parent stock (hatchery). Under Poultry
                                insurance, 'Good Features' discount of 5% is
                                allowed or farms having good features
                                likehavingown veterinary officer, dead birth
                                disposal system and sophisticated equipments etc
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The insurance is on flock/lot basis and not
                                single bird basis. No identification of
                                individual bird is required.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Insurance is similar to cattle insurance i.e.
                                death is covered due to accident or diseases
                                only.
                              </p>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Exclusions include transport by any means,
                                natural mortality or unknown causes, improper
                                management or overcrowding.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                9) Agricultural Pump set Insurance:
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                The insurance is for centrifugal pump sets of
                                upto 25 HP (electrical or diesel) of approved
                                makes used for agricultural purpose. The policy
                                covers loss due to unforeseen and sudden
                                physical damage by the penis of: fire,
                                lightning, riot strike, malicious damage,
                                terrorism, mechanical or electrical breakdown,
                                burglary [by violent forcible entry and provided
                                the pump set is kept in a locked enclosure]
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 64/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                <li>Claim intimation letter to insurer </li>
                                <li>
                                  Repair estimate on bills (in case of damage){" "}
                                </li>
                                <li>
                                  Police Report (in case of burglary) & Survey
                                  Report
                                </li>
                              </ol>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                10) CROP INSURANCE
                              </label>
                              <p
                                style={{
                                  fontFamily: "Calibri",
                                  fontSize: "16px",
                                }}
                              >
                                Agriculture sector is demographically the most
                                significant and broadest economic sector in
                                India. Even a marginal dip in the agricultural
                                production has trickled down effect on the whole
                                economy. The variation in production is directly
                                affected by many unfavourable conditions such as
                                pest attacks, variations in weather conditions
                                such as rainfall, temperature, humidity, etc.
                                Thus, the need of the hour is to secure the
                                yield and yield-based losses.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Who can take this policy?
                              </label>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <li>Farmers </li>
                                <li>Banks</li>
                                <li>
                                  Financial Institutions / Companies extending
                                  credit facility for Agricultural Operations,
                                  whose repayments are affected by yield
                                  factors.
                                </li>
                              </ol>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                What is covered under this policy?
                              </label>{" "}
                              <br></br>
                              This policy covers
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <li>
                                  Any shortfall in yield resulting due to
                                  Natural Fire and lightning, Storm, Hailstorm,
                                  cyclone, Typhoon, Tempest, Hurricane, Tornado,
                                  Flood, Inundation, Landslide, Drought, Dry
                                  spells, Pests/ Diseases, etc.{" "}
                                </li>
                                <li>
                                  Any loss due to prevented sowing / planting
                                  risk due to deficit rainfall or adverse
                                  seasonal conditions .
                                </li>
                                <li>Any post harvest Losses </li>
                                <li>
                                  Premium chargeable depends on various factors
                                  such as the type of crop, location, historical
                                  yield data, calamity years in the specified
                                  area and the indemnity level of yield crop
                                </li>
                                <li>
                                  Claims are paid for loss of average yield due
                                  to the risks covered. Crop insurance does not
                                  cover fidelity guarantee.
                                </li>
                              </ol>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 65/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Pradhan Mantri Fasal Bima Yojana (PMFBY):
                              </label>
                              <br></br>
                              (Launched .13th January 2016)
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                i. This scheme is implemented in association
                                with respective State Governments. The scheme is
                                administered under the Ministry of Agriculture
                                and Farmers Welfare, Government of India.
                                <br></br>
                                ii. There is a uniform premium of only 2% to be
                                paid by <br></br>
                                iii. To stabiles the income of farmers to ensure
                                their continuous process in farming <br></br>
                                iv. To encourage farmers to adopt innovative and
                                modern agricultural practices <br></br>
                                v. To ensure flow of credit to the agriculture
                                sector. <br></br>
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Coverage of Risk:
                              </label>
                              <br></br>
                              <p>
                                Following states of the crop and risks leading
                                to crop loss are covered under the scheme.
                              </p>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <li>
                                  <b>Prevented Sowing / Planting Risk:</b>{" "}
                                  Insured area is prevented from sowing planting
                                  due to deficit rainfall or adverse seasonal
                                  conditions;{" "}
                                </li>
                                <li>
                                  <b>Standing Crop (Sowing to Harvesting):</b>{" "}
                                  Comprehensive risk insurance is provided to
                                  cover yield losses due to non-preventable
                                  risks, viz. drought, dry spells, flood,
                                  inundation, pests and diseases, landslides,
                                  natural fire and lightening, storm, hailstorm,
                                  cyclone, typhoon, tempest, hurricane and
                                  tornado. .
                                </li>
                              </ol>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <b> Post-Harvest Losses:</b> Coverage is
                                available only up to a maximum period of two
                                weeks from harvesting for those crops which are
                                allowed to dry in cut and spread condition in
                                the field after harvesting against specific
                                perils of cyclone and cyclonic rains and
                                unseasonal rains.
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <b>Localized Calamities:</b> Loss/damage
                                resulting from occurrence of identified
                                localized risks of hailstorm, landslide and
                                inundation affecting isolated farms in the
                                notified area.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Area Approach Basis:
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The scheme shall be implemented on an “Area
                                Approach Basis” (i.e. defined areas) for each
                                notified crop for widespread calamities. The
                                assumption that all the insured farmers, in a
                                Unit of Insurance, should be defined as
                                "Notified Area" for a crop, face similar risk
                                exposures, incur to a large extent, identical
                                cost of production per hectare, earn comparable
                                farm income per hectare, and experience similar
                                extent of crop loss due to the operation of an
                                insured peril, in the notified area. The unit of
                                insurance can be demographically mapped with
                                region having homogenous risk profile for the
                                notified crop.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 66/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                For risks of localized calamities and
                                post-harvest losses on account of defined peril,
                                the unit of insurance for loss assessment shall
                                be the affected insured field of the individual
                                farmer.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                11) Weather Based Crop Insurance Scheme (WBCIS):
                              </label>
                              <br></br>
                              <b>Who can take this policy? </b>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <li>Farmers </li>
                                <li>Banks </li>
                                <li>
                                  Financial institutions / companies extending
                                  credit facility for agricultural/ non
                                  agricultural seasonal operations, whose
                                  repayments are affected by weather conditions
                                </li>
                              </ol>
                              <br></br>
                              <b
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                {" "}
                                What is covered under this policy?{" "}
                              </b>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Policy covers
                              </label>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <li>
                                  Cost of input - Covers the diminished
                                  agricultural output/yield resulting due to
                                  deviation from optimum weather requirement of
                                  a crop within a specific geo graphical
                                  location and specified time period{" "}
                                </li>
                                <li>
                                  Increased operational costs of agricultural or
                                  non-agricultural economic activity resulting
                                  from deviation of Observed Weather index from
                                  Strike index{" "}
                                </li>
                                <li>
                                  Premium chargeable depends on various factors
                                  such as the type of crop, location, historical
                                  weather data, cost of cultivation in the
                                  specified area and the acreage under
                                  cultivation{" "}
                                </li>
                              </ol>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Documents required:
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                1. Land record document (for the standing crop
                                against which insurance is taken) <br></br>
                                2. Photo ID proof
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                12 ) Coconut Palm Insurance Scheme (CPIS):
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                1. In collaboration with Coconut Development
                                Board, a comprehensive coconut life insurance
                                product. <br></br>
                                2. Insurance based on Named perils leading to
                                death / permanent damage to coconut palm{" "}
                                <br></br>
                                3. Maximum liability is based on age of coconut
                                palm and discounted future value <br></br>
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                Coconut Palm Insurance Scheme is a mechanism for
                                providing, effective risk management aid to
                                those growers who are likely to be impacted by
                                non-preventable natural factors, pests &
                                diseases, etc.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 67/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Coverage:
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The insurance cover shall cover damage / losses
                                to coconut palm and / or nut yield arising out
                                of non-preventable natural factors.
                                <br></br>
                                <b
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Calibri",
                                  }}
                                >
                                  Insurance of Nut Yield:{" "}
                                </b>
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The insurance compensates the insured against
                                the likelihood of diminished nut output/ yield
                                resulting from non-preventable natural factors,
                                such as Natural Fire & Lightning, Storm,
                                Hailstorm, Cyclone, Typhoon, Tempest, Hurricane,
                                Tornado, Flood,{" "}
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Insurance of Coconut Palm
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The insurance compensates the insured in the
                                event of total loss of the individual palm/tree
                                in the insured plantation from the 4th year to
                                the 60th year. In the first year, a waiting
                                period of three months from the date of
                                transplanting shall apply. The insurance
                                coverage and claim assessment shall be on
                                individual tree basis. The insurance is against
                                perils like Storm, Hail storm, Cyclone, Typhoon,
                                Tornado, Heavy rains, Flood, Inundation, severe
                                Drought, Fire, Lightning.. Earthquake,
                                Landslide, Riot, Strike and Pests & Diseases of
                                widespread incidence.
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                Sum insured: For section-1 (nut yield), Sum
                                insured shall vary from Block to Block and is
                                determined by the Guaranteed Yield of the Block
                                multiplied by the previous year's average nut
                                price in the respective district.{" "}
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                For section — 2, Sum insured is based on the
                                average input cost of the plantation according
                                to the age of the plantation.
                              </p>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Premium:
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The premium is determined on the basis of
                                variability in the historical Block level nut
                                yield and age of the palm.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 68/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <h2
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  color: "#0093dd",
                                }}
                              >
                                MISCELLANEOUS TOPICS{" "}
                              </h2>{" "}
                              <br></br>
                              <p
                                style={{
                                  color: "gray",
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                  fontFamily: "Calibri",
                                }}
                              >
                                1) Grievance Redressal Mechanism .( Integrated
                                Grievance Redressal Mechanism) <br></br>
                                2) Consumers Redressal Agencies ( District ,
                                State, National Consumer Forums )<br></br>
                                3) Insurance Ombudsman. <br></br>
                                4) Protection of Policyholder’s Interest
                                Regulations. <br></br>
                                5) Anti Money Laundering (AML) norms. <br></br>
                                6) Know your customer (KYC) norms.{" "}
                              </p>{" "}
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  textAlign: "center",
                                  paddingLeft: "15rem",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                1) GRIEVANCE REDRESSAL{" "}
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <br></br>
                                The time for high priority action is when the
                                customer has a complaint.
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                A complaint is a crucial{" "}
                                <b>“moment of truth”</b> in the customer
                                relationship; if the company gets it right there
                                is potential to actually improve customer
                                loyalty. The human touch is critical in this;
                                customers want to feel valued. <br></br>
                                If you are a professional insurance advisor, you
                                would not allow such a situation to happen in
                                the first place. You would take the matter up
                                with the appropriate officer of the company.
                              </p>
                              <br></br>
                              <b
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                Remember, no one else in the company has
                                ownership of the client‘s problems as much as
                                you do.{" "}
                              </b>
                              <br></br>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                Complaints / grievances provide us the
                                opportunity to demonstrate how much we care for
                                the customer’s interests. They are in fact the
                                solid pillars on which an insurance agent’s
                                oodwill and business is built. At the end of
                                every policy document, the insurance companies
                                have detailed the procedure of grievance
                                redressal, which should be brought to the notice
                                of the customers at the time of explaining the
                                document provisions.
                              </p>
                              <br></br>
                              <b
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                Word of mouth publicity (Good/Bad) has
                                significant role in selling and servicing.{" "}
                              </b>
                              <br></br>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                Remember good service gets rewarded by 5 people
                                being informed, where as bad service is passed
                                on to 20 people.{" "}
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 69/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <h4
                                style={{
                                  alignText: "center",
                                  fontFamily: "ErasDemiITC",
                                  color: "gray",
                                }}
                              >
                                INTEGRATED GRIEVANCE MANAGEMENT SYSTEM (IGMS){" "}
                              </h4>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <br></br>
                                IRDAI‟s regulations stipulate the turnaround
                                times (TAT) for various services that an
                                insurance company has to render the consumer.
                                These are part of the IRDAI (Protection of
                                Policyholders‟ Interests Regulations), 2002.
                                Insurance companies are also required to have an
                                effective grievance redressal mechanism and
                                IRDAI has created the guidelines for that too.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  textAlign: "center",
                                  paddingLeft: "8rem",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                IRDI’s Integrated Grievance Management System
                                (IGMS):
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <br></br>
                                IRDAI has launched an Integrated Grievance
                                Management System (IGMS) which acts as a central
                                repository of insurance grievance data and as a
                                tool for monitoring grievance redress in the
                                industry.
                              </p>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  lineHeight: "2rem",
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <li>
                                  The System facilitates online registration of
                                  policyholders' complaints and helps track
                                  status of the complaint..{" "}
                                </li>
                                <li>
                                  The System facilitates online registration of
                                  policyholders' complaints and helps track
                                  status of the complaint..{" "}
                                </li>
                                <li>
                                  Complaints can be made only after having
                                  exhausted the complaint mechanism of the
                                  insurer.{" "}
                                </li>
                                <li>
                                  IRDAI follows the complaints with the
                                  Insurance Company, till the matter is resolved
                                  as per laws and rules.{" "}
                                </li>
                              </ol>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                Policyholders can register on this system with
                                their policy details and lodge their complaints.
                                Complaints are then forwarded to respective
                                insurance company. IGMS tracks complaints and
                                the time taken for redressal. The complaints an
                                be registered at:
                              </p>
                              <a href="http://www.policyholder.gov.in/Integrated_Grievance_Management.aspx">
                                http://www.policyholder.gov.in/Integrated_Grievance_Management.aspx
                              </a>
                              <br></br>
                              <div>
                                <br></br>
                                <h6
                                  style={{
                                    paddingLeft: "5rem",
                                    fontFamily: "ErasDemiITC",
                                  }}
                                >
                                  {" "}
                                  CONSUMER DISPUTES REDRESSAL AGENCIES
                                </h6>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Calibri",
                                  }}
                                >
                                  <br></br>
                                  Consumer disputes redressal agencies are
                                  established in each district and state and at
                                  national level. <br></br>
                                  <b>i. DISTRICT FORUM: </b> The forum has
                                  jurisdiction to entertain complaints, where
                                  value of the goods or services and the
                                  compensation claimed is up to Rs. 20 lakhs The
                                  District Forum is empowered to send its
                                  order/decree for execution to appropriate
                                  Civil Court. <br></br>
                                  <b> ii. STATE COMMISSION: </b>This redressal
                                  authority has original, appellate and
                                  supervisory jurisdiction. It entertains
                                  appeals from the District Forum. It also has
                                  original jurisdiction to entertain complaints
                                  where the value of goods/service and
                                  compensation, if any claimed exceeds Rs. 20
                                  lakhs but does not exceed Rs. 100 lakhs. Other
                                  powers and authority are similar to those of
                                  the District Forum. <br></br>
                                  <b> iii. NATIONAL COMMISSION:</b> The final
                                  authority established under the Act is the
                                  National Commission. It has original;
                                  appellate as well as supervisory jurisdiction.
                                  It can hear the appeals from the order passed
                                  by the State Commission and in its original
                                  jurisdiction it will entertain disputes, where
                                  goods/services and the compensation claimed
                                  exceeds Rs.100 lakhs. It has supervisory
                                  jurisdiction over State Commission.
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Calibri",
                                  }}
                                >
                                  All the three agencies have powers of a Civil
                                  Court.{" "}
                                  <b>The procedure for filing a complaint </b>
                                  for the three redressal agencies mentioned
                                  above is very simple. There is no fee for
                                  filing a complaint or filing an appeal whether
                                  before the State Commission or National
                                  Commission. The complaint can be filed by the
                                  complainant himself or by his authorised
                                  agent. It can be filed personally or can even
                                  be sent by post. It may be noted that no
                                  advocate is necessary for the purpose of
                                  filing a complaint.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 70/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiiTC",
                                }}
                              >
                                CONSUMER DISPUTES CATEGORIES
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <br></br>
                                The majority of consumer disputes with the three
                                forums fall in the following main categories, as
                                far as the insurance business is concerned
                              </p>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                i. Delay in settlement of claims <br></br>
                                ii. Non-settlement of claims <br></br>
                                iii. Repudiation of claims <br></br>
                                iv. Quantum of loss <br></br>
                                v. Policy terms, conditions etc.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiiTC",
                                  textAlign: "center",
                                  paddingLeft: "13rem",
                                }}
                              >
                                3) THE INSURANCE OMBUDSMAN
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <br></br>
                                The Central Government under the powers of the
                                Insurance Act, 1938 made Redressal of Public
                                Grievances Rules, 1998 by a notification
                                published in the official gazette on November
                                11, 1998. These rules apply to life and non-life
                                insurance, for all personal lines of insurances,
                                that is, insurances taken in an individual
                                capacity
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The objective of these rules is to resolve all
                                complaints relating to settlement of claim on
                                the part of the insurance companies in a cost
                                effective, efficient and impartial manner.
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <b>
                                  The Ombudsman, by mutual agreement of the
                                  insured and the insurer can act as a mediator
                                  and counsellor within the terms of reference.{" "}
                                </b>
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The decision of the Ombudsman, whether to accept
                                or reject the complaint,is final.{" "}
                              </p>

                              <br></br>

                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                (A) Complaint to the Ombudsman :
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                Any complaint made to the Ombudsman should be in
                                writing, signed by the insured or his legal
                                heirs addressed to an Ombudsman within whose
                                jurisdiction, the insurer has a branch/ office,
                                supported by documents, if any, along with an
                                estimate of the nature and extent of loss to the
                                complainant and the relief sought
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Complaints can be made to the Ombudsman if:
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                i. The complainant had made a previous written
                                representation to the insurance company and the
                                insurance company had: <br></br>
                                Rejected the complaint or <br></br>
                                The complainant had not received any reply
                                within one month after receipt of the complaint
                                by the insurer <br></br>
                                The complaint is not satisfied with the reply
                                the given by the insurer <br></br>
                                I. The complaint is made within one year from
                                the date of rejection by the insurance company{" "}
                                <br></br>
                                II. The complaint is not pending in any court or
                                consumer forum or in arbitration <br></br>
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 71/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                (B) Recommendations by the Ombudsman:
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                {" "}
                                <br></br>
                                There are certain duties/protocols that the
                                Ombudsman is expected to follow: <br></br>{" "}
                                Recommendations should be made within one month
                                of the receipt of such a complaint. The copies
                                should be sent to both the complainant and the
                                insurance company. <br></br>
                                Recommendations have to be accepted in writing
                                by the complainant within 15 days of receipt of
                                such recommendation. A copy of acceptance letter
                                _by the insured should he sent to the insurer
                                and his written confirmation sought within 15
                                days of his receiving such acceptance letter.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                (C) Awards by Ombudsman:
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                {" "}
                                <br></br>
                                If the dispute is not settled by intermediation,
                                the Ombudsman will pass an award to the insured
                                which he thinks is fair, and is not more than
                                what is necessary to cover the loss of the
                                insured.{" "}
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The awards by Ombudsman are governed by the
                                following rules
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The award should not be more than Rs.20 lakhs
                                (inclusive of ex-gratia payment and other
                                expenses)
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The award should be made within a period of 3
                                months from the date of receipt of such a
                                complaint, and the insured should acknowledge
                                the receipt of the award in full as a final
                                settlement within one month of the receipt of
                                such award
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The insurer shall comply with the award and send
                                a written intimation to the Ombudsman within 15
                                days of the receipt of such acceptance letter.
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                If the insured does not intimate in writing the
                                acceptance of such award, the insurer may not
                                implement the award.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiiTC",
                                  textAlign: "center",
                                  paddingLeft: "9rem",
                                }}
                              >
                                4) PROTECTION OF THE INTEREST OF POLICYHOLDER
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <br></br>
                                IRDA has the responsibility of protecting the
                                interest of insurance policyholders. Towards
                                achieving this objective, the Authority has
                                taken the following steps: <br></br>
                                IRDA has notified Protection of Policyholders
                                Interest Regulations 2001 to provide for:{" "}
                                <br></br>
                                1) Policy proposal documents in easily
                                understandable language <br></br>
                                2) Claims procedure in both life and non-life;{" "}
                                <br></br>
                                3) Setting up of grievance redressal machinery;{" "}
                                <br></br>
                                4) Speedy settlement of claims; <br></br>
                                5) Policyholders' servicing. <br></br>
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 72/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                6) The Regulation also provides for payment of
                                interest by insurers for the delay in settlement
                                of claim. <br></br>
                                7) Right to professional diligence. <br></br>
                                8) Right to protection against unfair contract
                                terms. <br></br>
                                9) Right to protection against unfair market
                                conduct. <br></br>
                                10) Right to receive suitable advices <br></br>
                                11) Protection from conflict of interest of
                                advices. <br></br>
                                There are certain obligations/responsibilities
                                on the part of policy holder too.
                              </p>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <li>
                                  The insurers are required to maintain solvency
                                  margins so that they are in a position to meet
                                  their obligations towards policyholders with
                                  regard to payment of claims
                                </li>
                                <li>
                                  It is obligatory on the part of the insurance
                                  companies to disclose clearly the benefits,
                                  terms and conditions under the policy. The
                                  advertisements issued by the insurers should
                                  not mislead the insuring public.
                                </li>
                                <li>
                                  All insurers are required to set up proper
                                  grievance redress machinery in their head
                                  office and at their other offices.{" "}
                                </li>
                                <li>
                                  The Authority takes up with the insurers any
                                  complaint received from the policyholders in
                                  connection with services provided by them
                                  under the insurance contract.
                                </li>
                              </ol>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The Authority is sensitive to the fact that
                                information asymmetry is a reality in the market
                                and that it is necessary for all stakeholders to
                                work towards its removal. In this backdrop, the
                                importance of giving policyholders clear and
                                precise information with regard to insurance
                                products needs no further emphasis. The
                                Authority has brought out the IRDA (Insurance
                                Advertisement and Disclosure) Regulations in
                                2000 to ensure that asymmetry is addressed. It
                                has been IRDA's constant endeavour to permit
                                only correct, easily understood depiction of the
                                product features. Apart from the Regulations,
                                IRDA has also issued guidelines in this regard.
                              </p>
                              <br></br>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiiTC",
                                  textAlign: "center",
                                  paddingLeft: "10rem",
                                }}
                              >
                                5) ANTI MONEY LAUNDERING NORMS & KYC
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <br></br>
                                Money laundering is the process of bringing
                                illegal money into an economy by hiding its
                                illegal origin so that it appears to be legally
                                acquired. The Government of India launched the
                                PMLA. 2002 to rein in money-laundering
                                activities.
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The Prevention of Money Laundering Act (PMLA),
                                2002 came into effect from 2005 to control money
                                laundering activities and lo provide for
                                confiscation of property derived from money-
                                aundering. It mentions money laundering as an
                                offense which is punishable by rigorous
                                imprisonment from three to seven years and fine
                                upto Rs. 5 lakhs.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 73/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                Each insurer is required to have an AML policy
                                and accordingly file a copy with IRDAI. The AML
                                program should include: <br></br>
                                1. Internal policies, procedures and controls{" "}
                                <br></br>
                                2. Appointment of a principal compliance officer{" "}
                                <br></br>
                                3. Recruitment and training of agents on AM L.
                                measures <br></br>
                                4. Internal audit/control
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                What is Money Laundering and Financial
                                Terrorism? Money laundering involves disguising
                                financial assets so that they can be used
                                without detection of the illegal activity that
                                produced them. Through money laundering, the
                                launderer transforms the monetary proceeds
                                derived from criminal activity into funds with
                                an apparently legal source. Financial Terrorism
                                means financial support to, in any form of
                                terrorism or to those who encourage, plan or
                                engage in terrorism. Money launderers send
                                illicit funds through legal channels in order to
                                conceal their criminal origin while those who
                                finance terrorism transfer funds that may be
                                legal or illicit in original in such a way as to
                                conceal their source and ultimate use, which is
                                to support Financial Terrorism.
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <b>
                                  {" "}
                                  Application of AML guidelines to existing
                                  customers:{" "}
                                </b>
                                In view of the practical hardships on account of
                                retrospective application of AML guidelines from
                                1st April 2004, and in order to reduce the
                                burden for carrying out KYC exercise for the
                                very large number of existing customers,
                                compliance of KYC norms on existing customers
                                should be carried out by you with effect from
                                1st January 2006. The compliance requirements
                                are also further limited to the policies coming
                                into force on or after <b>
                                  1st January 2006{" "}
                                </b>{" "}
                                and those covered under detailed due diligence
                                procedures vide para 3.1.1 (ii), 3.1.3 and 3.1.5
                                of the circular. The AML requirements may not be
                                applied to the{" "}
                                <b>remaining existing customers,</b> i.e., those
                                below the threshold premium of Rs.1 lakh per
                                annum.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiiTC",
                                  textAlign: "center",
                                  paddingLeft: "15rem",
                                }}
                              >
                                6) Know Your Customer (KYC)
                              </label>
                              <p>
                                <br></br>
                                Know your customer is the process used by a
                                business to verify the identity of their
                                clients. Banks and insurer are increasingly
                                demanding their customer’s provide details
                                information to prevent identity theft, financial
                                fraud and money laundering.{" "}
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                The objective of KYC- :guidelines is to prevent
                                financial institutions from being used by
                                criminal elements for money laundering
                                activities.
                              </p>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 74/75</p>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className="Scroll-bar" data-interval="false">
                            <br></br>
                            <br></br>
                            <div
                              style={{
                                paddingLeft: "4rem",
                                paddingRight: "3rem",
                              }}
                            >
                              <br></br>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                Insurers, hence, need to determine the true
                                identity of their customers. Agents should
                                ensure that proposers submit the proposal form
                                along with the following as part of the KYC
                                procedure: <br></br>
                                I. Photographs <br></br>
                                II. Age proof <br></br>
                                III. Proof of address–driving license, passport,
                                telephone bill, electricity bill, bank passbook
                                etc. <br></br>
                                IV. Proof of identity–driving license, passport,
                                and voter ID card, PAN card, etc. <br></br>
                                V. Income proof documents in case of high-value
                                transactions <br></br>
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                KYC involves making reasonable efforts to
                                determine true identity and beneficial ownership
                                of accounts, source of funds, the nature of
                                customer’s business, reasonableness of
                                operations in the account in relation to the
                                customer’s business, etc which in turn helps the
                                banks to manage their risks prudently. The
                                objective of the KYC guidelines is to prevent
                                banks being used, intentionally or
                                unintentionally by criminal elements for money
                                laundering.
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Illustrative list of Suspicious Transactions:
                              </label>
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <br></br>
                                1. Customer insisting on anonymity, reluctance
                                to provide identifying information, or providing
                                minimal., seemingly fictitious information;{" "}
                                <br></br>
                                2. Cash based suspicious transactions for
                                payment of premium and top ups over and above
                                Rs.5 lath per person per month; <br></br>
                                3. Frequent free look cancellations by
                                customers; <br></br>
                                4. Assignments to unrelated parties without
                                valid consideration <br></br>
                                5. Request for purchase of a policy in amount
                                considered beyond apparent need <br></br>
                                6. Policy from a place where customer does not
                                reside or is not employed <br></br>
                                7. Unusual terminating of policies and refunds;{" "}
                                <br></br>
                                8. Frequent request for change in address;{" "}
                                <br></br>
                                9. Borrowing the maximum amount against a policy
                                soon after buying it; <br></br>
                                10. Inflated or totally fraudulent claims e.g.,
                                by arson or other means ca using fraudulent
                                claim to be made to recover part of the invested
                                illegitimate funds. <br></br>
                                11. Overpayment of premiums with a request for a
                                refund of the amount overpaid. <br></br>
                                12. Multiple DO each of denomination for less
                                than Rs.50,000/- for payment of premium and top
                                ups over and above Rs.2 lakhs per person per
                                month. <br></br>
                                13. Media reports about a customer <br></br>
                                14. Information sought by Enforcement agencies;
                              </p>
                              <br></br>
                              <label
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "ErasDemiITC",
                                }}
                              >
                                Vulnerable Features / Products:
                              </label>
                              <ol
                                style={{
                                  listStyleType: "disc",
                                  fontSize: "16px",
                                  fontFamily: "Calibri",
                                }}
                              >
                                <li>
                                  Single premium products-where the money is
                                  invested in lump sum and surrendered at the
                                  earliest opportunity;
                                </li>
                                <li>
                                  Free look cancellations - especially the big
                                  ticket cases;
                                </li>
                              </ol>
                            </div>
                          </div>
                          <div className="Page-content">
                            <p>PAGE 75/75</p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          className="section-examination"
                          style={{ height: "80vh" }}
                        >
                          <br></br> <br></br>
                          <div
                            style={{
                              paddingLeft: "1rem",
                              paddingRight: "3rem",
                              marginTop: "4rem",
                            }}
                          >
                            <h2
                              style={{
                                fontFamily: "ErasDemiITC",
                                color: "#30a7e4",
                                textAlign: "center",
                              }}
                            >
                              CONGRATULATIONS!
                            </h2>
                            <h4
                              style={{
                                fontFamily: "Calibri",
                                textAlign: "center",
                              }}
                            >
                              <br></br>
                              You've successfully completed your training.{" "}
                              <br></br>
                              Please take your exam to download your certificate
                            </h4>
                            <br></br> <br></br>
                            <div style={{ textAlign: "center" }}>
                              <h2
                                style={{
                                  fontFamily: "ErasDemiITC",
                                  color: "#30a7e4",
                                  textAlign: "center",
                                }}
                              >
                                Training has been Completed!
                              </h2>
                              {/* <button onClick={()=> { history.push("/MyExamination");}}   className="exm-btn">Take Exam</button> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {/* {!isFinish&&<a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" onClick={prevButtonClick}>
  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  <span className="sr-only">Previous</span>
</a>
}
{page==75?null:!isFinish&&<a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" onClick={nextButtonClick}>
  <span className="carousel-control-next-icon" aria-hidden="true"></span>
  <span className="sr-only">Next</span>
</a>} */}
                </div>

                <div className="topic-section__timer">
                  <div className="timer-zoom">
                    <div
                      style={{ position: "absolute", top: "0", right: "5%" }}
                    >
                      {/* <h3>Time Left : <span><Countdown   date={timer} renderer={renderer} /></span></h3> */}
                      {/* <h3>Time Left : <span>15:00:00</span></h3> */}
                      {/* {!isFinish && <MyTimer expiryTimestamp={time} onChange={handleChange} />} */}
                      {!isCompleted && (
                        <span>
                          <MyTimer
                            expiryTimestamp={time}
                            onChange={handleChange}
                          />
                        </span>
                      )}
                      {/* <a className="right carousel-control"  role="button" >
                <button className="btn-trining  pause-btn" style={{color:"white", fontFamily:"ErasDemiITC" }} onClick={pauseButtonClick}>Pause</button> </a>
              */}
                    </div>
                    {!isFinish && (
                      <div
                        style={{
                          position: "absolute",
                          bottom: "4%",
                          right: "5%",
                          zIndex: "1",
                        }}
                      >
                        {page != 1 ? (
                          <a
                            className="left carousel-control"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="prev"
                          >
                            <button
                              className="btn-trining previous-btn"
                              style={{
                                color: "white",
                                fontFamily: "ErasDemiITC",
                                marginBottom: 20,
                              }}
                              onClick={prevButtonClick}
                            >
                              PREVIOUS
                            </button>
                          </a>
                        ) : (
                          <button
                            className="btn-trining previous-btn"
                            style={{
                              color: "white",
                              fontFamily: "ErasDemiITC",
                              marginBottom: 20,
                              background: "#d3d3d3",
                            }}
                          >
                            PREVIOUS
                          </button>
                        )}

                        {page == 75 ? (
                          <div></div>
                        ) : (
                          <a
                            className="right carousel-control"
                            href="#carouselExampleControls"
                            role="button"
                            data-slide="next"
                          >
                            <button
                              className="btn-trining  next-btn"
                              style={{
                                color: "white",
                                fontFamily: "ErasDemiITC",
                              }}
                              onClick={nextButtonClick}
                            >
                              {"NEXT"}
                            </button>{" "}
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
export default MyTraining;

{
  /* <button className="btn-trining  finish-btn" style={{color:"white", fontFamily:"ErasDemiITC" }} onClick={finishButtonClick}>{"FINISH"}</button> </a> */
}
