import axios from "axios"
import Constant from "../../utils/constant"

export const updateTextDetails = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updateTextDetails',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}