import React, { useState, useEffect } from "react";
import Footer from "../ReusableComponents/Footer";
import NavBar from "../ReusableComponents/NavBar";
import OtpInput from "react-otp-input";
// Styling
import "./RegistrationPage.css";
// React Bootstrap Breadcrumb
import { Breadcrumb } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import RegistrationLeft from "../RegistrationPos/RegistrationLeft";
import { OTP, resendOTP } from "../../store/actions/auth";

import { useDispatch, useSelector } from "react-redux";
const OtpVarification = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const History = useHistory();
  const User = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const location = useLocation();
  const [isHide, setHide] = useState(true);

  useEffect(() => {
    console.log(location.state, "location");
    setHide(false);
    setTimeout(() => setHide(true), 30000);
  }, {});
  const ProfileDetail = () => {
    History.push("/profile_detail");
    window.location.reload();
  };

  // midutexo@musiccode.me
  let handleChange = (otp) => {
    setOtp(otp);
  };
  const sendOTP = (e) => {
    e.preventDefault();
    const phone = User.mobileNo;
    const email = User.emailId;
    // console.log(phone, email, "========>");
    localStorage.getItem("user");
    if (otp.length == 4) {
      setLoading(true);
      dispatch(OTP(phone, email, otp)).then((res) => {
        if (res !== 0) {
          ProfileDetail();
          setLoading(false);
          setOtp("");
        } else {
          setLoading(false);
          setOtp("");
        }
      });
    }
  };

  const resendOtp = (e) => {
    setOtp("");
    setHide(false);
    setTimeout(() => setHide(true), 30000);
    e.preventDefault();
    const phone = User.mobileNo;
    const email = User.emailId;
    const firstName = User.firstName;
    dispatch(resendOTP(firstName, phone, email));
  };
  return (
    <div className="main-div">
      <NavBar />
      {/* Breadcrumb Start */}
      <section className="Breadcrumb__Section">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/">Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Become POS</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      {/* Breadcrumb End */}

      {/* Register Area Start */}
      <section className="Registration__Section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-12">
              <RegistrationLeft />
            </div>
            <div className="col-lg-5 col-12" style={{ padding: "3%" }}>
              <div
                className="bg-light p-5 shadow-lg"
                style={{
                  height: "auto",
                  boxShadow: "0 1rem 3rem rgba(0,0,0,0.1)!importan",
                }}
              >
                <div className="form-row form-run ">
                  <i
                    className="bi bi-arrow-left"
                    onClick={() =>
                      History.push("/registration_pos", location.state)
                    }
                    style={{ paddingTop: "-5rem" }}
                  ></i>
                  <h3
                    className="text-center Register__Pos__Heading"
                    style={{
                      fontSize: "25px",
                      paddingLeft: "2.7rem",
                      position: "flex",
                    }}
                  >
                    {" "}
                    OTP Verification
                  </h3>
                </div>
                <p className="text-center otp-text my-5">
                  Please enter the 4 digit code sent to{" "}
                  <span style={{ textTransform: "lowercase" }}>
                    {User?.emailId}
                  </span>
                </p>
                <form className="mt-4">
                  <div className="car2-form-groupp w-100">
                    <div className="car3-form-groupp mt-3 mb-2 w-100">
                      <div className="form-row">
                        <OtpInput
                          value={otp}
                          onChange={handleChange}
                          numInputs={4}
                          isInputNum={true}
                          inputStyle={"inputStyle"}
                          containerStyle={"containerStyle"}
                          separator={<span> </span>}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={loading}
                    className="btn-primary Continue__Button mt-3 btn-block btn_verify_continue"
                    onClick={sendOTP}
                  >
                    Verify
                  </button>
                  {isHide && (
                    <div className="mt-4 text-center">
                      <Link onClick={resendOtp} to="#">
                        Resend OTP
                      </Link>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Register Area End */}

      <Footer />
    </div>
  );
};

export default OtpVarification;
