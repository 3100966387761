import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_PINCODE_FAILURE, FETCH_PINCODE_REQUEST, FETCH_PINCODE_SUCCESS } from "./types"



export const fetchPinCodeRequest = () => {
    return {
        
        type: FETCH_PINCODE_REQUEST
    }
}
export const fetchPinCodeSuccess = model => {
    return {
        type: FETCH_PINCODE_SUCCESS,
        payload: model
    }
}
export const fetchPinCodeFailure = error => {
    return {
        type: FETCH_PINCODE_FAILURE,
        payload: error
    }
}


export const getPinCode = (Id) => async (dispatch) => {
    dispatch(fetchPinCodeRequest)
    await axios.post(Constant.BASE_URL + 'pos/listPincode', {
        cityName: Id
    })
        .then(response => {
            const result = response.data
            dispatch(fetchPinCodeSuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchPinCodeFailure(errorMsg))
        })
}