import axios from "axios"
import Constant from "../../utils/constant"
import { FETCH_CITY_FAILURE, FETCH_CITY_REQUEST, FETCH_CITY_SUCCESS } from "./types"



export const fetchCityRequest = () => {
    return {
        type: FETCH_CITY_REQUEST
    }
}
export const fetchCitySuccess = model => {
    return {
        type: FETCH_CITY_SUCCESS,
        payload: model
    }
}
export const fetchCityFailure = error => {
    return {
        type: FETCH_CITY_FAILURE,
        payload: error
    }
}


export const getCity = (Id) => async (dispatch) => {
    dispatch(fetchCityRequest)
    await axios.post(Constant.BASE_URL + 'pos/listCities', {
        stateName: Id
    })
        .then(response => {
            const result = response.data
            dispatch(fetchCitySuccess(result))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(fetchCityFailure(errorMsg))
        })
}