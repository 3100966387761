
import React, { useEffect, useState } from "react";
import { useTimer } from 'react-timer-hook';
// import axios from 'axios';
// import Constant from "../../../utils/constant";
import { trainingStart,traningCompl,isTrainingCompleted,traningPause } from '../../../store/actions/getPosPersonalDetails'
// const User = JSON.parse(localStorage.getItem("user"));

export function MyTimer({ expiryTimestamp,onChange }) {

  window.addEventListener('beforeunload', (event) => {
    // Cancel the event as stated by the standard.
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = '';
  
    this.props.pauseButtonClick();
  });

    const [finish,setFinish]=useState(false)
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => {
    // setFinish(true)
    localStorage.setItem('isFin', true);
    onChange(true)
    // call the finish API
    // completeTraining().then();
    traningCompl()
  } });

  useEffect(() => {
    window.addEventListener('beforeunload', handleTabClosing)
    window.addEventListener('unload', handleTabClosing)
    return () => {
        window.removeEventListener('beforeunload', handleTabClosing)
        window.removeEventListener('unload', handleTabClosing)
    }
})

const handleTabClosing = () => {
  pauseButtonClick()
}

const alertUser = (event) => {
    event.preventDefault()
    event.returnValue = ''
}
  

  const pauseButtonClick = () =>{
    
   var json =  {
    // "user_id":42,
    // "training_starttime":"2022-04-22 16:14:01",
    // "training_endtime": "2022-04-22 26:14:01",
      "training_duration":hours*60*60+minutes*60+seconds
      }
    // alert(JSON.stringify(json));  
    traningPause(json).then(res => {
      pause()
      // alert("//*"+JSON.stringify(res))
     
     })
     .catch( (error)=> {
      
      alert('err',error.message);  
     });


    // setFinish(true)
    // localStorage.setItem('isFin', true);
  
  };
  return (
    <div style={{textAlign: 'center',display:"flex",justifyContent:"space-between",alignItems:"center",height:"120px",width:"600px"}}>
      
      {/* <div style={{fontSize: '20px'}}> */}
        <span>{`Time Left :${hours}|${minutes}|${seconds}`}</span>
      {/* </div> */}
      <p>{isRunning ? 'Running' : 'Not running'}</p> 
      
      {/* <button onClick={start}>Start</button> */}
      <span>
      <button onClick={pauseButtonClick}>Pause</button>{"   "}
      <button onClick={resume}>Resume</button>
      </span>
      {/* <button onClick={() => {
        // Restarts to 5 minutes timer
        const time = new Date();
        time.setSeconds(time.getSeconds() + 300);
        restart(time)
      }}>Restart</button> */}
    </div>
  );
  
}

