import { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Constant from "../../../utils/constant";
import axios from "axios";
import Countdown from "react-countdown";

const ForgetPassword = ({ setEmailSec, setForgetPassSec }) => {
  const { BASE_URL } = Constant;
  const [forgetPasswordScreen, setForgetPasswordScreen] = useState(true);
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [createNewPasswordScreen, setCreateNewPasswordScreen] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [otp, setOTP] = useState("");
  const [token, setToken] = useState("");
  const [otpError, setOtpError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  var strongPasswordRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  const sendOTP = () => {
    resendOTP && setResendOTP(false);
    axios
      .post(`${BASE_URL}/pos/forgotPassword`, {
        emailId: email,
      })
      .then(({ data }) => {
        if (data.status === 0) {
          setEmailError(data.message);
          setLoading(false);
        } else {
          setResendOTP(false);
          setForgetPasswordScreen(false);
          setVerifyOTP(true);
          setCreateNewPasswordScreen(false);
          setSuccessScreen(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const renderer = ({ minutes, seconds }) => {
    return (
      <span style={{ width: "auto" }}>
        OTP will expire in{" "}
        <span className="text-primary">
          {minutes < 10 ? "0" + minutes : minutes}:
          {seconds < 10 ? "0" + seconds : seconds}
        </span>
      </span>
    );
  };

  const styles = {
    marginBottom: "3rem !important",
    position: "relative",
    width: "270px",
    height: "60px",
  };

  const reset = () => {
    setToken("");
    setEmail("");
    setOTP("");
    setEmailError("");
    setOtpError("");
    setNewPasswordError("");
  };

  const resetErrors = () => {
    setEmailError("");
    setOtpError("");
    setNewPasswordError("");
  };

  const handleClick = () => {
    setLoading(true);
    resetErrors();
    if (forgetPasswordScreen) {
      if (email === "") {
        setEmailError("Please enter a  email");
        setLoading(false);
        return;
      }
      sendOTP();
    }
    if (verifyOTP) {
      axios
        .post(`${BASE_URL}/pos/passwordverifyotp`, {
          emailId: email,
          otp: otp,
        })
        .then(({ data }) => {
          if (data.status === 0) {
            setOtpError(data.message);
            setLoading(false);
          } else {
            setToken(data.data.token);
            setForgetPasswordScreen(false);
            setVerifyOTP(false);
            setCreateNewPasswordScreen(true);
            setSuccessScreen(false);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
    if (createNewPasswordScreen) {
      if (newPassword === "") {
        setNewPasswordError("Plese Enter New Password");
        setLoading(false);
        return;
      }

      if (confirmNewPassword === "") {
        setNewPasswordError("Plese Enter Confirm Password");
        setLoading(false);
        return;
      }

      if (newPassword != confirmNewPassword) {
        setNewPasswordError("Password do not match.");
        setLoading(false);
        return;
      }

      if (!strongPasswordRegex.test(newPassword)) {
        setNewPasswordError(
          "Password must be eight characters long, at least one uppercase one lowercase and one special character."
        );
        setLoading(false);
        return;
      }

      axios
        .post(
          `${BASE_URL}/pos/resetPassword`,
          {
            emailId: email,
            password: newPassword,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(({ data }) => {
          console.log(data);
          if (data.status === 0) {
            setOtpError(data.message);
            setLoading(false);
          } else {
            reset();
            setForgetPasswordScreen(false);
            setVerifyOTP(false);
            setCreateNewPasswordScreen(false);
            setSuccessScreen(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  };

  const Button = () => {
    return loading ? (
      <span className="spinner-border spinner-border-md"></span>
    ) : !resendOTP ? (
      <button className="login-btn" onClick={handleClick}>
        {forgetPasswordScreen && "Send OTP on E-Mail"}
        {verifyOTP && "Verify OTP"}
        {createNewPasswordScreen && "Save"}
      </button>
    ) : (
      <button className="login-btn" onClick={() => sendOTP()}>
        Resend OTP
      </button>
    );
  };

  return (
    <>
      {/* Email Enter Screen */}
      {forgetPasswordScreen && (
        <>
          <div style={styles}>
            <input
              type="text"
              className="car2-form-contl"
              style={{ width: "auto" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label
              className="ms-3 car2-form-control-placeholderr fs-6 mt-2 text-secondary"
              for="icueRegistrationNo1"
            >
              Email
              <br />
            </label>
          </div>
        </>
      )}
      {/* Email enter Screen End */}

      {/* Enter OTP Screen */}
      {verifyOTP && (
        <>
          <div style={styles}>
            <input
              type="number"
              className="car2-form-contl"
              style={{ width: "auto" }}
              value={otp}
              onChange={(e) => setOTP(e.target.value)}
              required
            />
            <label
              className="ms-3 car2-form-control-placeholderr fs-6 mt-2 text-secondary"
              for="icueRegistrationNo1"
            >
              Enter OTP
              <br />
            </label>
          </div>
          {!resendOTP ? (
            <Countdown
              date={Date.now() + 300000}
              renderer={renderer}
              onComplete={() => setResendOTP(true)}
            />
          ) : (
            <span className="text-danger">
              OTP expired. CLick below to re-send OTP.
            </span>
          )}
        </>
      )}
      {/* Enter OTP Screen End */}

      {/* Create New Password Screen */}
      {createNewPasswordScreen && (
        <>
          <div style={styles}>
            <input
              type="password"
              className="car2-form-contl"
              style={{ width: "auto" }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <label
              className="ms-3 car2-form-control-placeholderr fs-6 mt-2 text-secondary"
              for="icueRegistrationNo1"
            >
              New Password
              <br />
            </label>
          </div>

          <div style={{ marginTop: "30px" }}>
            <div style={styles}>
              <input
                type="password"
                className="car2-form-contl"
                style={{ width: "auto" }}
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
              <label
                className="ms-3 car2-form-control-placeholderr fs-6 mt-2 text-secondary"
                for="icueRegistrationNo1"
              >
                Confirm New Password
                <br />
              </label>
            </div>
          </div>
        </>
      )}
      {/* Create New Password Screen End */}

      {/* Success Screen Start */}
      {successScreen && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              padding: "2rem",
            }}
          >
            <CheckCircleOutlineIcon
              style={{ transform: "scale(3)", color: "#28a745" }}
            />
            <span style={{ marginTop: "30px" }}>
              Password Changed Successfully.
            </span>
            <a
              onClick={() => {
                setEmailSec(true);
                setForgetPassSec(false);
              }}
              className="postext"
              style={{ cursor: "pointer" }}
              href="#!"
            >
              Click Here to Login
            </a>
          </div>
        </>
      )}
      {/* Success Screen End */}

      {emailError != "" && (
        <span
          className="text-danger"
          style={{
            fontSize: "0.9rem",
            marginLeft: "5px",
            fontWeight: "lighter",
          }}
        >
          {emailError}
        </span>
      )}

      {otpError !== "" && (
        <span
          className="text-danger"
          style={{
            fontWeight: "lighter",
            fontSize: "0.9rem",
            marginLeft: "5px",
          }}
        >
          {otpError}
        </span>
      )}

      {newPasswordError !== "" && (
        <span
          className="text-danger"
          style={{
            fontWeight: "lighter",
            fontSize: "0.9rem",
            marginLeft: "5px",
          }}
        >
          {newPasswordError}
        </span>
      )}

      {!successScreen && <Button />}
    </>
  );
};

export default ForgetPassword;
