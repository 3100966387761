import axios from "axios"
import Constant from "../../utils/constant"
import { IMAGE_FAILURE, IMAGE_REQUEST, IMAGE_SUCCESS } from "./types"



export const uploadImageRequest = () => {
    return {
        type: IMAGE_REQUEST
    }
}
export const uploadImageSuccess = model => {
    return {
        type: IMAGE_SUCCESS,
        payload: model
    }
}
export const uploadImageFailure = error => {
    return {
        type: IMAGE_FAILURE,
        payload: error
    }
}
export const updateBankCopy = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updateBankCopy',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const updateEducationCert = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updateEducationCert',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const updatePan = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updatePancardImage',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}

export const updateAdhaarImagesFront = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updateAdhaarImagesFront',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const updateAdhaarImagesBack = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updateAdhaarImagesBack',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export const uploadProfile = (photo) => {
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/updatePhoto',photo)
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}