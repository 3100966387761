// import logo from './logo.svg';
import './App.css';
import AppRoutes from './components/Routes/AppRoutes';
// import Pass from './components/Create-Password/Pass';

function App() {
  return (
    <div className="Appp">
     <AppRoutes/>
     {/* <Pass/> */}
    </div>
  );
}

export default App;
