import React, { useState ,useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { getState } from '../../store/actions/listStates'

import { getCity } from '../../store/actions/city'
import { getPinCode } from '../../store/actions/pinCode'
import DropDownSelect from './DropDown'

// Styling
import "./ProfileDetail.css"

const Form2 = ({
  personalchk,
  uploadSingleFile,
  deleteFile,
  cnicFile,
  accountFile,
  
  formErrors,
  personalDetail,
  errors,selectState,setSelectState,
  selectCity,setSelectCity,
  selectPinCode,setSelectPinCode
}) => {
   const listState = useSelector(state => state.posState.data.data);
   const listCity = useSelector(state => state.posCity.data.data);
  const listPinCode = useSelector(state => state.posPinCode.data.data);
  //  const listCity = [];
  //  const listPinCode = [];
  const [errPin, seterrPin] = useState(false)
  
    const dispatch = useDispatch();
      
    useEffect(() => {
      if (selectPinCode != "") {
        seterrPin(true)
        delete formErrors.pincode;
      } 
  }, [selectState,selectCity,selectPinCode]);
  useEffect(() => {
    dispatch(getState())
}, [])

   const setState = (id) => {
        setSelectState(id)
        dispatch(getCity(id))
    }
   const setCity = (id) => {
        setSelectCity(id)
        dispatch(getPinCode(id))
    }
   const setPinCode = (id) => {
    setSelectPinCode(id)

    }
  return (
    <form className='pl-2'>
      <div className="form-row mt-3">
        <div className="col-md-12 col-lg-4 p-3">
          <div className="profile-form-groupp w-100">
            <div className="car3-form-groupp w-100">
              <textarea
                className="profile-form-controll w-100 pt-m"
                id="address1"
                type="text"
                name="address1"
                rows="5"
                onChange={(e) => personalchk('addressLine1', e)}
								value={personalDetail.addressLine1}
              />
              <label className="ms-3 profile-form-control-placeholderr" for="address1">
                Address Line 1*     
              </label>
            </div>
            <p className='p-error'>{errors.addressLine1||formErrors.addressLine1}</p>
          </div>
        </div>
        <div className="col-md-12 col-lg-4 p-3 ">
          <div className="profile-form-groupp w-100">
            <div className="car3-form-groupp w-100">
              <textarea
                className="profile-form-controll w-100 pt-m"
                id="address2"
                type="text"
                name="address2"
                rows="5"
                onChange={(e) => personalchk('addressLine2', e)}
								value={personalDetail.addressLine2}
              />
              <label className="ms-3 profile-form-control-placeholderr" for="address1">
                Address Line 2
              </label>
            </div>
            <p className='p-error'>{errors.addressLine2}</p>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-12 col-lg-4 p-3 ">
          <div className="profile-form-groupp w-100">
            <div className="car3-form-groupp w-100">
              <input
                className="profile-form-controll w-100 wd"
                id="work"
                type="text"
                name="work"
                onChange={(e) => personalchk('landmark', e)}
								value={personalDetail.landmark}
              />
              <label className="ms-3 profile-form-control-placeholderr" for="work">
               Landmark
              </label>
            </div>
            <p className='p-error'>{errors.landmark}</p>
          </div>
        </div>
        <div className="col-md-12 col-lg-4 p-3 mrr">
          <DropDownSelect
          value={selectState}
          onChange={(e) => setState(e)}
          data={listState}
          type={'state'}
          placeholder="Select State"
          />
           {selectState=='' &&   <p className='p-error'>{errors.state||formErrors.state}</p>}
        </div>
      </div>
      <div className="form-row">
      <div className="col-md-12 col-lg-4 p-3">
          <DropDownSelect
          value={selectCity}
          onChange={(e) => setCity(e)}
          data={listCity}
          type={'city'}
          placeholder="Select City"
          />
           {selectCity=='' &&  <p className='p-error'>{errors.city||formErrors.city}</p>}
        </div>
      
        <div className="col-md-12 col-lg-4 p-3 mrr">
        <DropDownSelect
          value={selectPinCode}
          onChange={(e) => setPinCode(e)}
          data={listPinCode}
          type={'pincode'}
          placeholder="Select Pincode"
          />
             {selectPinCode=='' &&  <p className='p-error'>{formErrors.pincode}</p>}
        </div>
      </div>
    </form>
  )
}

export default Form2
