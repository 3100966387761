import { combineReducers } from 'redux';
import {
    carMakeModalVariant,
    carMakeReducer,
    bikeMakeReducer,
    carModelsReducer,
    carPolicyTypesReducer,
    bikeModelsReducer,
    rtoReducer,
    authReducer,
    messageReducer,
    bikePolicyTypesReducer,
    bikeMakeModalVariant,
    posStateReducer,
    posCityReducer,
    posPinReducer,
    posQualificationReducer,
    AlertReducer
} from './reducers';


const rootReducer = combineReducers({
    alertState:AlertReducer,
    posState: posStateReducer,
    posQualification: posQualificationReducer,
    posCity: posCityReducer,
    posPinCode: posPinReducer,
    carMake: carMakeReducer,
    bikeMake: bikeMakeReducer,
    carModels: carModelsReducer,
    bikeModels: bikeModelsReducer,
    rto: rtoReducer,
    policyTypes: carPolicyTypesReducer,
    bikePolicyTypes: bikePolicyTypesReducer,
    makeModalVaraint: carMakeModalVariant,
    bikeMakeModalVaraint: bikeMakeModalVariant,
    auth: authReducer,
    message: messageReducer,
});

export default rootReducer;