import React, { useRef, useState, useEffect } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaShieldAlt,FaUsers } from 'react-icons/fa';
// Styling
import "./RegistrationPos.css"
// React Bootstrap Breadcrumb
import { Breadcrumb, Button } from 'react-bootstrap'

const RegistrationLeft = () => {


    return (
        <>
            <div className="text-container">
                <h3 className="Register__Pos__Heading_left">Become<span>your own boss Register as POSP (Point Of Sale Person)</span></h3>
                <p className='Register__Pos__Sub__Heading'>Start earning from day one.</p>
                <div className='row my-5 mx-0 justify-content-between'>
                    <div className='col-md-3 col-lg-3 p-3'>
                        <div className='Icon__Border m-auto'>
                            <i className='fas fa-star'></i>
                        </div>
                        <div style={{textAlign:'center'}}>
                        <h6 className='Icon__Title'>Dedicated Relationship Manager</h6>
                        <p className='Icon__Sub__Title'></p>
                        </div>
                    </div>
                    <div className='col-md-3 col-lg-3 p-3'>
                        <div className='Icon__Border m-auto' >
                            <FaShieldAlt style={{color:'#0093dd',fontSize:'30px'}}/>
                        </div>
                        <div style={{textAlign:'center'}}>
                        <h6 className='Icon__Title'>Top Insurers on Platform</h6>
                        <p className='Icon__Sub__Title'></p>
                        </div>
                    </div>
                    <div className='col-md-3 col-lg-3 p-3'>
                        <div className='Icon__Border m-auto'>
                            <FaUsers style={{color:'#0093dd',fontSize:'30px'}}/>
                        </div>
                        <div style={{textAlign:'center'}}>
                        <h6 className='Icon__Title'>Dedicated Claim Team to serve your Customers</h6>
                        <p className='Icon__Sub__Title'> </p>
                        </div>
                    </div>
                </div>
                <hr />
                <h6 className="Register__Pos__Heading"></h6>
                <p className='text-justify Register__Pos__para my-3'>When the world is working from home you have the choice to choose your working hours and days. You may choose to work 3 days or 7 days a week, 4 Hour a day or 8 hours. PoliciCue encourage it’s POSPs to choose their interest and grow their career with the Product they like the most. Our Training module will let you enjoy your journey and not only enable you to earn more but also entice you to learn more earn more.  We at PoliciCue believe in transparency and ease of work, we guarantee you seamless support whether its client acquisition or servicing. Our dedicated RM and claim support ensure your customer satisfaction. We believe customer delight will help you win more customer. We work to grow together and dedicated learning team ensures a regular development and training of our dedicated RMs for you and POSPs as well. We believe in Skilling and Scaling and skills learnt should be shared among team for future development of the team as a whole, so, we encourage regular sharing of experience and knowledge to grow together.</p>
                <h6 className="Register__Pos__Heading">Key Benefits we offer are:</h6>
                <p className='text-justify Register__Pos__para my-3'> &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;Flexibility to Work &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Training & Development &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; Transparency &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Dedicated RM</p>
                <p className='text-justify Register__Pos__para my-3'> &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;Experience Sharing &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  Reward & Recognition &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Claim Support &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Customer Delight</p>
            </div>
        </>
    )
}

export default RegistrationLeft