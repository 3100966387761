import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./SideBar.css";
import { Navbar, Nav } from "react-bootstrap";
import { traningStatus } from "../../../store/actions/getPosPersonalDetails";
import { getResult } from "../../../store/actions/getPosPersonalDetails";
const SideBar = (props) => {
  const { pathname } = window.location;
  // console.log(window.location, "=======");
  const [active, setactive] = useState("");
  const [trainingStatus, setTrainingStatus] = useState("");
  const [timer, setTimer] = useState(Date.now() + 54000000);
  const [posResult, setPosResult] = useState(false);
  const getTrainingStatus = async () => {
    let res = await traningStatus();
    // console.log("res", res);
    setTrainingStatus(res.status);
  };
  const result_get = () => {
    getResult()
      .then((res) => {
        if (res.status) {
          if (res.data?.result == "Pass") {
            setPosResult(true);
          }
        }
      })
      .catch((error) => {
        //   alertShow('err',error.message);
      });
  };
  useEffect(() => {
    getTrainingStatus();
    result_get();
    // console.log(trainingStatus, "-------------");
  });
  return (
    <div
      className="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 bg-white shadow-sm position-fixed fixed-top "
      style={{ marginTop: "64px", height: "calc(100vh - 64px)", width:"250px" }}
    >
      <div
        className="icueRespSideBar d-none "
        id="icueRespSideBar"
      >
        <button type="button" className="btn btn-primary" id="icueRespHumburger">
          <i className="bi bi-grid-3x3-gap"></i>
        </button>
        <div className="icueSideBar" id="icueSideBar">
          <nav className="navbar icueSideNavContainer">
            <ul className="icueNavBarLst">
              <li className="icueNavBarItm active">
                <a href="/Hrdashboard" className="icueNavBarLink">
                  Dashboard
                </a>
              </li>
              <li className="icueNavBarItm border-bottom-0">
                <a href="#" className="icueNavBarLink">
                  User Management
                </a>
              </li>

              <li>
                <ul className="icueSubNavBar">
                  <li className="icueSubNavItem">
                    {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" className="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                    <a href="#" className="icueSubNavLink  text-capitalize">
                      Add Members
                    </a>
                  </li>
                  <li className="icueSubNavItem">
                    <a
                      href="/ListMembers"
                      className="icueSubNavLink  text-capitalize"
                    >
                      List of Members
                    </a>
                  </li>
                </ul>
              </li>
              <hr></hr>

              <li className="icueNavBarItm border-bottom-0">
                <a href="#" className="icueNavBarLink" id="">
                  Leave Management
                </a>
              </li>

              <li>
                <ul className="icueSubNavBar">
                  <li className="icueSubNavItem">
                    {/* <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#addid" data-bs-whatever="@getbootstrap" className="icueSubNavLink  text-capitalize">Add Member</a> --> */}
                    <a href="/Leaves" className="icueSubNavLink  text-capitalize">
                      Leaves
                    </a>
                  </li>
                  <li className="icueSubNavItem">
                    <a
                      href="/LeaveType"
                      className="icueSubNavLink  text-capitalize"
                    >
                      Leave Type
                    </a>
                  </li>
                </ul>
              </li>
              <hr></hr>
              <li className="icueNavBarItm border-bottom-1">
                <a href="/Holidays" className="icueNavBarLink" id="">
                  Holidays
                </a>
              </li>
              <li className="icueNavBarItm border-bottom-1">
                <a href="/Attendance" className="icueNavBarLink" id="">
                  Attendance
                </a>
              </li>

              <li className="icueNavBarItm">
                <a href="/Department" className="icueNavBarLink" id="">
                  Department
                </a>
              </li>

              <li className="icueNavBarItm">
                <a href="/AddRoles" className="icueNavBarLink" id="">
                  Roles
                </a>
              </li>

              <li className="icueNavBarItm border-bottom-0">
                <a href="#" className="icueNavBarLink" id="">
                  Payroll
                </a>
              </li>
              <li>
                <ul className="icueSubNavBar">
                  <li className="icueSubNavItem">
                    <a
                      href="/EmpSalary"
                      className="icueSubNavLink  text-capitalize"
                    >
                      Employee Salary
                    </a>
                  </li>
                  <li className="icueSubNavItem">
                    <a href="/Payitems" className="icueSubNavLink  text-capitalize">
                      Payroll Items
                    </a>
                  </li>
                </ul>
                <hr></hr>
              </li>

              <li
                className="icueNavBarItm border-bottom-0"
                style={{ marginTop: "-18px" }}
              >
                <a href="#" className="icueNavBarLink" id="">
                  Settings
                </a>
              </li>
              <li>
                <ul className="icueSubNavBar">
                  <li className="icueSubNavItem">
                    <a href="#" className="icueSubNavLink  text-capitalize">
                      Change Password
                    </a>
                  </li>
                  <li className="icueSubNavItem">
                    <a href="#" className="icueSubNavLink  text-capitalize">
                      Profile
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div
        className="icueNavSideBar d-block"
        id="icueNavSideBar"
      >
        <nav>
          <ul className="icueNavBarLst">
            {/* <li
              className={pathname == "/" ? "icueNavBarItm active" : "icueNavBarItm"}
            >
              <a href="/dashboard" className="icueNavBarLink" id="">
                Dashboard
              </a>
            </li> */}
            {/* <li className={pathname=="/SellPage"?"icueNavBarItm active":"icueNavBarItm"}>
                        <a href="/SellPage" className="icueNavBarLink" id="">Sell</a>
                    </li>
                    <li className={pathname=="/MyPolicy"?"icueNavBarItm active":"icueNavBarItm"} >
                        <a href="/MyPolicy" className="icueNavBarLink" id="">My Policy </a>
                    </li>   */}

            {/* <li className={pathname=="/account"?"icueNavBarItm active":"icueNavBarItm"} data-toggle="collapse" data-target="#account">
                        <a href="/account" className="icueNavBarLink" id="">Account Information <i className="bi bi-chevron-down" style={{marginLeft:"1rem"}}></i></a>
                    </li>  
                    <ul className="sub-menu collapse" id="account">
                  <li className={pathname=="/MyProfile"?"icueNavBarSubItm active":"icueNavBarSubItm"} data-toggle="collapse" data-target="#MyProfile">
                  <a href="/MyProfile" className="icueNavBarLink" id="">My Profile </a>                  
                </li>
                <li className={pathname=="/MyTraining"?"icueNavBarSubItm active":"icueNavBarSubItm"} data-toggle="collapse" data-target="#nMyTraining">
                  <a href="/MyTraining" className="icueNavBarLink" id="">My Training </a>                  
                </li>
                <li className={pathname=="/MyCertificate"?"icueNavBarSubItm active":"icueNavBarSubItm"} data-toggle="collapse" data-target="#MyCertificate">
                  <a href="/MyCertificate" className="icueNavBarLink" id="">My Certificate </a>                  
                </li>
                <li className={pathname=="/MyAggrement"?"icueNavBarSubItm active":"icueNavBarSubItm"} data-toggle="collapse" data-target="#MyAggrement">
                  <a href="/MyAggrement" className="icueNavBarLink" id="">My Aggrement </a>                  
                </li>
                <li className={pathname=="/MyCommission"?"icueNavBarSubItm active":"icueNavBarSubItm"} data-toggle="collapse" data-target="#MyCommission">
                  <a href="/MyCommission" className="icueNavBarLink" id="">My Commission </a>                  
                </li>
                </ul>  */}
            <Accordion>
              <Accordion.Item eventKey="0" style={{ border: "0" }}>
                <Accordion.Header className={"active"}>
                  <p>
                    <li
                      className={
                        pathname == "/MyProfile"
                          ? "icueNavBarItm active"
                          : "icueNavBarItm"
                      }
                      data-toggle="collapse"
                      data-target="#account"
                      style={{
                        fontFamily: "ErasDemiITC",
                        color: "#334d6e",
                        fontSize: "15px",
                      }}
                    >
                      Account Information
                    </li>{" "}
                  </p>
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <p>
                      <li
                        className={
                          pathname == "/MyProfile"
                            ? "icueNavBarSubItm active"
                            : "icueNavBarSubItm"
                        }
                      >
                        <a href="/MyProfile" className="icueNavBarLink" id="">
                          My Profile
                        </a>
                      </li>
                      <li
                        className={
                          pathname == "/MyTraining"
                            ? "icueNavBarSubItm active"
                            : "icueNavBarSubItm"
                        }
                      >
                        <a href="/MyTraining" className="icueNavBarLink" id="">
                          My Training
                        </a>
                      </li>
                      {trainingStatus == 1 && (
                        <>
                          <li
                            className={
                              pathname == "/MyCertificate"
                                ? "icueNavBarSubItm active"
                                : "icueNavBarSubItm"
                            }
                          >
                            <a
                              href="/MyExamination"
                              className="icueNavBarLink"
                              id=""
                            >
                              My Examination
                            </a>
                          </li>

                          {posResult && (
                            <>
                              <li
                                className={
                                  pathname == "/MyCertificate"
                                    ? "icueNavBarSubItm active"
                                    : "icueNavBarSubItm"
                                }
                              >
                                <a
                                  href="/MyCertificate"
                                  className="icueNavBarLink"
                                  id=""
                                >
                                  My Certificate
                                </a>
                              </li>
                              <li
                                className={
                                  pathname == "/MyAgreement"
                                    ? "icueNavBarSubItm active"
                                    : "icueNavBarSubItm"
                                }
                              >
                                <a
                                  href="/MyAgreement"
                                  className="icueNavBarLink"
                                  id=""
                                >
                                  My Agreement
                                </a>
                              </li>
                            </>
                          )}
                          {/* {posResult && (
                            <li
                              className={
                                pathname == "/MyCommission"
                                  ? "icueNavBarSubItm active"
                                  : "icueNavBarSubItm"
                              }
                            >
                              <a
                                href="/MyCommission"
                                className="icueNavBarLink"
                                id=""
                              >
                                My Commission
                              </a>
                            </li>
                          )} */}
                        </>
                      )}
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* <li className={pathname=="/MyQuotes"?"icueNavBarItm active":"icueNavBarItm"} >
                        <a href="/MyQuotes" className="icueNavBarLink" id="">My Quotes </a>
                    </li> 
                    <li className={pathname=="/MyProposal"?"icueNavBarItm active":"icueNavBarItm"} >
                        <a href="/MyProposal" className="icueNavBarLink" id="">My Proposal </a>
                    </li>   */}
            <Accordion>
              {/* <Accordion.Item eventKey="2" style={{border:"0"}}>
                <Accordion.Header >
                 
                  <p >
                    <li className={pathname=="/MyQueries"?"icueNavBarItm active":"icueNavBarItm"} style={{fontFamily:"ErasDemiITC", color:"#334d6e", fontSize:"15px"}} >
                       My Queries
                    </li> 
                  </p>

                  </Accordion.Header>
                <Accordion.Body >
                  <div>
                  <p>
                      
                      
                  <li className={pathname=="/RaiseTickets"?"icueNavBarSubItm active":"icueNavBarSubItm"}>
                  <a href="/RaiseTickets" className="icueNavBarLink" id="">Raise Tickets </a>                  
                </li>
                <li className={pathname=="/ViewTickets"?"icueNavBarSubItm active":"icueNavBarSubItm"} >
                  <a href="/ViewTickets" className="icueNavBarLink" id="">View Tickets </a>                  
                </li>
                
                </p>
                </div>
                </Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
            {/* <li className={pathname=="/MyQueries"?"icueNavBarItm active":"icueNavBarItm"} data-toggle="collapse" data-target="#MyQueries">
                        <a href="/MyQueries" className="icueNavBarLink" id="">My Queries <i className="bi bi-chevron-down" style={{marginLeft:"4.5rem"}}></i></a>
                    </li>  
                    <ul className="sub-menu collapse" id="MyQueries">
                  <li className={pathname=="/RaiseTickets"?"icueNavBarSubItm active":"icueNavBarSubItm"} data-toggle="collapse" data-target="#RaiseTickets">
                  <a href="/RaiseTickets" className="icueNavBarLink" id="">Raise Tickets </a>                  
                </li>
                <li className={pathname=="/ViewTickets"?"icueNavBarSubItm active":"icueNavBarSubItm"} data-toggle="collapse" data-target="#ViewTickets">
                  <a href="/ViewTickets" className="icueNavBarLink" id="">View Tickets </a>                  
                </li>
                
                </ul>    */}
            {/* <li className={pathname=="/MyLeads"?"icueNavBarItm active":"icueNavBarItm"} >
                        <a href="/MyLeads" className="icueNavBarLink" id="">My Leads </a>
                    </li> 
                    <li className={pathname=="/MyReports"?"icueNavBarItm active":"icueNavBarItm"} >
                        <a href="/MyReports" className="icueNavBarLink" id="">My Reports </a>
                    </li>                  */}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;
