import React from "react";
import { useEffect, useState } from "react";
import "./MyAgreement.scss";
//import { makeStyles } from '@material-ui/core';
import ReactPaginate from "react-paginate";
import {ImDownload3} from "react-icons/im"; 
import axios from 'axios';
import {Modal} from 'react-bootstrap';
import Constant from "../../../utils/constant";
import file from "../../../assets/file/UserAgreement_POS.docx"
import { useHistory } from "react-router-dom";

const User = JSON.parse(localStorage.getItem("user"));

export const uploadFile = (formData) => {
  
    return new Promise(resolve => {
     axios.patch(Constant.BASE_URL + 'pos/agreement', formData, { headers: {"Authorization" : `Bearer ${User?.token}`} } )
        .then(response => {
            resolve(response.data)
        })
        .catch(error => {
            resolve(error.message)
        })
    }
    )
}
export default function MyAgreement() {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([...Array(33).keys()]);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [fileName , setfileName] = useState("");
  const [fileDownloadLink,setFileDownloadLink] = useState('');
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  console.log(fileName)
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const uploadAgreement = (event) => {
    event.preventDefault();
    // console.log({fileName})
    var formData = new FormData();
  formData.append("agreement", fileName);
    uploadFile(formData).then(res => {
      if(res.status === 1){
        setFileDownloadLink(res.posagreement);
        setShowModal(true);

      }
    });
  }

  const handleClose = () => {
    history.push('/dashboard')
  }

  return (
    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 ">
      <main className="mainBodyContainer">
        <div className=" my-2">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
            <div className="bg-transparent p-2  rounded-3">
              <nav
                style={{
                  bsbreadcrumbdivider:
                    'url("data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E")',
                }}
                aria-label="breadcrumb"
              >
                <h6>My Agreement</h6>
                <ol className="breadcrumb pt-2 mb-0 bg-transparent">
                  <li className="breadcrumb-item bg-transparent">
                    <a
                      href="/"
                      className="text-decoration-none "
                      style={{ fontFamily: "Calibri" }}
                    >
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item text-muted" aria-current="page">
                    Account Information
                  </li>
                  <li className="breadcrumb-item bg-transparent">
                      My Agreement
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 bg-white nav-data-handling">
              <div className="display_flex agreement">
                <div className="agreement__btn-agreement"><button><span><ImDownload3 /></span>        
               <a href={file} download style={{color: "#fff"}}  >Download file</a>
                </button>
                </div>
                <div className="agreement__btn-choose-agreement" >
                    <label
					>
					Choose Agreement
						
						<input
							type='file'
							className='file_'
							size='60'
							name='file'
              onChange={(e) => setfileName(e.target.files[0])}
						/>
					</label>

                </div>
                <div className="agreement__btn-choose-file" ><button>{fileName!=''?fileName.name:"No file choosen"}</button></div>
                <div className="agreement__btn-choose-upload" onClick={uploadAgreement}>
                <button>Upload</button>
                </div>
              </div>
              {/* {fileDownloadLink?<a href={fileDownloadLink}>{fileDownloadLink}</a>:""} */}
              <Modal show={showModal} onHide={handleClose}>
              <Modal.Body>
                    <div className="modal-block">
                      <h3
                        style={{
                          paddingLeft: "0.5rem",
                          fontFamily: "ErasDemiITC",
                          color: "rgb(41, 22, 111)",
                          fontSize: "20px",
                        }}
                      >
                        Agreement Uploaded Successfully
                      </h3>
                      
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
          <button onClick={handleClose}>
            Close
          </button>
         
        </Modal.Footer>
                </Modal>
            {/* <table id="dtBasicExample" className="table results ">
              <thead>
                <tr
                  className="text-uppercase"
                  style={{
                    lineHeight: "15px",
                    fontFamily: "Calibri",
                    fontStyle: "normal",
                    fontSize: "16px",
                  }}
                >
                  <th>SR. NO.</th>
                  <th>POSP NAME</th>
                  <th>POSP CODE</th>
                  <th>AGREEMENT</th>
                  <th>SIGNED DATE</th>
                  <th>STATUS</th>
                  <th> DOWNLOAD</th>
                </tr>
              </thead>
              <tbody
                style={{
                  lineHeight: "15px",
                  fontFamily: "Calibri",
                  fontSize: "16px",
                }}
              >
                {currentItems &&
                  currentItems.map((k, v) => (
                    <tr>
                      <td className="sorting-num" >1</td>
                      <td className="sorting-num1">Name 1</td>
                      <td className="wrap sorting-num2">123123</td>
                      <td className="wrap  sorting-num3">Agreement 1</td>
                      <td className="sorting-num4">25-Jan-2022</td>
                      <td className="sorting-num5">Status 1</td>
                      <td className=" btn-download  "> <a
                          href="/PayInstallment"
                          type="button"
                          className="agreement-dowload"
                        >
                          Download
                        </a>{" "}
                        <br></br>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table> */}
          </div>
        </div>
      </main>
    </div>
  );
}
